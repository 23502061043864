//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defaultNotPaginationAttributes } from '@/utils/helpers/pagination-helper'
import { formatRupiah, generateRandomString } from '@/utils/helpers/string-helper'

export default {
  props: {
    filterModel: {
      type: Object,
      required: true
    },

    filterData: {
      type: Object,
      default: () => ({
        ...defaultNotPaginationAttributes(),
        data: {
          category: [],
          brand: [],
          location: [],
          price: {
            from: 0,
            until: 5000000
          },
          rating: [],
          variant: {}
        }
      })
    },

    filterLabel: {
      type: Object,
      default: () => ({})
    },

    filterShowed: {
      type: Object,
      default: () => ({
        category: true,
        brand: true,
        location: true,
        price: true,
        rating: true
      })
    },

    prefixQuery: {
      type: String,
      default: () => 'products'
    }
  },

  data () {
    return {
      //
    }
  },

  watch: {
    filterData: {
      handler (value) {
        if (value) {
          if (Number(this.filterModel.price[0]) < this.filterData.data.price.from) {
            this.filterModel.price[0] = this.filterData.data.price.from
          }

          if (Number(this.filterModel.price[1]) > this.filterData.data.price.until) {
            this.filterModel.price[1] = this.filterData.data.price.until
          }
        }
      },
      deep: true
    }
  },

  methods: {
    generateRandomString,
    formatRupiah,

    getNodeTreeClassName (treeNodeData) {
      if (this.isCategoryTreeItemActive(treeNodeData)) {
        return 'tree-node-active'
      }

      return 'color-text-sub '
    },

    isCategoryTreeItemActive (item) {
      return this.filterModel.activeCategoryTreeItems.includes(item.slug)
    },

    handleFilterCategoryTreeNodeOnClick (data) {
      this.$emit('filter-category-tree-node-on-click', data)
    },

    handleFilterBrandCheckboxGroupOnChange (data) {
      this.$emit('filter-brand-checkbox-group-on-change', data)
    },

    handleFilterLocationCheckboxGroupOnChange (data) {
      this.$emit('filter-location-checkbox-group-on-change', data)
    },

    handleFilterPriceSliderOnChange (data) {
      this.$emit('filter-price-slider-on-change', data)
    },

    handleFilterRatingCheckboxGroupOnChange (data) {
      this.$emit('filter-rating-checkbox-group-on-change', data)
    }
  }
}
