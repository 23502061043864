//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { storeTypes } from '@/utils/helpers/store-helper'
import { defaultNotPaginationAttributes } from '@/utils/helpers/pagination-helper'

export default {
  props: {
    partner: {
      type: Object,
      required: true
    },

    partnerRelatedData: {
      type: Object,
      default: () => ({
        ...defaultNotPaginationAttributes()
      })
    }
  },

  computed: {
    storeTypes () {
      return storeTypes
    }
  }
}
