//
//
//
//
//
//
//
//

import { storeTypes } from '@/utils/helpers/store-helper'

export default {
  props: {
    partnerType: {
      type: String,
      required: true
    }
  },

  computed: {
    storeTypes () {
      return storeTypes
    }
  }
}
