//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDefaultImageFromFiles } from '@/utils/helpers/file-helper'
import { maskNumber } from '@/utils/helpers/string-helper'
export default {
  props: {
    listAccount: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      value: ''
    }
  },
  methods: {
    getDefaultImageFromFiles,
    maskNumber
  }
}
