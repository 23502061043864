//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { formatDateWithTimezone, getUserTimezoneLabel } from '@/utils/helpers/date-time-helper'
import panstoreMixin from '@/mixins/index'
export default {
  mixins: [panstoreMixin],
  computed: {
    ...mapState('Order', ['orderDetailPayment']),
    countDown () {
      const expiredDate = formatDateWithTimezone(this.orderDetailPayment.expired_at, {
        formatResult: 'YYYY-MM-DD HH:mm:ss'
      })

      let newDate = new Date(expiredDate)

      if (Number.isNaN(newDate.getTime())) {
        newDate = new Date(expiredDate)
      }
      return newDate.getTime() - new Date().getTime()
    }
  },
  methods: {
    formatDateWithTimezone,
    getUserTimezoneLabel,
    two_digits (value) {
      if (value.toString().length <= 1) {
        return '0' + value.toString()
      }
      return value.toString()
    }
  }
}
