//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import authMixin from '@/mixins/auth-mixin'

import { getDefaultImageFromFiles, getDefaultUserAvatarOnError } from '@/utils/helpers/file-helper'
import { defaultUserGender, userFileNames } from '@/utils/helpers/user-helper'
import { mapMutations, mapState } from 'vuex'

export default {
  mixins: [
    authMixin
  ],

  data () {
    return {
      navigationItems: {
        home: {
          id: 0,
          icon: '/img/icon/bottom-navigation/new/icon-home.svg',
          iconActive: '/img/icon/bottom-navigation/new/icon-home-filled.svg',
          name: 'home',
          text: 'Beranda',
          clickActionType: 'link',
          link: '/',
          clickAction: () => {},
          activeFlagCallback: () => {},
          hasBadgeCount: false,
          badgeCount: 0,
          onlyShowWhenLoggedIn: false
        },
        category: {
          id: 1,
          icon: '/img/icon/bottom-navigation/new/icon-category.svg',
          iconActive: '/img/icon/bottom-navigation/new/icon-category-filled.svg',
          name: 'category',
          text: 'Kategori',
          clickActionType: 'callback',
          clickAction: (value) => {
            this.SET_ALL_CATEGORY_DRAWER_IS_OPEN(value)
          },
          activeFlagCallback: () => {
            return this.allCategoryDrawerIsOpen
          },
          link: '',
          hasBadgeCount: false,
          badgeCount: 0,
          onlyShowWhenLoggedIn: false
        },
        brand: {
          id: 2,
          icon: '/img/icon/bottom-navigation/new/icon-brand.svg',
          iconActive: '/img/icon/bottom-navigation/new/icon-brand-filled.svg',
          name: 'brand',
          text: 'Brand',
          clickActionType: 'link',
          link: '/partner?type=brand',
          clickAction: () => {},
          activeFlagCallback: () => {},
          hasBadgeCount: false,
          badgeCount: 0,
          onlyShowWhenLoggedIn: false
        },
        wishlist: {
          id: 3,
          icon: '/img/icon/bottom-navigation/new/icon-wishlist.svg',
          iconActive: '/img/icon/bottom-navigation/new/icon-wishlist-filled.svg',
          name: 'wishlist',
          text: 'Wishlist',
          clickActionType: 'link',
          link: '/account/wishlist',
          clickAction: () => {},
          activeFlagCallback: () => {},
          hasBadgeCount: false,
          badgeCount: 0,
          onlyShowWhenLoggedIn: false
        },
        account: {
          id: 4,
          icon: '/img/icon/bottom-navigation/new/icon-user.svg',
          iconActive: '',
          name: 'account',
          text: 'Akun',
          clickActionType: 'link',
          link: this.$auth.loggedIn ? '/account/profile' : `/login?redirect_from=${this.$route.fullPath}`,
          clickAction: () => {},
          activeFlagCallback: () => {},
          hasBadgeCount: false,
          badgeCount: 0,
          onlyShowWhenLoggedIn: false
        }
      }
    }
  },

  computed: {
    ...mapState('Category/AllCategoryDrawer', [
      'allCategoryDrawerIsOpen'
    ]),

    defaultUserGender () {
      return defaultUserGender
    },

    hasActiveNavigationItemClickActionTypeCallback () {
      return Object.values(this.navigationItems).some((item) => {
        return item.clickActionType === 'callback' && item.activeFlagCallback();
      })
    }
  },

  watch: {
    userLoggedIn: {
      handler (value) {
        this.filterNavigationDataWhenLoggedIn()

        if (value) {
          this.changeAccountNavigationItemIcon()
        }
      },
      immediate: true
    }
  },

  methods: {
    getDefaultUserAvatarOnError,
    getDefaultImageFromFiles,

    ...mapMutations('Category/AllCategoryDrawer', [
      'SET_ALL_CATEGORY_DRAWER_IS_OPEN'
    ]),

    filterNavigationDataWhenLoggedIn () {
      if (!this.userLoggedIn) {
        this.navigationItems = Object.fromEntries(
          Object.entries(this.navigationItems).filter(([key, value]) => value.onlyShowWhenLoggedIn === false)
        )
      }
    },

    checkNavigationItemActive (item) {
      if (!process.client) {
        return false
      }

      if (item.clickActionType === 'link') {
        if (this.hasActiveNavigationItemClickActionTypeCallback) {
          return false;
        }

        // Create a URL object to remove the query parameters from item.link
        const url = new URL(item.link, window.location.origin);
        return this.$route.path === url.pathname;
      }

      // Handle if the item is a callback
      return item.activeFlagCallback()
    },

    changeAccountNavigationItemIcon () {
      const userAvatar = getDefaultImageFromFiles(this.userLoggedIn.files, userFileNames.avatar)

      this.navigationItems.account.icon = userAvatar
      this.navigationItems.account.iconActive = userAvatar
    },

    handleBottomNavigationItemOnClick (item) {
      if (item.clickActionType === 'link') {
        const url = new URL(item.link, window.location.origin)

        if (this.$route.path !== url.pathname) {
          window.location.href = item.link
          return
        }

        Object.values(this.navigationItems).forEach((item) => {
          switch (item.name) {
            case this.navigationItems.category.name:
              if (item.clickActionType === 'callback' && item.activeFlagCallback()) {
                item.clickAction(false)
              }
              break
          }
        })
      } else if (item.clickActionType === 'callback') {
        switch (item.name) {
          case this.navigationItems.category.name:
            item.clickAction(true)
            break
          default:
            item.clickAction()
            break
        }
      }
    }
  }
}
