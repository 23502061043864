//
//
//
//
//
//
//
//
//
//
//

import {
  defaultImages,
  getDefaultImageFromFiles,
  getDefaultImgBannerOnError,
  getDefaultImgOnError
} from '@/utils/helpers/file-helper'

export default {
  props: {
    files: {
      type: Array,
      default: () => []
    },

    fileName: {
      type: String,
      default: () => ''
    },

    fileVariant: {
      type: String,
      default: () => ''
    },

    defaultImage: {
      type: String,
      default: () => defaultImages.defaultImageBanner
    }
  },

  methods: {
    getDefaultImgOnError,
    getDefaultImgBannerOnError,
    getDefaultImageFromFiles
  }
}
