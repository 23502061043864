//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex'
import { isObjectNotEmptyOrNull } from '@/utils/helpers/object-helper'
import orderModuleMixins from '@/mixins/order-module-mixins'
export default {
  mixins: [orderModuleMixins],
  data () {
    return {
      formType: 'add',
      source: 'listAddress',
      editedData: {}
    }
  },
  computed: {
    ...mapState('User/address', ['userAddress']),
    ...mapState('Checkout', ['selectedAddress'])
  },
  methods: {
    isObjectNotEmptyOrNull,
    ...mapActions('User/address', ['getUserAddress']),
    async fetchAddress () {
      try {
        await this.getUserAddress()
      } catch (error) {
        console.log(error)
      }
    },
    handleAddressCreated (source) {
      this.fetchAddress()
      if (source === 'listAddress') {
        this.$refs.openListAddress.click()
      }
    },
    handleOpenFormAddress ({ type, editedData }) {
      this.formType = type
      this.source = 'listAddress'
      if (type === 'edit' && isObjectNotEmptyOrNull(editedData)) {
        this.editedData = editedData
      }
      this.$refs.openFormPopup.click()
    },
    async handleAddressSelected () {
      await this.checkAvaibilityCheckout()

      if (!this.checkoutAvailability.is_fulfilled && isObjectNotEmptyOrNull(this.selectedAddress)) {
        await this.fetchBranchesForCheckout()
        await this.fetchShippingCourier()
      }
    }
  }
}
