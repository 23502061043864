import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=24c1975b&scoped=true&"
import script from "./_id.vue?vue&type=script&lang=js&"
export * from "./_id.vue?vue&type=script&lang=js&"
import style0 from "./_id.vue?vue&type=style&index=0&id=24c1975b&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24c1975b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumb: require('/usr/src/app/components/home/base/Breadcrumb.vue').default,PartnerProfileHeader: require('/usr/src/app/components/home/partner/PartnerProfileHeader.vue').default,PartnerProfileBody: require('/usr/src/app/components/home/partner/PartnerProfileBody.vue').default,BannerBottomSection: require('/usr/src/app/components/home/BannerBottomSection.vue').default})
