//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defaultDebounceTimes } from '@/utils/helpers/form-helper'
import _ from 'lodash'
import { mapActions } from 'vuex'

export default {
  layout: 'account',
  middleware: ['user'],
  data () {
    const breadcrumbs = [
      {
        path: '/'
      },
      {
        name: 'Beranda',
        path: '/account/home'
      },
      {
        name: 'Belanja Saya'
      },
      {
        name: 'Ulasan Saya'
      }
    ]
    return {
      breadCrumb: breadcrumbs,
      options: [
        {
          value: 'latest',
          label: 'Terbaru'
        },
        {
          value: 'oldest',
          label: 'Terlama'
        }
      ],
      value: '',
      payload: {
        per_page: 10,
        page: 1
      },
      rate: '',
      order_by: 'latest',
      search: '',
      isAvailable: false,
      totalPage: 1,
      firstLoad: true
    }
  },
  watch: {
    '$route.query': {
      handler () {
        this.handleInitQuery()
        this.getDataReviews()
      },
      deep: true,
      immediate: true
    }
  },
  async mounted () {
    this.handleInitQuery()
    await this.getDataReviews()
  },
  methods: {
    ...mapActions('Order', ['getMyOrderReviews']),
    handleInitQuery () {
      this.payload.page = this.$route.query.page || 1
      this.order_by = this.$route.query.order_by || 'latest'
      this.rate = this.$route.query.rating || ''
      this.search = this.$route.query.searchReview || ''
      this.firstLoad = true
    },
    handleChangeRating (value) {
      this.rate = value === '' ? '' : Number(value)
      this.applyQuery()
    },

    handleSearch: _.debounce(function () {
      this.applyQuery()
    }, defaultDebounceTimes.search),

    applyQuery () {
      const payload = {}

      if (this.search) {
        payload.searchReview = this.search
      }

      if (this.rate !== '') {
        payload.rating = this.rate
      }
      if (this.order_by) {
        payload.order_by = this.order_by
      } else {
        payload.order_by = 'latest'
      }

      this.$router.push({ query: payload })
    },
    applySort () {
      this.$router.push({
        query: {
          ...this.$route.query,
          order_by: this.order_by,
          page: 1
        }
      })
    },

    async getDataReviews () {
      if (!this.firstLoad) {
        this.payload.page++
      }

      const query = this.$route.query

      this.payload.search = query.searchReview || ''

      if (query.rating) {
        this.payload.rating = Number(query.rating) // Pastikan dikonversi ke angka
        this.rate = query.rating || ''
      }

      this.payload.order_by = query.order_by || 'latest'

      try {
        const resp = await this.getMyOrderReviews({
          params: this.payload
        })

        if (resp.status === 200 && resp.data.data.reviews.data.length) {
          this.totalPage = resp.data.data.reviews.last_page
          this.isAvailable = true
        } else {
          this.isAvailable = false
        }
      } catch (error) {
        console.error('Error fetching reviews:', error)
      } finally {
        this.firstLoad = false
      }
    }
  }
}
