import { render, staticRenderFns } from "./NavbarCart.vue?vue&type=template&id=d2988bbe&scoped=true&"
import script from "./NavbarCart.vue?vue&type=script&lang=js&"
export * from "./NavbarCart.vue?vue&type=script&lang=js&"
import style0 from "./NavbarCart.vue?vue&type=style&index=0&id=d2988bbe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2988bbe",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Clamp: require('/usr/src/app/components/base/Clamp.vue').default,Divider: require('/usr/src/app/components/base/Divider.vue').default})
