//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapState } from 'vuex'

import { defaultNotPaginationAttributes } from '@/utils/helpers/pagination-helper'

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },

    filterModel: {
      type: Object,
      required: true
    },

    filterData: {
      type: Object,
      default: () => ({
        ...defaultNotPaginationAttributes(),
        data: {
          category: [],
          location: [],
          price: {
            from: 0,
            until: 5000000
          },
          rating: [],
          variant: {}
        }
      })
    },

    filterLabel: {
      type: Object,
      default: () => ({
        category: 'Kategori'
      })
    },

    filterShowed: {
      type: Object,
      default: () => ({})
    },

    navbarTopLabel: {
      type: String,
      default: () => 'Filter'
    }
  },

  computed: {
    ...mapState('Product/ProductsFilterMobile', [
      'productsFilterMobileIsOpen'
    ])
  },

  methods: {
    ...mapMutations('Product/ProductsFilterMobile', [
      'SET_PRODUCTS_FILTER_MOBILE_IS_OPEN'
    ]),

    handleProductsSideFilterCategoryTreeNodeOnClick (data) {
      this.$emit('filter-category-tree-node-on-click', data)
    },

    handleProductsSideFilterLocationCheckboxGroupOnChange (data) {
      this.$emit('filter-location-checkbox-group-on-change', data)
    },

    handleProductsSideFilterPriceSliderOnChange (data) {
      this.$emit('filter-price-slider-on-change', data)
    },

    handleProductsSideFilterRatingCheckboxGroupOnChange (data) {
      this.$emit('filter-rating-checkbox-group-on-change', data)
    },

    handleBtnCloseFilterOnClick () {
      this.SET_PRODUCTS_FILTER_MOBILE_IS_OPEN(false)
    },

    handleBtnResetFilterOnClick () {
      this.$emit('btn-filter-reset-on-click')
    },

    handleBtnApplyFilterOnClick () {
      this.$emit('btn-filter-apply-on-click')
    }
  }
}
