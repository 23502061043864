import { render, staticRenderFns } from "./_order_id.vue?vue&type=template&id=0d931fd6&scoped=true&"
import script from "./_order_id.vue?vue&type=script&lang=js&"
export * from "./_order_id.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d931fd6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumb: require('/usr/src/app/components/home/base/Breadcrumb.vue').default,IconWarning: require('/usr/src/app/components/icons/IconWarning.vue').default,Alert: require('/usr/src/app/components/base/Alert.vue').default,PaymentConfirmation: require('/usr/src/app/components/home/payment-confirmation/PaymentConfirmation.vue').default,BannerBottomSection: require('/usr/src/app/components/home/BannerBottomSection.vue').default})
