//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import panstoreMixin from '@/mixins/index'
import { checkoutMethods, defaultCourierForCheckoutFulfilled } from '@/utils/helpers/checkout-helper'
import { isObjectNotEmptyOrNull } from '@/utils/helpers/object-helper'
import { isArrayNotNullOrEmpty } from '@/utils/helpers/array-helper'
import { defaultToastOptions, toastTypes } from '@/utils/helpers/toast-helper'
import { getResponseErrorMessage } from '@/utils/helpers/response-helper'
import orderModuleMixins from '@/mixins/order-module-mixins'
export default {
  mixins: [panstoreMixin, orderModuleMixins],
  data () {
    return {
      switchPrize: false,
      note: '',
      totalPrice: 0
    }
  },
  computed: {
    ...mapState('Cart', ['selectItem', 'cart', 'cartAvailability']),
    ...mapState('Voucher', ['voucherUsed', 'vouchers']),
    ...mapGetters('Voucher', [
      'voucherUsedId',
      'listVouchers',
      'totalVoucherUsed',
      'groupedVoucherUsedData',
      'ungroupedVoucherUsedData'
    ]),
    ...mapState('Checkout', [
      'selectedCourier',
      'listItem',
      'selectedAddress',
      'noteShipping',
      'storeBranch',
      'storeBranchPAS',
      'branchSelected',
      'pageTakeInStore',
      'onCheck',
      'usePoint',
      'enableShipping'
    ]),
    ...mapState('Checkout', {
      checkoutPaymentDetail: 'paymentDetail'
    }),
    ...mapGetters('Cart', [
      'getTotalPrice',
      'getTotalSelectedItem',
      'isEventSale',
      'getCheckDisabledGotoCheckout'
    ]),
    ...mapState('User/address', ['userAddress']),
    ...mapState('Feature', ['panintiPointActive']),
    totalVoucher () {
      return this.totalVoucherUsed
    },
    paymentDetail () {
      return this.$route.path.includes('/cart') ? this.cartAvailability : this.checkoutPaymentDetail
    },
    paymentDetailExist () {
      return !!Object.keys(this.paymentDetail).length
    },
    routeCheckout () {
      return this.$route.path === '/checkout'
    },
    checkPayload () {
      const checkCartGroups = this.availableCartsForm.every((cartGroup) => {
        if (this.checkoutMethod === checkoutMethods.shipping.value) {
          return this.checkoutAvailability.is_fulfilled ? true : isObjectNotEmptyOrNull(cartGroup.selectedBranch) && isObjectNotEmptyOrNull(cartGroup.selectedCourier)
        } else {
          return this.checkoutAvailability.is_fulfilled ? true : isObjectNotEmptyOrNull(cartGroup.selectedBranch)
        }
      })

      // For Shipping & Pickup First
      if (
        this.checkoutMethod === checkoutMethods.shipping.value &&
        isObjectNotEmptyOrNull(this.selectedAddress) &&
        checkCartGroups &&
        isObjectNotEmptyOrNull(this.selectedPaymentMethod)
      ) {
        // Check If CC
        if (isObjectNotEmptyOrNull(this.selectedPaymentMethod) &&
          this.selectedPaymentMethod.code === 'credit_card' &&
          isObjectNotEmptyOrNull(this.selectedPaymentAccount) &&
          isObjectNotEmptyOrNull(this.selectedCCInstallment)
        ) {
          return false
        } else if (isObjectNotEmptyOrNull(this.selectedPaymentMethod) &&
          this.selectedPaymentMethod.code === 'credit_card' &&
          (!isObjectNotEmptyOrNull(this.selectedPaymentAccount) ||
          !isObjectNotEmptyOrNull(this.selectedCCInstallment))
        ) {
          return true
        } else {
          return false
        }
      } else if (
        this.checkoutMethod === checkoutMethods.pickup.value &&
        isObjectNotEmptyOrNull(this.selectedAddress) &&
        checkCartGroups &&
        isObjectNotEmptyOrNull(this.selectedPaymentMethod)
      ) {
        // Check If CC
        if (isObjectNotEmptyOrNull(this.selectedPaymentMethod) &&
          this.selectedPaymentMethod.code === 'credit_card' &&
          isObjectNotEmptyOrNull(this.selectedPaymentAccount) &&
          isObjectNotEmptyOrNull(this.selectedCCInstallment)
        ) {
          return false
        } else if (isObjectNotEmptyOrNull(this.selectedPaymentMethod) &&
          this.selectedPaymentMethod.code === 'credit_card' &&
          (!isObjectNotEmptyOrNull(this.selectedPaymentAccount) ||
          !isObjectNotEmptyOrNull(this.selectedCCInstallment))
        ) {
          return true
        } else {
          return false
        }
      }

      return true
    },
    user () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user
      } else {
        return {}
      }
    },
    listProduct () {
      const tempData = []
      if (
        Object.keys(this.listItem).length &&
        this.$route.path === '/checkout'
      ) {
        this.listItem.available.map((avail) => {
          tempData.push(avail.id)
        })
      } else {
        this.selectItem.map((selectId) => {
          this.cart.map((c) => {
            c.cart_items.map((items) => {
              if (items.id === selectId) {
                tempData.push(items.id)
              }
            })
          })
        })
      }
      return tempData
    },
    enablePoint () {
      if (this.$route.path === '/cart') {
        return (
          Object.keys(this.user).length &&
          this.selectItem.length &&
          this.user.paninti_point
        )
      } else {
        return this.paymentDetail.usable_paninti_point
      }
    },
    userPoint () {
      if (this.$route.path === '/cart') {
        if (Object.keys(this.user).length) {
          return this.user.paninti_point
        }
      } else {
        return this.paymentDetail.usable_paninti_point
      }
      return 0
    },
    voucherDiscount () {
      const vouchers =
        this.$route.path === '/checkout' ? [] : this.voucherUsed
      if (vouchers) {
        const voucherDisc = vouchers.filter((used) => {
          return used.type === 'discount'
        })

        if (voucherDisc && voucherDisc.length) {
          return voucherDisc[0]
        }
      }
      return {}
    },
    productCount () {
      let total = 0
      this.groupedSelectedItem.map((item) => {
        item.items.map((product) => {
          total += product.quantity
        })
      })
      return total
    }
  },
  watch: {
    selectItem () {
      if (!this.selectItem.length) {
        this.setUsePoint(false)
        localStorage.removeItem('usePoint')
      }
    }
  },
  async mounted () {
    if (localStorage.note) {
      const note = JSON.parse(localStorage.note)
      this.note = note.note
      this.switchPrize = note.switchPrize
    }
    if (localStorage.cartPrice) {
      this.totalPrice = localStorage.cartPrice
    }
    if (localStorage.usePoint) {
      this.setUsePoint(true)
    }
    if (!(Object.keys(this.user).length && this.user.paninti_point)) {
      this.setUsePoint(false)
    }
    if (
      this.$route.path === '/cart' &&
      this.usePoint &&
      !this.selectItem.length
    ) {
      this.setUsePoint(false)
      localStorage.removeItem('usePoint')
    }
    if (localStorage.cartItem && !this.selectItem.length) {
      const localCartItem = JSON.parse(localStorage.cartItem)
      localCartItem.forEach((cart) => {
        this.setSelectedItem(cart)
      })
    }

    if (localStorage.cartItem && !this.selectItem.length) {
      const localCartItem = JSON.parse(localStorage.cartItem)
      localCartItem.forEach((cart) => {
        this.setSelectedItem(cart)
      })
    }

    if (localStorage.voucher_used && !this.voucherUsed.length && this.$route.name === 'checkout') {
      const localVoucherUsed = JSON.parse(localStorage.voucher_used)
      localVoucherUsed.forEach((used) => {
        this.setVoucherUsedByStore(used)
      })
    }

    await this.fetchPointFeature()
  },
  methods: {
    ...mapActions('Voucher', ['getVouchersAvailable']),
    ...mapMutations('Voucher', [
      'setVoucherUsedByStore',
      'setVoucherUsed',
      'setPage',
      'setTotalPage',
      'setVouchers',
      'clearVouchersByType',
      'setPopupVoucherModal',
      'removeVouchersAvailableByStore'
    ]),
    ...mapMutations('Checkout', ['setUsePoint']),
    ...mapMutations('Cart', ['setSelectedItem']),
    ...mapActions('Feature', ['getPanintiPointActive']),
    ...mapActions('Order', ['addOrder']),
    async handleOpenVoucher () {
      if (this.selectItem.length) {
        await this.fetchListVouchers()
        this.$refs.openVoucher.click()
        this.setPopupVoucherModal(true)
      } else {
        this.$message({
          message: 'Silahkan pilih produk terlebih dahulu',
          type: 'warning'
        })
      }
    },
    async fetchListVouchers () {
      for (let i = 0; i < this.groupedSelectedItem.length; i++) {
        const group = this.groupedSelectedItem[i]

        const checkStoreVoucherIdx = this.vouchers.findIndex((voucher) => {
          return voucher.store_id === group.storeId
        })

        // check if all items in groupedSelectedItem is flashsale
        const checkAllItemsFlashsale = group.items.every((item) => {
          return item.isFlashsale === true
        })

        if (checkAllItemsFlashsale && checkStoreVoucherIdx > -1) {
          this.removeVouchersAvailableByStore({
            storeId: group.storeId
          })
        }

        // check if any items is ordinary product (no flashsale, no fulfilled, no pre order)
        const checkAnyOrdinaryItems = group.items.some((item) => {
          return !item.isFlashsale && !item.isFulfilled && !item.isPreorder
        })

        const checkAnyFlashsale = group.items.some((item) => {
          return item.isFlashsale
        })

        if (!checkAllItemsFlashsale && checkAnyOrdinaryItems && checkAnyFlashsale && checkStoreVoucherIdx > -1) {
          this.removeVouchersAvailableByStore({
            storeId: group.storeId
          })
        }

        // recheck if store exist if exist dont get api
        const recheckStoreVoucherIdx = this.vouchers.findIndex((voucher) => {
          return voucher.store_id === group.storeId
        })

        if (recheckStoreVoucherIdx === -1 || (recheckStoreVoucherIdx > -1 && !this.vouchers[recheckStoreVoucherIdx].data)) {
          try {
            await this.getVouchersAvailable({
              store_id: group.storeId,
              store_name: group.storeName,
              payload: {
                data: {
                  cart_ids: group.items.map(item => item.cartId),
                  pickup_at_store: false,
                  only_available: true
                },
                params: {
                  paginated: true,
                  page: 1,
                  per_page: 3
                }
              }
            })
          } catch (error) {
            console.log(error)
          }
        }
      }
    },
    async fetchPointFeature () {
      try {
        await this.getPanintiPointActive({
          params: {
            key: 'paninti_point.active'
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    async setItemLocal () {
      if (this.$auth.loggedIn) {
        this.handleSetData()
        if (this.$route.name === 'cart') {
          try {
            localStorage.setItem('order_method', 'shipping')
            localStorage.setItem('voucher_used', JSON.stringify(this.voucherUsed))
            await this.checkAvaibilityCheckout()
            this.$router.push('/checkout')
          } catch (error) {
            console.log(error)
          }
        } else if (!this.enableShipping) {
          this.$Swal
            .fire({
              title: 'Pemberitahuan',
              text: 'Produk tersebut hanya dapat diambil ditoko',
              icon: 'warning',
              showCloseButton: true,
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#ea2828',
              confirmButtonText: 'Ya, lanjutkan belanja',
              cancelButtonText: 'Batal'
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.$refs.buttonShowModalPaymentMethod.click()
              }
            })
        } else {
          this.$refs.buttonShowModalPaymentMethod.click()
        }
      } else {
        this.$refs.btnShowLogin.click()
      }
    },
    async handleCreateOrder () {
      if (isObjectNotEmptyOrNull(this.selectedPaymentMethod) &&
        this.selectedPaymentMethod.code === 'credit_card' &&
        isObjectNotEmptyOrNull(this.selectedPaymentAccount) &&
        isObjectNotEmptyOrNull(this.selectedCCInstallment)
      ) {
        this.$refs.buttonShowPopupCVVNew.click()
      } else {
        await this.handleOrder()
      }
    },
    async handleOrder () {
      const note = localStorage.getItem('note')
      const payload = {
        data: {
          order_method: localStorage.getItem('order_method') || 'shipping',
          send_as_gift: !!note,
          gift_note: note || '',
          use_paninti_point: this.usePoint,
          user_address_id: this.selectedAddress.id,
          cart_ids: this.groupedSelectedItem.map((item) => {
            return item.items.map(cart => cart.cartId)
          }).flat(),
          voucher_codes: []
        }
      }

      if (isObjectNotEmptyOrNull(this.checkoutAvailability) && isArrayNotNullOrEmpty(this.availableCartsForm)) {
        payload.data = Object.assign(payload.data, {
          checkout_groups: this.availableCartsForm.map((cartForm) => {
            let checkoutGroup = {
              store_branch_id: this.checkoutAvailability.is_fulfilled ? cartForm.storeBranchId : cartForm.selectedBranch.id,
              cart_ids: cartForm.carts.map((cart) => {
                return cart.id
              }),
              note: cartForm.shippingPickupNote
            }

            if (isObjectNotEmptyOrNull(cartForm.selectedCourier)) {
              checkoutGroup = Object.assign(checkoutGroup, {
                shipping_courier: {
                  code: cartForm.selectedCourier.code,
                  service: cartForm.selectedCourier.service
                }
              })
            } else if (this.checkoutAvailability.is_fulfilled) {
              checkoutGroup = Object.assign(checkoutGroup, {
                shipping_courier: defaultCourierForCheckoutFulfilled
              })
            }

            // Append Voucher
            if (isArrayNotNullOrEmpty(this.voucherUsed)) {
              const storeVoucherIdx = this.voucherUsed.findIndex((used) => {
                return cartForm.storeId === used.storeId
              })

              let voucherCodes = []

              if (storeVoucherIdx > -1) {
                voucherCodes = this.voucherUsed[storeVoucherIdx].data.map((dt) => {
                  return dt.code
                })
              }

              checkoutGroup.voucher_codes = voucherCodes
            }

            return checkoutGroup
          })
        })
      }

      if (isObjectNotEmptyOrNull(this.selectedPaymentMethod)) {
        payload.data = Object.assign(payload.data, {
          payment_method_id: this.selectedPaymentMethod.id
        })
      }

      try {
        this.$refs.buttonShowPopupCheckoutLoading.click()
        await this.addOrder(payload)
      } catch (error) {
        const errorMessage = getResponseErrorMessage(error)
        this.$message({
          ...defaultToastOptions(),
          message: errorMessage,
          type: toastTypes.error.value
        })
        this.$emit('create-order-error')
      }
    },
    handleSetData () {
      localStorage.cartItem = JSON.stringify(this.selectItem)
      localStorage.cartPrice = this.getTotalPrice
      if (this.switchPrize) {
        localStorage.note = JSON.stringify({
          switchPrize: this.switchPrize,
          note: this.note
        })
      } else {
        localStorage.removeItem('note')
      }
    },
    StoreProductPrice (price) {
      let totalDisc = 0

      if (
        this.paymentDetail &&
        Object.keys(this.paymentDetail).length
      ) {
        totalDisc = this.paymentDetail.total_discount_voucher

        return this.formatRupiah((price - totalDisc).toString(), 'Rp')
      }

      return this.formatRupiah(price.toString(), 'Rp')
    },
    countProduct (product) {
      let qty = 0
      product.map((p) => {
        qty += p.quantity
      })
      return qty
    },
    async handleSwitchPoint () {
      this.setUsePoint(!this.usePoint)
      if (this.$route.path === '/cart') {
        await this.checkAvaibilityItems('')
      } else {
        await this.checkAvaibilityCheckout()
      }

      if (this.usePoint) {
        localStorage.setItem('usePoint', this.usePoint)
      } else {
        localStorage.removeItem('usePoint')
      }
    },
    getDataBranch () {
      if (this.pageTakeInStore) {
        return this.storeBranchPAS
      } else {
        return this.storeBranch
      }
    }
  }
}
