//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'
import { mapState, mapActions, mapMutations } from 'vuex'

import panstoreMixin from '@/mixins/index'

import { storeSimpleType } from '@/utils/helpers/store-helper'

export default {
  mixins: [panstoreMixin],
  props: {
    product: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      input4: '',
      input5: '',
      alphabet: [],
      partnerByCity: [],
      listSearch: [],
      eventSale: [],
      loading: true,
      indexCategory: 0,
      indexAlphabet: 0,
      indexPartners: 0,
      categoriesContent: {
        stores: [],
        brands: []
      },
      firstLoad: true
    }
  },
  computed: {
    ...mapState('Contents', [
      'productBrands',
      'productCategories',
      'productPartners',
      'isLoading'
    ]),
    ...mapState('Contents', ['articleParentingHub']),

    storeSimpleType () {
      return storeSimpleType
    },

    sortedBrands () {
      const brandsData = this.productBrands.all
      const groupedData = []
      brandsData &&
        brandsData.forEach((d) => {
          const index = groupedData.findIndex((a) => {
            return a.alphabet === d.name.charAt(0)
          })
          if (index >= 0) {
            const dummy = groupedData[index].data
            dummy.push(d)
            groupedData[index].data = dummy
          } else {
            groupedData.push({
              alphabet: d.name.charAt(0),
              data: [d]
            })
          }
        })
      return groupedData
    },
    sortedPartners () {
      const partners = this.productPartners
      const datas = []
      partners.forEach((d) => {
        const index = datas.findIndex((p) => {
          return p.alphabet === d.name.charAt(0)
        })
        if (index >= 0) {
          const dummy = datas[index].data
          dummy.push(d)
          datas[index].data = dummy
        } else {
          datas.push({
            alphabet: d.name.charAt(0),
            data: [d]
          })
        }
      })
      return datas
    },
    displayedCategoryChild () {
      if (this.indexCategory > -1) {
        const originalArr = this.productCategories[this.indexCategory].sub_categories
        const groupedArr = [[], [], []]

        originalArr.forEach((element, index) => {
          groupedArr[index % 3].push(element)
        })

        return groupedArr.filter(arr => arr.length > 0)
      }

      return []
    }
  },
  mounted () {
    this.initComponent()
  },
  methods: {
    ...mapActions('Contents', [
      'getProductCategoriesNavbar',
      'getPartnersNavbar',
      'getNavbarEventSale',
      'getEventSaleOngoing',
      'getNavbarCategoriesContent'
    ]),
    ...mapActions('Contents', ['getArticleParentingHub']),
    ...mapMutations('Contents', ['setLoading', 'setEventSale']),
    async initComponent () {
      this.setLoading(true)
      try {
        const resp = await this.getNavbarEventSale()
        if (resp.status === 200) {
          this.eventSale = resp.data.data.event_sales
          this.setEventSale(this.eventSale)
        }
      } catch (error) {
        console.log(error)
      }
      this.firstLoad = false

      const fetchNavbarData = [
        this.handleGetCategories(),
        this.handleGetBrands(),
        this.handleGetPartner(),
        this.handleCategoryContent(),
        this.handleArticle()
      ]

      try {
        await Promise.all(fetchNavbarData)
      } catch (error) {
        console.log(error)
      }
    },
    async handleArticle () {
      try {
        await this.getArticleParentingHub()
      } catch (error) {
        console.error(error)
      }
    },
    handleGoTo (url) {
      window.location = url
    },
    async handleCategoryContent () {
      try {
        const resp = await this.getNavbarCategoriesContent()
        if (resp.status === 200) {
          this.categoriesContent.stores = resp.data.data.stores
          this.categoriesContent.brands = resp.data.data.brands
        }
      } catch (error) {
        console.log(error)
      }
    },
    async handleGetBrands () {
      await this.getPartnersNavbar({
        data: {
          type: 'brand_partner'
        }
      })
    },
    async handleGetCategories () {
      try {
        await this.getProductCategoriesNavbar({
          data: {
            order_by: {
              name: 'asc'
            }
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    async handleGetPartner () {
      this.indexAlphabet = 0
      this.indexPartners = 0
      try {
        await this.getPartnersNavbar({
          data: {
            search: this.input5
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    searchFunction (val) {
      this.listSearch = []
      const data = this.sortedBrands
      const list = []
      data.forEach((d) => {
        d.data.filter((child) => {
          if (child.name.toLowerCase().includes(val.toLowerCase())) {
            list.push(child)
          }
        })
      })
      const brandsData = list
      brandsData.forEach((d) => {
        const index = this.listSearch.findIndex((a) => {
          return a.alphabet === d.name.charAt(0)
        })
        if (index >= 0) {
          const dummy = this.listSearch[index].data
          dummy.push(d)
          this.listSearch[index].data = dummy
        } else {
          this.listSearch.push({
            alphabet: d.name.charAt(0),
            data: [d]
          })
        }
      })
    },
    searchStoreFunction: _.debounce(function () {
      this.setLoading(true)
      this.handleGetPartner()
    }, 700),
    changeIndexPartners (index, idx) {
      this.indexAlphabet = index
      this.indexPartners = idx
    },
    getData (origin, idx, row) {
      const start = idx * row
      return origin.slice(start, start + row)
    }
  }
}
