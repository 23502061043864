//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { isArrayNotNullOrEmpty } from '@/utils/helpers/array-helper'
import { isObjectNotEmptyOrNull } from '@/utils/helpers/object-helper'
import orderModuleMixins from '@/mixins/order-module-mixins'
export default {
  mixins: [orderModuleMixins],
  data () {
    return {
      activeNames: ['1'],
      selectedPayment: '',
      value: '',
      selectedAccount: {},
      selectedInstallment: ''
    }
  },
  computed: {
    ...mapState('User', ['accounts']),
    ...mapState('Checkout', ['checkoutAvailability']),
    ...mapGetters('Checkout', ['checkoutPaymentMethods']),
    listCreditCard () {
      return this.accounts.filter((account) => {
        return account.type === 'Credit Card'
      })
    },
    listInstallment () {
      if (isObjectNotEmptyOrNull(this.checkoutAvailability) &&
      isObjectNotEmptyOrNull(this.checkoutAvailability.credit_card) &&
      isArrayNotNullOrEmpty(this.checkoutAvailability.credit_card.installments)) {
        return this.checkoutAvailability.credit_card.installments
      }
      return []
    }
  },
  async created () {
    await this.initComponent()
  },
  methods: {
    isArrayNotNullOrEmpty,
    ...mapActions('User', ['getPayment']),
    ...mapMutations('Checkout', ['setSelectedPaymentAccount', 'setSelectedCCInstallment']),
    async handleChangePaymentMethod (payment) {
      this.setSelectedPaymentMethod(payment)

      if (payment.code !== 'credit_card') {
        await this.checkAvaibilityCheckout()
      }
    },
    async initComponent () {
      try {
        await this.getPayment({
          data: {
            paginated: false,
            per_page: 10,
            page: this.page,
            where: {
              type: 'Credit Card'
            }
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    async handleChangeCreditAccount (value) {
      this.selectedAccount = value
      this.setSelectedPaymentAccount(value)

      if (value) {
        await this.checkAvaibilityCheckout()
      }
    },
    async handleChangeInstallment (value) {
      this.setSelectedCCInstallment(value)

      await this.checkAvaibilityCheckout()
    }
  }
}
