//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { toastTypes, defaultToastOptions } from '@/utils/helpers/toast-helper'
export default {
  mixins: [mixins],
  props: {
    onAccountPage: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    const validateNumberOnly = (rule, value, callback) => {
      const letters = /^[0-9]+$/
      const field = rule.field
      const input = value.charAt(value.length - 1)
      if (value === ' ') {
        this.formData[field] = ''
      }
      if (!input.match(letters)) {
        callback(new Error('Inputan hanya berupa angka'))
        this.formData[field] = value.substring(0, value.length - 1)
      } else {
        callback()
      }
    }
    return {
      formData: {
        account_number: '',
        expired_date: '',
        expired_month: '',
        expired_year: '',
        cvv: '',
        save: true,
        card_holder: ''
      },
      rules: {
        account_number: [
          {
            required: true,
            message: 'Masukan nomor kartu kredit',
            trigger: 'blur'
          },
          {
            validator: validateNumberOnly
          }
        ],
        expired_date: [
          {
            required: true,
            message: 'Masukan masa berlaku kartu',
            trigger: 'change'
          }
        ],
        expired_month: [
          {
            required: true,
            message: 'Masa berlaku harus diisi',
            trigger: 'blur'
          },
          {
            validator: validateNumberOnly
          }
        ],
        expired_year: [
          {
            required: true,
            message: 'Masa berlaku harus diisi',
            trigger: 'blur'
          },
          {
            validator: validateNumberOnly
          }
        ],
        cvv: [
          {
            required: true,
            message: 'Masukan CVV',
            trigger: 'blur'
          },
          {
            min: 3,
            message: 'Masukan 3 digit CVV',
            trigger: 'blur'
          },
          {
            validator: validateNumberOnly
          }
        ],
        card_holder: [
          {
            required: true,
            message: 'Nama Lengkap wajib disi',
            trigger: 'blur'
          }
        ]
      },
      onSubmit: false,
      onDraw: false,
      datePickerOpt: {
        disabledDate (time) {
          return time.getTime() < Date.now()
        }
      }
    }
  },
  computed: {
    ...mapState('Checkout', [
      'pageTakeInStore',
      'selectedAddress',
      'selectedCourier',
      'branchSelected',
      'storeBranch',
      'storeBranchPAS',
      'selectedBank',
      'listPaymentMethods',
      'usePoint'
    ]),
    ...mapState('Voucher', ['voucherUsed']),
    ...mapGetters('Voucher', ['voucherUsedId']),
    ...mapState('User', ['accounts'])
  },
  methods: {
    ...mapMutations('Checkout', ['setSelectedBank']),
    ...mapMutations('User', ['setLink3DS', 'setDataAccount']),
    ...mapActions('User', [
      'createPayment',
      'getPayment',
      'createTokenCC',
      'checkValidCC'
    ]),
    handleModalClose () {
      this.$refs.formDataCC.resetFields()
    },
    getMonthFormat () {
      let tempDate = new Date(this.formData.expired_date).getMonth() + 1
      if (tempDate < 10) {
        tempDate = `0${tempDate}`
      }
      return tempDate.toString()
    },
    handleSubmit (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (!valid) { return }

        this.onSubmit = true

        try {
          // Initial credit card availability check
          const checkResponse = await this.checkValidCC({
            data: {
              credit_card: {
                card_number: this.formData.account_number
              }
            }
          })

          if (!checkResponse.status === 200 || !checkResponse.data.data.available_for_new_account) {
            this.$message({
              ...defaultToastOptions(),
              message: 'Kartu kredit tidak dapat digunakan / telah terdaftar !',
              type: toastTypes.error.value,
              showClose: true
            })
            return
          }

          // Validate card details using Xendit
          const expYear = '20' + this.formData.expired_year
          const validations = [
            window.Xendit.card.validateCardNumber(this.formData.account_number),
            window.Xendit.card.validateExpiry(this.formData.expired_month, expYear),
            window.Xendit.card.validateCvn(this.formData.cvv)
          ]

          if (validations.some(validation => !validation)) {
            this.$message({
              ...defaultToastOptions(),
              message: 'Periksa kembali credit card yang dimasukan',
              type: toastTypes.error.value,
              showClose: true
            })
            return
          }

          // Prepare payload
          const payload = {
            credit_card: {
              amount: 5000,
              card_number: this.formData.account_number,
              card_exp_month: this.formData.expired_month,
              card_exp_year: expYear,
              card_cvv: this.formData.cvv,
              is_multiple_use: false,
              should_authenticate: true
            }
          }

          // Create token
          const tokenResponse = await this.createTokenCC(payload.credit_card)

          if (tokenResponse.status !== 'IN_REVIEW') {
            throw new Error('Token creation failed')
          }

          // Set account data
          this.setDataAccount({
            account_number: this.formData.account_number,
            card_charge_amount: 5000,
            card_token_id: tokenResponse.id,
            card_authentication_id: tokenResponse.authentication_id || null,
            card_exp_month: this.formData.expired_month,
            card_exp_year: expYear,
            card_cvv: this.formData.cvv,
            card_holder: this.formData.card_holder
          })

          // Handle 3DS authentication or payment
          if (tokenResponse.payer_authentication_url) {
            this.setLink3DS(tokenResponse.payer_authentication_url)
            this.$emit('show-modal-3ds')
            this.$refs.modalPopupFormCreditCard.closeModal()
          } else if (this.formData.save) {
            const paymentResponse = await this.createPayment({ data: payload })

            if (paymentResponse.status === 200) {
              this.getDataAccounts()
            }
          }
        } catch (error) {
          // Centralized error handling
          const errorMessage = error.response ? error.response.data.message : error.toString()
          this.$message({
            ...defaultToastOptions(),
            message: errorMessage,
            type: toastTypes.error.value,
            showClose: true
          })
        } finally {
          this.onSubmit = false
        }
      })
    },
    async getDataAccounts () {
      try {
        await this.getPayment({
          data: {
            paginated: false,
            per_page: 10,
            page: this.page,
            where: {
              type: 'Credit Card'
            }
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    getDataBranch () {
      if (this.pageTakeInStore) {
        return this.storeBranchPAS
      } else {
        return this.storeBranch
      }
    },
    resetForm () {
      this.formData = Object.assign({
        account_number: '',
        expired_date: '',
        cvv: '',
        save: true
      })
    }
  }
}
