//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'

import windowResizeMixin from '@/mixins/window-resize-mixin'

import { defaultImages, fileVariants } from '@/utils/helpers/file-helper'
import { storeFileNames, storeTypes } from '@/utils/helpers/store-helper'
import {
  assignResponseToDataModelDataNotPaginated,
  defaultNotPaginationAttributes
} from '@/utils/helpers/pagination-helper'

export default {
  mixins: [
    windowResizeMixin
  ],

  data () {
    return {
      relatedPartners: {
        ...defaultNotPaginationAttributes()
      }
    }
  },

  computed: {
    ...mapState('Partner/PartnerProfile', [
      'partner'
    ]),

    storeTypes () {
      return storeTypes
    },

    defaultImages () {
      return defaultImages
    },

    fileVariants () {
      return fileVariants
    },

    storeFileNames () {
      return storeFileNames
    }
  },

  mounted () {
    this.getPartnerRelatedData()

    this.$nextTick(() => {
      setTimeout(() => {
        this.observeSpaceBetweenHeaderAndBody()
      }, 1000)
    })
  },

  methods: {
    ...mapActions('Partner/PartnerProfile', [
      'getRelatedPartners'
    ]),

    observeSpaceBetweenHeaderAndBody () {
      const observer = new ResizeObserver(this.calculateSpaceBetweenHeaderAndBody)
      observer.observe(this.$refs.partnerProfileHeaderCard.$el)
    },

    calculateSpaceBetweenHeaderAndBody () {
      if (!this.$refs.partnerProfileHeaderCard) {
        return
      }

      const childHeight = this.$refs.partnerProfileHeaderCard.$el.offsetHeight
      let translateOffset = 0

      if (this.windowWidth <= 375) {
        translateOffset = childHeight * 0.95
      } else if (this.windowWidth <= 1024) {
        translateOffset = childHeight * 0.9
      } else {
        translateOffset = childHeight * 0.5
      }

      this.$emit('space-between-header-and-body-on-change', translateOffset)
    },

    async getPartnerRelatedData () {
      if (!this.partner || (this.partner && this.partner.type === storeTypes.storePartner.value)) {
        assignResponseToDataModelDataNotPaginated(
          this.relatedPartners,
          this.partner.branches.map(branch => ({
            ...branch,
            store_type: this.partner.type
          }))
        )
        return
      }

      try {
        const response = await this.getRelatedPartners({
          id: this.partner.slug,
          payload: {
            data: {
              paginated: this.relatedPartners.paginated
            }
          }
        })

        assignResponseToDataModelDataNotPaginated(
          this.relatedPartners,
          response.data.data.stores.map(store => ({
            ...store,
            store_type: this.partner.type
          }))
        )
      } catch (error) {
        console.error(error)
      }
    }
  }
}
