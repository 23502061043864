import { render, staticRenderFns } from "./CardPartnerRelated.vue?vue&type=template&id=d565d646&scoped=true&"
import script from "./CardPartnerRelated.vue?vue&type=script&lang=js&"
export * from "./CardPartnerRelated.vue?vue&type=script&lang=js&"
import style0 from "./CardPartnerRelated.vue?vue&type=style&index=0&id=d565d646&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d565d646",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Badge: require('/usr/src/app/components/base/Badge.vue').default,IconPinLocation: require('/usr/src/app/components/icons/IconPinLocation.vue').default,DividerVertical: require('/usr/src/app/components/base/DividerVertical.vue').default,IconStar: require('/usr/src/app/components/icons/IconStar.vue').default,Button: require('/usr/src/app/components/base/Button.vue').default})
