//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  fileVariants,
  getDefaultImageFromFiles,
  getDefaultImgProductOnError,
  getDefaultStoreLogoOnError
} from '@/utils/helpers/file-helper'
import { formatRupiah, generateRandomString } from '@/utils/helpers/string-helper'
import { getShareLinkSocialMediaUrl, socialMedias } from '@/utils/helpers/social-media-helper'
import { getStoreTypeLabelFromStoreType, storeFileNames } from '@/utils/helpers/store-helper'

export default {
  props: {
    partner: {
      type: Object,
      default: () => ({
        name: '',
        type: '',
        share_link: '',
        share_text: '',
        rating: 0,
        total_sold: 0,
        files: []
      })
    }
  },

  data () {
    return {
      //
    }
  },

  computed: {
    storeFileNames () {
      return storeFileNames
    },
    socialMedias () {
      return socialMedias
    },

    fileVariants () {
      return fileVariants
    },

    partnerShareText () {
      return this.partner ? String(this.partner.share_text).trim() : ''
    }
  },

  methods: {
    getStoreTypeLabelFromStoreType,
    getDefaultStoreLogoOnError,
    generateRandomString,
    formatRupiah,
    getDefaultImgProductOnError,
    getDefaultImageFromFiles,

    openCopyLinkSuccessAlert () {
      this.$message({
        message: 'Tautan berhasil disalin ke clipboard',
        type: 'success'
      })
    },

    openWhatsappShareLink () {
      window.open(
        getShareLinkSocialMediaUrl(socialMedias.whatsapp.code, this.partnerShareText),
        '_blank',
        'width=500,height=400'
      )
    }
  }
}
