//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    cardBodyClass: {
      type: String,
      default: () => ''
    }
  },

  computed: {
    hasHeaderSlot () {
      return !!this.$slots.header
    },

    hasBodySlot () {
      return !!this.$slots.body
    },

    hasFooterSlot () {
      return !!this.$slots.footer
    }
  }
}
