//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

import windowResizeMixin from '@/mixins/window-resize-mixin'

import { defaultNotPaginationAttributes } from '@/utils/helpers/pagination-helper'
import { fileVariants, getDefaultImageFromFiles, getDefaultShowcaseImageOnError } from '@/utils/helpers/file-helper'
import { showcaseFileNames } from '@/utils/helpers/showcase-helper'

export default {
  mixins: [
    windowResizeMixin
  ],

  props: {
    partnerShowCases: {
      type: Object,
      default: () => ({
        ...defaultNotPaginationAttributes()
      })
    }
  },

  data () {
    return {
      sliderArrow: {
        left: '<img src="/img/icon/chevron-left.svg" alt=""/>',
        right: '<img src="/img/icon/chevron-right.svg" alt=""/>'
      }
    }
  },

  computed: {
    ...mapState('Partner/PartnerProfile', [
      'partnerProfileTabItems', 'partnerProfileHomepageSections'
    ]),

    fileVariants () {
      return fileVariants
    },

    showcaseFileNames () {
      return showcaseFileNames
    },

    paramId () {
      return this.$route.params.id
    }
  },

  methods: {
    getDefaultShowcaseImageOnError,
    getDefaultImageFromFiles,

    handleShowCaseItemOnClick (showcase) {
      this.$router.push({
        path: `/partner/${this.paramId}`,
        query: {
          partnerProfileTab: this.partnerProfileTabItems.allProduct.name,
          partnerProductsShowcase: showcase.slug
        }
      })

      this.scrollToTop()
    }
  }
}
