//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isObjectNotEmptyOrNull } from '@/utils/helpers/object-helper'
export default {
  async asyncData ({ store }) {
    let eventSale = []

    try {
      const resp = await store.dispatch('Contents/getOngoingEventSale', {
        data: {
          where_has: [
            {
              relation: 'event_sale',
              where: {
                type: 'event_sale'
              }
            }
          ]
        }
      })

      eventSale = resp.data.data.event_sale_sessions
    } catch (error) {
      console.log(error)
    }

    return {
      eventSale
    }
  },
  data () {
    return {
      detailSectionSale: {},
      search: ''
    }
  },
  computed: {
    eventSaleSession () {
      return Array.isArray(this.eventSale) && this.eventSale.length > 0
        ? this.eventSale.find((evt) => {
            return evt.event_sale.slug === this.$route.params.id
          })
        : null
    },
    breadCrumb () {
      const breadCrumb = [
        {
          path: '/'
        }
      ]

      if (this.eventSale) {
        breadCrumb.push({
          name: this.eventSaleSession.event_sale.name,
          path: `/sale/${this.eventSaleSession.event_sale.slug}`
        })

        if (isObjectNotEmptyOrNull(this.detailSectionSale)) {
          breadCrumb.push({
            name: this.detailSectionSale.title
          })
        }
      }

      return breadCrumb
    }
  },
  methods: {
    isObjectNotEmptyOrNull,
    setSectionSaleDetail (value) {
      this.detailSectionSale = value
    },
    onSearch () { }
  }
}
