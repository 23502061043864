//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'
import { mapState, mapActions } from 'vuex'
import { isObjectNotEmptyOrNull } from '@/utils/helpers/object-helper'
import { toastTypes, defaultToastOptions } from '@/utils/helpers/toast-helper'
import { defaultSwal2Options } from '@/utils/helpers/swal2-helper'
import { getResponseErrorMessage } from '@/utils/helpers/response-helper'

export default {
  props: {
    source: {
      type: String,
      default: 'listAddress'
    },
    type: {
      type: String,
      default: 'add'
    },
    editedData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    const validateNumberOnly = (rule, value, callback) => {
      const input = value.charAt(value.length - 1)
      const inputBefore = value.charAt(value.length - 2)
      const letters = /^[0-9]+$/

      if (value.length === 1) {
        if (value === ' ') {
          this.formData[rule.field] = ''
        } else if (value === '0') {
          this.formData[rule.field] = ''
        }
      }

      if (value === ' ') {
        this.formData[rule.field] = ''
      }

      if (!input.match(letters)) {
        this.formData[rule.field] = value.substr(
          0,
          value.length - 1
        )
      } else if (input.match(' ') && input.match(inputBefore)) {
        this.formData[rule.field] = value.substr(
          0,
          value.length - 1
        )
      } else if (inputBefore.match(' ')) {
        this.formData[rule.field] =
          value.substr(0, value.length - 1) + input.toUpperCase()
      }

      if (this.formData[rule.field].length < 10) {
        callback(new Error('No. Telepon minimal 10 angka'))
      }

      callback()
    }

    return {
      formData: {
        title: '',
        recipient_name: '',
        phone_number: '',
        province: '',
        city: '',
        subdistrict: '',
        village_id: '',
        postal_code: '',
        detail_address: '',
        note: '',
        main: false,
        latitude: '',
        longitude: ''
      },
      rules: {
        title: [
          { required: true, message: 'Label Alamat harus diisi', trigger: 'blur' }
        ],
        recipient_name: [
          { required: true, message: 'Nama Penerima harus diisi', trigger: 'blur' }
        ],
        phone_number: [
          { required: true, message: 'No. Telepon harus diisi', trigger: ['blur', 'change'] },
          { validator: validateNumberOnly }
        ],
        province: [
          { required: true, message: 'Provinsi harus diisi', trigger: 'change' }
        ],
        city: [
          { required: true, message: 'Kota/Kabupaten harus diisi', trigger: 'change' }
        ],
        subdistrict: [
          { required: true, message: 'Kecamatan harus diisi', trigger: 'change' }
        ],
        village_id: [
          { required: true, message: 'Kelurahan harus diisi', trigger: 'change' }
        ],
        detail_address: [
          { required: true, message: 'Alamat Lengkap harus diisi', trigger: 'blur' }
        ],
        latitude: [
          { required: true, message: 'Titik lokasi harus diisi', trigger: 'blur' }
        ],
        longitude: [
          { required: true, message: 'Longitude harus diisi', trigger: 'blur' }
        ]
      },
      defaultPosition: { lat: -2.548926, lng: 118.0148634 },
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false
      }
    }
  },
  computed: {
    ...mapState('Region', ['provinces', 'cities', 'subdistricts', 'villages']),
    ...mapState('User/address', ['userAddress']),
    ...mapState('GoogleMaps', ['location'])
  },
  watch: {
    editedData: {
      handler () {
        if (this.type === 'edit' && isObjectNotEmptyOrNull(this.editedData)) {
          this.setDataForm()
        }
      },
      deep: true
    }
  },
  async mounted () {
    await this.initComponent()
  },
  methods: {
    isObjectNotEmptyOrNull,
    ...mapActions('Region', [
      'getProvinces',
      'getCities',
      'getSubdistricts',
      'getVillages'
    ]),
    ...mapActions('User/address', ['addUserAddress', 'editUserAddress', 'getUserAddress']),
    ...mapActions('GoogleMaps', ['getLocation']),
    handleModalClose () {
      this.formData = {
        title: '',
        recipient_name: '',
        phone_number: '',
        province: '',
        city: '',
        subdistrict: '',
        village_id: '',
        postal_code: '',
        detail_address: '',
        note: '',
        main: false,
        latitude: '',
        longitude: ''
      }

      this.defaultPosition = { lat: -2.548926, lng: 118.0148634 }

      this.$refs.formPopupAddress.resetFields()
    },
    async initComponent () {
      await this.getProvinces()
      this.formData.main = !this.userAddress.length
    },
    async setDataForm () {
      this.formData = {
        title: this.editedData.title,
        recipient_name: this.editedData.recipient_name,
        phone_number: this.editedData.phone_number,
        province: this.editedData.village.subdistrict.city.province.id,
        city: this.editedData.village.subdistrict.city.id,
        subdistrict: this.editedData.village.subdistrict.id,
        village_id: this.editedData.village.id,
        postal_code: this.editedData.village.id,
        detail_address: this.editedData.detail_address,
        note: this.editedData.note,
        main: this.editedData.main,
        latitude: this.editedData.latitude,
        longitude: this.editedData.longitude
      }

      this.defaultPosition.lat = Number(this.formData.latitude)
      this.defaultPosition.lng = Number(this.formData.longitude)

      await this.handleGetCities()
      await this.handleGetSubdistricts()
      await this.handleGetVillages()
      await this.handleChangePosition({
        latLng: {
          lat: Number(this.formData.latitude),
          lng: Number(this.formData.longitude)
        }
      }, false)
    },
    async handleChange (target) {
      if (target === 'province') {
        await this.handleGetCities()
        this.formData.city = ''
        this.formData.subdistrict = ''
        this.formData.village_id = ''
        this.formData.postal_code = ''
      } else if (target === 'city') {
        await this.handleGetSubdistricts()
        this.formData.subdistrict = ''
        this.formData.village_id = ''
        this.formData.postal_code = ''
      } else if (target === 'subdistrict') {
        await this.handleGetVillages()
        this.formData.village_id = ''
        this.formData.postal_code = ''
      } else if (target === 'village_id') {
        this.villages.map((village) => {
          if (village.id === Number(this.formData.village_id)) {
            this.formData.postal_code = village.postal_code
          }
        })
      }
      this.getDefaultMap()
    },
    async handleGetCities () {
        try {
          await this.getCities({
            data: {
              where: {
                province_id: this.formData.province
              }
            }
          })
        } catch (error) {
          console.log(error)
        }
    },
    async handleGetSubdistricts () {
        try {
          await this.getSubdistricts({
            data: {
              where: {
                city_id: this.formData.city
              }
            }
          })
        } catch (error) {
          console.log(error)
        }
    },
    async handleGetVillages () {
        try {
          await this.getVillages({
            data: {
              where: {
                subdistrict_id: this.formData.subdistrict
              }
            }
          })
        } catch (error) {
          console.log(error)
        }
    },
    handleChangePosition (event, setLatLng = true) {
      if (setLatLng) {
        this.formData.latitude = event.latLng.lat().toString() || ''
        this.formData.longitude = event.latLng.lng().toString() || ''
      }
      this.$refs.markerLocation.$markerObject.setPosition(event.latLng)
    },
    handleInputDetailAddress: _.debounce(function () {
      this.getDefaultMap()
    }, 500),
    getDefaultMap () {
      if (
        this.formData.province &&
        this.formData.city &&
        this.formData.subdistrict &&
        this.formData.village_id &&
        this.formData.postal_code &&
        this.formData.detail_address
      ) {
        this.getDefaultLocation()
      }
    },
    async getDefaultLocation () {
      const province = this.provinces.filter((province) => {
        return province.id === Number(this.formData.province)
      })[0].name
      const city = this.cities.filter((city) => {
        return city.id === Number(this.formData.city)
      })[0]
      const subdistrict = this.subdistricts.filter((subdistrict) => {
        return subdistrict.id === Number(this.formData.subdistrict)
      })[0].name
      const village = this.villages.filter((village) => {
        return village.id === Number(this.formData.village_id)
      })[0].name
      const string =
        this.formData.detail_address +
        ', ' +
        village +
        ', ' +
        subdistrict +
        ', ' +
        city.type +
        ' ' +
        city.name +
        ', ' +
        province +
        ', ' +
        this.formData.postal_code

      try {
        const resp = await this.getLocation({
          data: {
            address: string
          }
        })

        if (resp.status === 200) {
          this.formData.latitude =
            resp.data.data.results[0].geometry.location.lat
          this.formData.longitude =
            resp.data.data.results[0].geometry.location.lng
          this.defaultPosition.lat =
            resp.data.data.results[0].geometry.location.lat
          this.defaultPosition.lng =
            resp.data.data.results[0].geometry.location.lng
          this.handleChangePosition({
            latLng: {
              lat: this.formData.latitude,
              lng: this.formData.longitude
            }
          }, false)
        }
      } catch (error) {
        console.log(error)
      }
    },
    validateForm () {
      this.$refs.formPopupAddress.validate((valid) => {
        console.log(valid)
      })
    },
    handleSubmit (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.onSubmit = true
          try {
            let resp = {}
            if (this.type === 'edit') {
              resp = await this.editUserAddress({ payload: { data: this.formData }, id: this.editedData.id })
            } else {
              resp = await this.addUserAddress({ data: this.formData })
            }
            if (resp.data.status_code === 201 || resp.data.status_code === 200) {
              this.$message({
                ...defaultToastOptions(),
                message: `Alamat berhasil di${this.type === 'edit' ? 'ubah' : 'tambahkan'}`,
                type: toastTypes.success.value
              })
            }

            this.$refs.modalPopupFormAddress.closeModal()
            this.$emit('address-created', this.source)
          } catch (error) {
            console.log(error)
            this.$Swal.fire({
              ...defaultSwal2Options(),
              icon: 'error',
              title: 'Gagal',
              text: getResponseErrorMessage(error),
              heightAuto: false
            })
          } finally {
            this.onSubmit = false
          }
        }
      })
    }
  }
}
