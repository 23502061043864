//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  props: {
    modelValue: [String, Number],
    label: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isFocused: false
    }
  },
  computed: {
    inputValue: {
      get () {
        return this.modelValue;
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    },
    cleanAttrs () {
      const { modelValue, ...attrs } = this.$attrs
      return attrs;
    }
  }
}
