//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import orderModuleMixins from '@/mixins/order-module-mixins'
import { mapActions, mapMutations } from 'vuex'
export default {
  mixins: [orderModuleMixins],
  data () {
    return {
      formCvv: {
        cvv: ''
      },
      rules: {
        cvv: [
          { required: true, message: 'Wajib Diisi', trigger: 'blur' },
          { min: 3, max: 4, message: 'Diisi 3 - 4 Karakter', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    ...mapActions('User', ['createTokenCC']),
    ...mapMutations('User', ['setLink3DS', 'setDataAccount']),
    submitCvv () {
      this.$refs.formCVV.validate(async (valid) => {
        if (valid) {
          const payload = {
            amount: this.checkoutAvailability.payment.total_payment || 5000,
            card_number: this.selectedPaymentAccount.value.account_number,
            card_exp_month: this.selectedPaymentAccount.value.card_exp_month,
            card_exp_year: this.selectedPaymentAccount.value.card_exp_year,
            card_cvv: this.formCvv.cvv,
            is_multiple_use: false,
            should_authenticate: true
          }

          try {
            const resp = await this.createTokenCC(payload)

            if (resp.status === 'IN_REVIEW') {
              const paymentAccountPayload = {
                ...this.selectedPaymentAccount
              }

              paymentAccountPayload.value = Object.assign(paymentAccountPayload.value, {
                card_token_id: resp.id,
                card_authentication_id: resp.authentication_id,
                card_cvv: this.formCvv.cvv,
                save_card: false,
                installment_interval: 'month'
              })

              this.setSelectedPaymentAccount(paymentAccountPayload)
              this.setLink3DS(resp.payer_authentication_url)
              this.$refs.modalPopupCVV.closeModal()
              this.$emit('show-modal-3ds')
            } else if (resp.status === 'VERIFIED') {
              // Show Loading
              this.$emit('handle')
              // Submit Create Order
            } else {
              this.$Swal.fire({
                icon: 'error',
                title: 'Pemberitahuan',
                text: 'Kartu Kredit tidak dapat digunakan.',
                heightAuto: false
              })
            }
          } catch (error) {
            if (error.error_code === 'VALIDATION_ERROR') {
              this.$Swal.fire({
                icon: 'error',
                title: 'Gagal',
                text: error.message,
                heightAuto: false
              })
            }
            console.log(error)
          }
        }
      })
    },
    handleModalClose () {

    }
  }
}
