//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'
import { mapState } from 'vuex'

import { filterNonNullValues } from '@/utils/helpers/object-helper'
import { showcaseSlugs } from '@/utils/helpers/showcase-helper'

export default {
  props: {
    spaceBetweenHeaderAndBody: {
      type: Number,
      default: () => 0
    }
  },

  data () {
    return {
      activePartnerProfileTab: '',
      search: ''
    }
  },

  computed: {
    ...mapState('Partner/PartnerProfile', [
      'partner', 'partnerProfileTabItems', 'sideFilterShowcases'
    ])
  },

  watch: {
    '$route.query': {
      handler (newValue, oldValue) {
        const isTabChange = newValue.partnerProfileTab !== oldValue.partnerProfileTab
        const isFilterSearchChange = newValue.partnerProductsSearch !== oldValue.partnerProductsSearch

        if (
          isTabChange &&
          newValue.partnerProfileTab === this.partnerProfileTabItems.homepage.name &&
          newValue.partnerProductsSearch && newValue.partnerProductsSearch !== ''
        ) {
          this.activePartnerProfileTab = this.partnerProfileTabItems.homepage.name
          this.search = ''
          this.pushFilterIntoRouteQuery({
            partnerProductsSearch: null
          })
          return
        }

        if (
          isFilterSearchChange &&
          newValue.partnerProfileTab === this.partnerProfileTabItems.homepage.name &&
          newValue.partnerProductsSearch && newValue.partnerProductsSearch !== ''
        ) {
          this.search = newValue.partnerProductsSearch
          this.activePartnerProfileTab = this.partnerProfileTabItems.allProduct.name

          this.pushFilterIntoRouteQuery({
            partnerProfileTab: this.partnerProfileTabItems.allProduct.name,
            partnerProductsShowcase: showcaseSlugs.allProduct
          })
          return
        }

        if (isTabChange) {
          this.activePartnerProfileTab = newValue.partnerProfileTab || this.partnerProfileTabItems.homepage.name
        }

        if (
          newValue.partnerProductsShowcase !== oldValue.partnerProductsShowcase ||
          newValue.partnerProductsPriceRange !== oldValue.partnerProductsPriceRange ||
          newValue.partnerProductsRating !== oldValue.partnerProductsRating ||
          newValue.partnerProductsSearch !== oldValue.partnerProductsSearch ||
          newValue.partnerProductsSort !== oldValue.partnerProductsSort
        ) {
          this.scrollToTopPartnerProfileBody()
        }
      },
      deep: true
    }
  },

  mounted () {
    this.populateRouteQueryToPageData()
  },

  methods: {
    populateRouteQueryToPageData () {
      const query = this.$route.query

      this.activePartnerProfileTab = query.partnerProfileTab || this.partnerProfileTabItems.homepage.name
      this.search = query.partnerProductsSearch || ''
    },

    handlePartnerProfileTabOnClick (tab) {
      this.pushFilterIntoRouteQuery({
        partnerProfileTab: tab.name
      })
    },

    handlePartnerProfileProductsFilterSearchOnInput: _.debounce(function (searchValue) {
      this.pushFilterIntoRouteQuery({
        partnerProductsSearch: searchValue || null
      })
    }, 500),

    // START - Others
    pushFilterIntoRouteQuery (query) {
      this.$router.push({
        query: filterNonNullValues({
          ...this.$route.query,
          ...query
        })
      })
    },

    scrollToTopPartnerProfileBody () {
      const targetTop = this.$refs.partnerProfileBody.getBoundingClientRect().top
      const containerTop = document.body.getBoundingClientRect().top
      const scrollPosition = targetTop - containerTop - 100

      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth'
      })
    }
    // END - Others
  }
}
