//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  async asyncData ({ store, params, error, redirect, app }) {
    try {
      const resp = await store.dispatch(
        'Order/getOrderPaymentDetail',
        params.order_id
      )
      const orderPayment = resp.data.data.order_payment
      const arrExpired = orderPayment.expired_at.split(' ')
      const dateExpiredArr = arrExpired[0].split('-')
      const reformedDate = dateExpiredArr.reverse().join('-')
      const reformedFullDate = `${reformedDate} ${arrExpired[1]}`

      const dateExpired = app.$dayjs(reformedFullDate, 'DD-MM-YYYY HH:mm:ss')
      const currentDate = app.$dayjs()
      const checkOrdersExceptWaiting = orderPayment.orders && orderPayment.orders.length ? orderPayment.orders.some((order) => {
        return order.status.slug !== 'menunggu-pembayaran'
      }) : false

      if ((resp.status === 200 && orderPayment.paid) || orderPayment.expired_at === null || dateExpired < currentDate || checkOrdersExceptWaiting) {
        redirect(
          `/account/shop/my-order/detail/${resp.data.data.order_payment.orders[0].id}`
        )
      }
    } catch (exerror) {
      error({ statusCode: 404, message: 'Halaman tidak ditemukan' })
    }
  },
  data () {
    const breadcrumbs = [
      {
        path: '/'
      },
      {
        path: '/cart',
        name: 'Keranjang'
      },
      {
        path: '/checkout',
        name: 'Checkout'
      },
      {
        name: 'Pembayaran'
      }
    ]
    return {
      breadCrumb: breadcrumbs
    }
  },
  methods: {
    toPaymentProve () {
      const orderId = this.$route.params.order_id
      this.$router.push(`/payment-confirmation/${orderId}`)
    },
    toShopping () {
      window.location.href = '/'
    }
  }
}
