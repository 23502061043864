//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDefaultImageFromFiles } from '@/utils/helpers/file-helper'
export default {
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    payment: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isChecked () {
      return this.modelValue === this.payment.id
    }
  },
  methods: {
    getDefaultImageFromFiles,
    handleCheck () {
      this.$emit('update:modelValue', this.$refs.paymentMethodItem.value)
      this.$emit('change', this.payment)
    }
  }
}
