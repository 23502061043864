import { render, staticRenderFns } from "./SearchResultProducts.vue?vue&type=template&id=bdd98bd2&scoped=true&"
import script from "./SearchResultProducts.vue?vue&type=script&lang=js&"
export * from "./SearchResultProducts.vue?vue&type=script&lang=js&"
import style0 from "./SearchResultProducts.vue?vue&type=style&index=0&id=bdd98bd2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bdd98bd2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconLeft: require('/usr/src/app/components/icons/IconLeft.vue').default,ProductsFilterMobile: require('/usr/src/app/components/base/product/ProductsFilterMobile.vue').default,CardPartnerRelated: require('/usr/src/app/components/base/partner/CardPartnerRelated.vue').default,SkeletonRelated: require('/usr/src/app/components/base/partner/SkeletonRelated.vue').default,CardProduct: require('/usr/src/app/components/home/base/CardProduct.vue').default,ProductCardSkeletonLoading: require('/usr/src/app/components/base/ProductCardSkeletonLoading.vue').default,EmptyState: require('/usr/src/app/components/base/EmptyState.vue').default})
