import { render, staticRenderFns } from "./PartnerRelatedDataItem.vue?vue&type=template&id=257b6c68&scoped=true&"
import script from "./PartnerRelatedDataItem.vue?vue&type=script&lang=js&"
export * from "./PartnerRelatedDataItem.vue?vue&type=script&lang=js&"
import style0 from "./PartnerRelatedDataItem.vue?vue&type=style&index=0&id=257b6c68&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "257b6c68",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconPinLocation: require('/usr/src/app/components/icons/IconPinLocation.vue').default,IconStore: require('/usr/src/app/components/icons/IconStore.vue').default})
