//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import windowResizeMixin from '@/mixins/window-resize-mixin'
import linkMixin from '@/mixins/link-mixin'

import { bannerDisplayTypes } from '@/utils/helpers/banner-helper'
import { fileVariants, getDefaultImageFromFiles } from '@/utils/helpers/file-helper'

export default {
  mixins: [
    windowResizeMixin,
    linkMixin
  ],

  props: {
    banners: {
      type: Array,
      default: () => []
    },

    fileName: {
      type: String,
      default: () => null
    }
  },

  data () {
    return {
      sliderBannerPartnerProfileCarousel: {
        dots: true,
        edgeFriction: 0.35,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  },

  computed: {
    fileVariants () {
      return fileVariants
    },

    filteredBanners () {
      const type = this.normalDesktop ? bannerDisplayTypes.normalDesktop : bannerDisplayTypes.normalPhone
      const data = this.banners.filter(banner => banner.display_type === type)

      return data.sort(function (a, b) {
        return a.position - b.position
      })
    }
  },

  methods: {
    getDefaultImageFromFiles
  }
}
