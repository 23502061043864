import { render, staticRenderFns } from "./PaymentInfo.vue?vue&type=template&id=e9c0e93c&"
import script from "./PaymentInfo.vue?vue&type=script&lang=js&"
export * from "./PaymentInfo.vue?vue&type=script&lang=js&"
import style0 from "./PaymentInfo.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Divider: require('/usr/src/app/components/base/Divider.vue').default,IconCopy: require('/usr/src/app/components/icons/IconCopy.vue').default,Button: require('/usr/src/app/components/base/Button.vue').default,PaymentGuide: require('/usr/src/app/components/home/payment-detail/PaymentGuide.vue').default})
