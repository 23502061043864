//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatRupiah } from '@/utils/helpers/string-helper'
import { isObjectNotEmptyOrNull } from '@/utils/helpers/object-helper'
import { getDefaultImageFromFiles } from '@/utils/helpers/file-helper'
export default {
  props: {
    storeBranchId: {
      type: String,
      default: ''
    },
    pickup: {
      type: Boolean,
      default: false
    },
    fulfilled: {
      type: Boolean,
      default: false
    },
    totalWeight: {
      type: Number,
      default: 0
    },
    shippingCouriers: {
      type: Array,
      default: () => ([])
    },
    pickupAddress: {
      type: String,
      default: ''
    },
    pickupSchedules: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      shippingPickupForm: {
        shippingCourier: '',
        note: ''
      }
    }
  },
  methods: {
    formatRupiah,
    getDefaultImageFromFiles,
    isObjectNotEmptyOrNull,
    getLabel (courier) {
      if (courier.etd === '0 HARI' || courier.etd === '0' || courier.etd === '0-0') {
        return 'Same Day'
      } else if (
        courier.etd === '1' ||
        courier.etd === '1 HARI' ||
        courier.etd === '1-1'
      ) {
        return 'Next Day'
      } else {
        return `Pengiriman ${
          courier.etd.substring(0, 1) === '?'
            ? 'Lainnya'
            : this.getETD(courier.etd)
        }`
      }
    },
    getETD (etd) {
      if (etd === '1-1' || etd === '1' || etd === '1 HARI') {
        return '1 Hari'
      } else if (etd.trim() === '') {
        return ''
      } else {
        if (
          etd.substring(etd.length - 4, etd.length).toLowerCase() === 'hari'
        ) {
          return etd.substring(0, etd.length - 4) + ' Hari'
        }
        if (etd.substring(etd.length - 3, etd.length).toLowerCase() === 'jam') {
          return etd.substring(0, etd.length - 4) + 'Jam'
        }
        return etd + ' Hari'
      }
    },
    removeSelectedCourier () {
      this.$refs.formShippingPickup.resetFields()
    },
    resetForm () {
      this.shippingPickupForm.shippingCourier = ''
      this.$emit('courier-change', {})
      this.shippingPickupForm.note = ''
      this.$emit('note-change', '')
    }
  }
}
