//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { getResponseErrorMessage } from '@/utils/helpers/response-helper'
import { toastTypes, defaultToastOptions } from '@/utils/helpers/toast-helper'
import { mapActions } from 'vuex'
export default {
  mixins: [mixins],
  props: {
    datas: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      indexImg: 0
    }
  },
  computed: {
    hasAvailableProducts () {
      return this.datas.some(data => data.is_available)
    }
  },
  methods: {
    ...mapActions('Cart', ['addBulkCart', 'getCartsNavbar']),
    async handleGetCarts () {
      try {
        await this.getCartsNavbar({
          params: {
            session_id: this.$cookies.get('session_id')
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    async handleAddBulkCart () {
      const selectedProducts = this.datas.map((data) => {
        return (
          data.is_available && {
            product_detail_id: data.detail_id,
            quantity: data.quantity > data.stock ? data.stock : data.quantity,
            store_id: data.store_id || '',
            is_fulfilled: data.is_fulfilled || false
          }
        )
      })
      try {
        const resp = await this.addBulkCart({
          data: {
            carts: selectedProducts
          }
        })
        if (resp.status === 200) {
          this.handleGetCarts()
          this.$message({
            ...defaultToastOptions(),
            message: 'Berhasil menambahkan produk ke keranjang',
            type: toastTypes.success.value
          })
          this.$refs.modalRebuyProduct.$refs.buttonClose.click()
        }
      } catch (error) {
        this.$message({
          ...defaultToastOptions(),
          message: getResponseErrorMessage(error),
          type: toastTypes.error.value
        })
        console.error(error)
      }
    }
  }
}
