import { render, staticRenderFns } from "./HomePartnerNew.vue?vue&type=template&id=1d45be34&scoped=true&"
import script from "./HomePartnerNew.vue?vue&type=script&lang=js&"
export * from "./HomePartnerNew.vue?vue&type=script&lang=js&"
import style0 from "./HomePartnerNew.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./HomePartnerNew.vue?vue&type=style&index=1&id=1d45be34&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d45be34",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VoucherProfileSection: require('/usr/src/app/components/base/VoucherProfileSection.vue').default,LorikeetProfileSection: require('/usr/src/app/components/base/LorikeetProfileSection.vue').default,FlashsaleProfileSection: require('/usr/src/app/components/home/profile/FlashsaleProfileSection.vue').default,ThumbnailListProduct: require('/usr/src/app/components/base/ThumbnailListProduct.vue').default,ProductProfileSection: require('/usr/src/app/components/home/profile/ProductProfileSection.vue').default})
