import { render, staticRenderFns } from "./PartnerProfileHomepageVouchers.vue?vue&type=template&id=1067ec10&scoped=true&"
import script from "./PartnerProfileHomepageVouchers.vue?vue&type=script&lang=js&"
export * from "./PartnerProfileHomepageVouchers.vue?vue&type=script&lang=js&"
import style0 from "./PartnerProfileHomepageVouchers.vue?vue&type=style&index=0&id=1067ec10&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1067ec10",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VoucherCardSkeletonLoading: require('/usr/src/app/components/base/voucher/VoucherCardSkeletonLoading.vue').default,CardVoucherNew: require('/usr/src/app/components/base/voucher/CardVoucherNew.vue').default,Button: require('/usr/src/app/components/base/Button.vue').default,ModalDetailVoucher: require('/usr/src/app/components/base/voucher/ModalDetailVoucher.vue').default})
