import { render, staticRenderFns } from "./PartnerProfileBodyTabAllProduct.vue?vue&type=template&id=4004f153&scoped=true&"
import script from "./PartnerProfileBodyTabAllProduct.vue?vue&type=script&lang=js&"
export * from "./PartnerProfileBodyTabAllProduct.vue?vue&type=script&lang=js&"
import style0 from "./PartnerProfileBodyTabAllProduct.vue?vue&type=style&index=0&id=4004f153&scoped=true&lang=scss&"
import style1 from "./PartnerProfileBodyTabAllProduct.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4004f153",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Clamp: require('/usr/src/app/components/base/Clamp.vue').default,ProductsSideFilter: require('/usr/src/app/components/base/product/ProductsSideFilter.vue').default,Button: require('/usr/src/app/components/base/Button.vue').default,CardBase: require('/usr/src/app/components/base/CardBase.vue').default,IconLeft: require('/usr/src/app/components/icons/IconLeft.vue').default,ProductsFilterMobile: require('/usr/src/app/components/base/product/ProductsFilterMobile.vue').default,PartnerProducts: require('/usr/src/app/components/home/partner/PartnerProducts.vue').default})
