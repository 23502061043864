//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defaultStoreEmptyRating, storeFileNames, storeTypes } from '@/utils/helpers/store-helper'
import { fileVariants, getDefaultImageFromFiles, getDefaultStoreLogo } from '@/utils/helpers/file-helper'

export default {
  props: {
    partner: {
      type: Object,
      default: () => {}
    },

    type: {
      type: String,
      default: storeTypes.storePartner.value
    }
  },

  computed: {
    fileVariants () {
      return fileVariants
    },

    storeFileNames () {
      return storeFileNames
    },

    storeTypes () {
      return storeTypes
    },

    defaultStoreEmptyRating () {
      return defaultStoreEmptyRating
    }
  },

  methods: {
    getDefaultStoreLogo,
    getDefaultImageFromFiles,

    handleGoto (id) {
      this.$router.push('/partner/' + id)
    }
  }
}
