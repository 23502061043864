//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations, mapActions } from 'vuex'
import { toastTypes, defaultToastOptions } from '@/utils/helpers/toast-helper'
export default {
  computed: {
    ...mapState('User/address', ['userAddress']),
    ...mapState('Checkout', ['selectedAddress'])
  },
  methods: {
    ...mapMutations('Checkout', ['setSelectedAddress', 'setOrderPayload']),
    ...mapActions('User/address', ['getUserAddress', 'editUserAddress']),
    async fetchAddress () {
      try {
        await this.getUserAddress()
      } catch (error) {
        console.log(error)
      }
    },
    handleClickAddress (address) {
      this.setSelectedAddress(address)
      this.setOrderPayload({
        user_address_id: address.id
      })
      this.$refs.modalPopupListAddress.closeModal()
      this.$emit('address-selected')
    },
    async setAsMainAddress (address) {
      try {
        await this.editUserAddress({
          id: address.id,
          payload: {
            data: {
              main: true
            }
          }
        })

        this.$message({
          ...defaultToastOptions(),
          message: 'Alamat berhasil diubah menjadi alamat utama',
          type: toastTypes.success.value
        })
        this.fetchAddress()
      } catch (error) {
        console.log(error)
        this.$message({
          ...defaultToastOptions(),
          message: 'Gagal mengubah alamat menjadi alamat utama',
          type: toastTypes.error.value
        })
      }
    },
    openModalFormAddress () {
      this.$refs.modalPopupListAddress.closeModal()
      this.$emit('open-add-address', {
        type: 'add'
      })
    },
    setToEditAddress (value) {
      this.$refs.modalPopupListAddress.closeModal()
      this.$emit('open-add-address', {
        type: 'edit',
        editedData: value
      })
    },
    handleModalClose () {

    }
  }
}
