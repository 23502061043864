//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations, mapState } from 'vuex'
import { paymentMethods } from '@/utils/helpers/checkout-helper'
import { defaultToastOptions, toastTypes } from '@/utils/helpers/toast-helper'
export default {
  data () {
    return {
      pusher: null,
      channel: null,
      configPusher: null,
      orderSuccess: false
    }
  },
  computed: {
    ...mapState('Order', ['order', 'onSuccess'])
  },
  mounted () {
    this.configPusher = {
      cluster: process.env.PUSHER_CLUSTER,
      forceTLS: true,
      encrypted: true,
      authEndpoint: `${process.env.BASE_API}/v1/broadcasting/auth`,
      auth: {
        headers: {
          Authorization: `${this.$auth.getToken('local')}`
        }
      }
    }
    this.handlePusherMessage()
  },
  beforeDestroy () {
    // unsub if pusher is subscribed
    if (this.pusher && this.orderSuccess) {
      this.pusher.unsubscribe(
        `private-user.${this.$store.state.auth.user.id}.create-order`
      )
      this.$pusher.disconnectPusher()
    }
  },
  methods: {
    ...mapMutations('Order', ['setOrderID']),
    handlePusherMessage () {
      if (this.pusher === null) {
        this.pusher = this.$pusher.getPusher(this.configPusher)
        this.channel = this.pusher.subscribe(
          `private-user.${this.$store.state.auth.user.id}.create-order`
        )
          this.channel.bind('order-created', (data) => {
            this.handleSuccess(data)
            this.pusher.unsubscribe(
              `private-user.${this.$store.state.auth.user.id}.create-order`
            )
          })
      }
    },
    handleSuccess (data) {
      if (data.status_code === 200 || data.status_code === 201) {
        this.orderSuccess = true

        if (localStorage.cartItem) {
          localStorage.removeItem('cartItem')
          localStorage.removeItem('cartPrize')
        }

        if (localStorage.note) {
          localStorage.removeItem('note')
        }

        if (localStorage.voucher) {
          localStorage.removeItem('voucher')
        }

        if (localStorage.usePoint) {
          localStorage.removeItem('usePoint')
        }

        this.$message({
          ...defaultToastOptions(),
          message: 'Pesanan berhasil dibuat',
          type: toastTypes.success.value
        })

        this.closeModal()

        // Redirect to countdown if payment are bank transfer and virtual account
        if (
          data.data.payment_detail.payment_method.type.code ===
          paymentMethods.bankTransfer.value ||
          data.data.payment_detail.payment_method.type.code ===
          paymentMethods.virtualAccount.value
        ) {
          setTimeout(() => {
            window.location.href = `/payment-detail/${data.data.payment_detail.order_payment.id}`
          }, 2000)
        } else if (
          data.data.payment_detail.payment_method.type.code === 'credit_card'
        ) {
          if (data.data.orders.length === 1) {
            setTimeout(() => {
              window.location.href = `/account/shop/my-order/detail/${data.data.orders[0].id}`
            }, 2000)
          } else {
            setTimeout(() => {
              window.location.href = '/account/shop/my-order'
            }, 2000)
          }
        }
      } else {
        setTimeout(() => {
          this.closeModal()
        }, 500)

        this.$message({
          ...defaultToastOptions(),
          message: 'Gagal membuat pesanan, periksa kembali koneksi internet Anda dan coba beberapa saat lagi',
          type: toastTypes.error.value
        })

        setTimeout(() => {
          window.location.href = '/cart'
        }, 1000)
      }
    },
    closeModal () {
      if (this.$refs.modalPopupCheckoutLoading) {
        this.$refs.modalPopupCheckoutLoading.closeModal()
      }
    }
  }
}
