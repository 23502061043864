//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'
import { StatusCodes } from 'http-status-codes'

import {
  getStoreTypeLabelFromStoreType,
  storeFileNames,
  storeSimpleType,
  storeTypes
} from '@/utils/helpers/store-helper'
import { defaultImages, fileVariants, getDefaultImageFromFiles } from '@/utils/helpers/file-helper'

export default {
  async asyncData ({ store, params, error }) {
    try {
      await store.dispatch('Partner/PartnerProfile/showPartner', params.id)
    } catch (err) {
      if (err.response && err.response.status === StatusCodes.NOT_FOUND) {
        error({
          statusCode: StatusCodes.NOT_FOUND,
          image: require('@/static/img/error/404.svg'),
          message: 'Partner Tidak Ditemukan',
          subMessage: 'Maaf Partner yang dimaksud tidak ada. Mohon untuk kembali ke beranda',
          type: 'partner-not-found'
        })
      } else {
        error({
          statusCode: err.response ? err.response.status : StatusCodes.INTERNAL_SERVER_ERROR
        })
      }
    }
  },

  data () {
    return {
      spaceBetweenHeaderAndBody: 0
    }
  },

  computed: {
    ...mapState('Partner/PartnerProfile', [
      'partner'
    ]),

    breadCrumb () {
      let partnerPath = '/partner'
      switch (this.partner ? this.partner.type : '') {
        case storeTypes.storePartner.value:
          partnerPath = '/partner?type=' + storeSimpleType.store_partner
          break
        case storeTypes.brandPartner.value:
          partnerPath = '/partner?type=' + storeSimpleType.brand_partner
          break
      }

      return [
        {
          path: '/'
        },
        {
          name: getStoreTypeLabelFromStoreType(this.partner ? this.partner.type : ''),
          path: partnerPath
        },
        {
          name: this.partner ? this.partner.name : '',
          path: ''
        }
      ]
    },

    // START - SEO
    partnerSEOTitle () {
      return this.partner ? `${this.partner.name} - Toko Online Terbaik di Paninti Store` : ''
    },

    partnerSEODescription () {
      if (!this.partner) { return '' }

      const { description, name } = this.partner

      if (
        description == null ||
        (typeof description === 'string' && !description.trim())
      ) {
        return `Temukan produk terbaik dan terbaru di toko ${name}. Dapatkan harga terbaik dan pengalaman belanja online yang nyaman dan aman. Jelajahi katalog lengkap kami dan temukan barang impianmu hari ini.`
      }

      return description
    },

    partnerSEOLogoImage () {
      if (!this.partner) {
        return process.env.URL + defaultImages.defaultStoreLogo
      }

      return getDefaultImageFromFiles(
        this.partner.files, storeFileNames.logo, fileVariants.thumbnail, true
      )
    }
    // END - SEO
  },

  mounted () {
    this.getPageData()
  },

  methods: {
    ...mapActions('Partner/PartnerProfile', [
      'getShowcasePartner'
    ]),

    spaceBetweenHeaderAndBodyOnChange (space) {
      this.spaceBetweenHeaderAndBody = space
    },

    getPageData () {
      Promise.allSettled([
        this.getPartnerShowcasesData()
      ])
    },

    // START - Get Partner Showcases
    async getPartnerShowcasesData () {
      try {
        await this.getShowcasePartner({
          data: {
            store_id: this.partner ? this.partner.id : ''
          }
        })
      } catch (error) {
        console.log(error)
      }
    }
    // END - Get Partner Showcases
  },

  head () {
    return {
      title: this.partnerSEOTitle,
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: this.partnerSEOTitle
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.partnerSEOTitle
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.partnerSEODescription
        },
        {
          hid: 'description',
          name: 'description',
          content: this.partnerSEODescription
        },
        {
          hid: 'og:image',
          name: 'og:image',
          content: this.partnerSEOLogoImage
        }
      ]
    }
  }
}
