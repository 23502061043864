import { render, staticRenderFns } from "./ModalShareProduct.vue?vue&type=template&id=2bba388a&"
import script from "./ModalShareProduct.vue?vue&type=script&lang=js&"
export * from "./ModalShareProduct.vue?vue&type=script&lang=js&"
import style0 from "./ModalShareProduct.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductPrice: require('/usr/src/app/components/base/product/ProductPrice.vue').default,ButtonShareSocialMedia: require('/usr/src/app/components/base/ButtonShareSocialMedia.vue').default,ModalBase: require('/usr/src/app/components/base/ModalBase.vue').default})
