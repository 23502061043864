var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal",class:{
    fade: _vm.animation === _vm.modalAnimations.fade
  },attrs:{"id":_vm.modalId,"tabindex":"-1","aria-labelledby":_vm.ariaLabelledby || _vm.modalId,"aria-hidden":"true"}},[_c('div',{ref:_vm.modalDialogRefName,staticClass:"modal-dialog",class:{
      'modal-dialog-centered': _vm.verticalCentered,
      'modal-dialog-responsive': _vm.isModalDialogResponsive
    },style:({ width: _vm.modalDialogWidth })},[_c('div',{staticClass:"modal-content",style:({'max-height': !_vm.maxHeightNone && _vm.modalContentMaxHeight})},[_c('div',{class:'modal-header ' + (_vm.customClassModalHeader ? _vm.customClassModalHeader : '')},[(_vm.hasHeaderSlot)?[_vm._t("header")]:_vm._e(),_vm._v(" "),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasCloseButton),expression:"hasCloseButton"}],ref:"buttonClose",staticClass:"btn btn-close--modal close",attrs:{"type":"button","data-bs-dismiss":"modal","aria-label":"Close"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])],2),_vm._v(" "),_vm._t("content-body"),_vm._v(" "),(_vm.hasBodySlot)?_c('div',{class:'modal-body thin-scrollbar thin-scrollbar-vertical ' +
            (_vm.hasFooterSlot && !_vm.customClassModalBody
              ? 'paninti-pb-spacing-l '
              : '') +
            (_vm.customClassModalBody ? _vm.customClassModalBody : '')},[_vm._t("body")],2):_vm._e(),_vm._v(" "),(_vm.hasFooterSlot)?_c('div',{class:'modal-footer ' +
            (_vm.customClassModalFooter ? _vm.customClassModalFooter : '')},[_vm._t("footer")],2):_vm._e()],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }