//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
  async asyncData ({ store }) {
    let banner = []
    try {
      const resp = await store.dispatch('Contents/getBannerSale')
      if (resp.status === 200) {
        banner = resp.data.data.contents[0].contentable.banners || []
      }
      store.commit('Brand/SET_LOADING', false)
      store.commit('Brand/SET_BRANDS', [])
      await store.dispatch('Brand/getListBrands')
    } catch (error) {
      console.log(error)
    }
    return {
      banner,
      brand: null
    }
  },
  computed: {
    ...mapState('Brand', ['brands', 'onLoaded'])
  },
  watch: {
    brands: {
      handler () {
        const length = this.brands && this.brands.length
        const index = Math.floor(Math.random() * length)
        if (this.brands && this.brands.length) {
          this.brand = this.brands[index]
        }
      },
      deep: true
    }
  },
  head: () => {
    return {
      title: 'Penawaran Harga Terbaik untuk Produk Terpilih | Paninti Store'
    }
  }
}
