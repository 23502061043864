var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"showcase-section-partner-profile-homepage"},[_c('el-skeleton',{attrs:{"loading":_vm.partnerShowCases.loading,"animated":""},scopedSlots:_vm._u([{key:"template",fn:function(){return [_c('div',{staticClass:"showcases-wrapper thin-scrollbar"},[_c('el-skeleton-item',{staticClass:"w-100",style:(_vm.normalDesktop ? 'height: 220px;' : 'height: 130px'),attrs:{"variant":"image"}})],1)]},proxy:true}])},[_vm._v(" "),(_vm.normalDesktop)?_c('ClientOnly',[_c('carousel',{staticClass:"paninti-carousel partner-profile-homepage-vouchers-carousel",attrs:{"pagination-enabled":false,"navigation-enabled":true,"autoplay":false,"loop":false,"per-page":4,"navigation-prev-label":_vm.sliderArrow.left,"navigation-next-label":_vm.sliderArrow.right,"per-page-custom":[
          [0, 1],
          [576, 2],
          [768, 4],
          [1280, 5]
        ]}},_vm._l((_vm.partnerShowCases.data),function(showcase){return _c('slide',{key:'showcase-partner-profile-homepage' + showcase.id,staticClass:"partner-profile-homepage-showcases-carousel-slide"},[_c('div',{staticClass:"showcase-item",on:{"click":function($event){return _vm.handleShowCaseItemOnClick(showcase)}}},[_c('img',{staticClass:"showcase-item-img",attrs:{"src":_vm.getDefaultImageFromFiles(
                  showcase.files,
                  _vm.showcaseFileNames.showcaseImage,
                  _vm.fileVariants.original,
                  true
                ),"alt":'Showcase ' + showcase.name},on:{"error":_vm.getDefaultShowcaseImageOnError}})])])}),1)],1):_c('div',{staticClass:"showcases-wrapper thin-scrollbar"},_vm._l((_vm.partnerShowCases.data),function(showcase){return _c('div',{key:'showcase-partner-profile-homepage' + showcase.id,staticClass:"showcase-item",on:{"click":function($event){return _vm.handleShowCaseItemOnClick(showcase)}}},[_c('img',{staticClass:"showcase-item-img",attrs:{"src":_vm.getDefaultImageFromFiles(
              showcase.files,
              _vm.showcaseFileNames.showcaseImage,
              _vm.fileVariants.original,
              true
            ),"alt":'Showcase ' + showcase.name},on:{"error":_vm.getDefaultShowcaseImageOnError}})])}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }