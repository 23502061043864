import { render, staticRenderFns } from "./error.vue?vue&type=template&id=5d3a72aa&scoped=true&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"
import style0 from "./error.vue?vue&type=style&index=0&id=5d3a72aa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d3a72aa",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BannerBottomSection: require('/usr/src/app/components/home/BannerBottomSection.vue').default,ApplicationDescriptionSection: require('/usr/src/app/components/home/ApplicationDescriptionSection.vue').default})
