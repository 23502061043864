//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

import windowResizeMixin from '@/mixins/window-resize-mixin'

import { storeTypes } from '@/utils/helpers/store-helper'
import { defaultNotPaginationAttributes } from '@/utils/helpers/pagination-helper'

export default {
  mixins: [
    windowResizeMixin
  ],

  props: {
    vouchers: {
      type: Object,
      default: () => ({
        ...defaultNotPaginationAttributes()
      })
    }
  },

  data () {
    return {
      sliderArrow: {
        left: '<img src="/img/icon/chevron-left.svg" alt=""/>',
        right: '<img src="/img/icon/chevron-right.svg" alt=""/>'
      },

      modalDetailVoucher: {
        type: '',
        reduction_type: '',
        date_start: '',
        date_end: ''
      }
    }
  },

  computed: {
    ...mapState('Partner/PartnerProfile', [
      'partner'
    ]),

    storeTypes () {
      return storeTypes
    }
  },

  mounted () {
    this.$emit('mounted')
  },

  methods: {
    handleCardVoucherOnClick (voucher) {
      this.modalDetailVoucher = voucher
      this.$refs.btnOpenModalDetailVoucher.click()
    }
  }
}
