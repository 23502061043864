//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defaultStoreLocationLabel, storeFileNames, storeTypes } from '@/utils/helpers/store-helper'
import {
  fileVariants,
  defaultImages,
  getDefaultImageFromFiles,
  getDefaultStoreLogoOnError
} from '@/utils/helpers/file-helper'
import { limitNumber } from '@/utils/helpers/string-helper'
import { socialMedias } from '@/utils/helpers/social-media-helper'
import { defaultNotPaginationAttributes } from '@/utils/helpers/pagination-helper'

export default {
  props: {
    partner: {
      type: Object,
      required: true
    },

    partnerRelatedData: {
      type: Object,
      default: () => ({
        ...defaultNotPaginationAttributes()
      })
    }
  },

  computed: {
    socialMedias () {
      return socialMedias
    },

    fileVariants () {
      return fileVariants
    },

    storeFileNames () {
      return storeFileNames
    },

    storeTypes () {
      return storeTypes
    },

    defaultPartnerLogo () {
      return this.partner && this.partner.type === storeTypes.brandPartner.value
        ? defaultImages.defaultBrandLogo
        : defaultImages.defaultStoreLogo
    },

    partnerLocationLabel () {
      if (!this.partner || (this.partner && this.partner.branches.length < 0)) {
        return defaultStoreLocationLabel
      }

      let selectedBranch = this.partner.branches.find(branch => branch.main === true)
      if (!selectedBranch) {
        selectedBranch = this.partner.branches[0]
      }
      return selectedBranch.main_address && selectedBranch.main_address.village
        ? selectedBranch.main_address.village.city
        : defaultStoreLocationLabel
    },

    partnerSocialMedias () {
      const validSocialMedias = new Set([
        socialMedias.whatsapp.code,
        socialMedias.instagram.code,
        socialMedias.tiktok.code,
        socialMedias.twitter.code,
        socialMedias.facebook.code,
        socialMedias.youtube.code
      ]);

      return this.partner.social_medias.filter(partnerSocialMedia =>
        validSocialMedias.has(partnerSocialMedia.name)
      );
    }
  },

  methods: {
    getDefaultStoreLogoOnError,
    getDefaultImageFromFiles,
    limitNumber,

    iconSocialMediaOnClick (socialMedia) {
      switch (socialMedia.name) {
        case socialMedias.whatsapp.code:
          window.open(`https://wa.me/${socialMedia.value.replace(/[^0-9]/g, '')}`, '_blank')
          break
        default:
          window.open(socialMedia.value, '_blank')
          break
      }
    }
  }
}
