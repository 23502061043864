//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    branch: {
      type: Object,
      default: () => ({
        id: '40d9e771-e8d5-4140-88a1-0a90e48ba25c',
        name: 'Willow Baby Shop Citraland',
        slug: 'willow-baby-shop-p2RrN8Gxrt-willow-baby-shop-citraland-PhrQE6m0LH',
        address: 'Kota Surabaya, Jawa Timur 60211',
        distance: 567
      })
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isChecked () {
      return this.modelValue === this.branch.id
    }
  },
  methods: {
    handleCheck () {
      this.$emit('update:modelValue', this.$refs.cartGroupItem.value)
      this.$emit('change', this.branch)
    }
  }
}
