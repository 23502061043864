//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'
import AbortController from 'abort-controller'
import { mapActions } from 'vuex'

import pageMixin from '@/mixins/page-mixin'

import {
  assignResponseToDataModelData,
  assignResponseToDataModelDataNotPaginated,
  defaultNotPaginationAttributes,
  defaultPaginationAttributes, resetPaginationData
} from '@/utils/helpers/pagination-helper'
import { defaultDebounceTimes } from '@/utils/helpers/form-helper'
import { filterNonNullValues } from '@/utils/helpers/object-helper'

export default {
  mixins: [
    pageMixin
  ],

  data () {
    const sortFilterOptions = {
      bestSeller: {
        label: 'Terlaris',
        value: 'best_seller'
      },
      mostExpensive: {
        label: 'Termahal',
        value: 'most_expensive'
      },
      cheapest: {
        label: 'Termurah',
        value: 'cheapest'
      }
    }

    return {
      pageDataLoading: true,
      eventSaleSession: null,
      brand: null,

      // START - Side Filter
      sideFilterModel: {
        activeCategoryTreeItems: [],
        activeCollapseNames: [
          'filter-category',
          'filter-location',
          'filter-price',
          'filter-rating'
        ],
        location: [],
        price: [
          0, 10000000
        ],
        rating: []
      },
      sideFilterData: {
        ...defaultNotPaginationAttributes()
      },
      sideFilterShowed: {
        category: true,
        brand: false,
        location: true,
        price: true,
        rating: true
      },
      // ENG - Side Filter

      // START - Event Sale Products
      sortFilterOptions,
      eventSaleProducts: {
        ...defaultPaginationAttributes(),
        perPage: 12,

        filter: {
          search: '',
          searchTemp: '',
          sort: sortFilterOptions.bestSeller.value
        },

        controller: null
      }
      // END - Event Sale Products
    }
  },

  computed: {
    paramId () {
      return this.$route.params.id
    },

    paramBrandId () {
      return this.$route.params.brandId
    },

    breadCrumb () {
      return [
        {
          path: '/'
        },
        {
          name: this.eventSale ? this.eventSale.name : 'Event Sale',
          path: `/sale/${this.eventSale ? this.eventSale.slug : 'event-sale'}`
        },
        {
          name: this.brand ? this.brand.name : 'Brand'
        }
      ]
    },

    eventSale () {
      return this.eventSaleSession ? this.eventSaleSession.event_sale : null
    }
  },

  watch: {
    '$route.query': {
      handler (value, oldVal) {
        if (value) {
          this.populateRouteQueryToEventSaleProducts(value)
        }

        if (!_.isEqual(value, oldVal)) {
          this.scrollToTopContentBody()

          resetPaginationData(this.eventSaleProducts)
          this.getEventSaleProductsData({
            resetData: true
          })
          this.$refs.eventSaleProducts.resetInfiniteLoading()
        }
      },
      deep: true
    }
  },

  async mounted () {
    this.populateRouteQueryToEventSaleProducts(
      this.$route.query
    )

    await Promise.allSettled([
      this.getPageData(),
      this.getProductsSideFilterData(),
      this.getEventSaleProductsData()
    ])

    this.$refs.eventSaleProducts.resetInfiniteLoading()
  },

  methods: {
    ...mapActions('Contents', ['getOngoingEventSale']),
    ...mapActions('Brand', ['showBrand']),
    ...mapActions('Product/filter', ['getProductsSideFilter']),
    ...mapActions('Product', ['getProducts']),

    // START - Page Methods
    populateRouteQueryToEventSaleProducts (queries = {}) {
      this.eventSaleProducts.filter.search = queries.saleProductsSearch || ''
      this.eventSaleProducts.filter.searchTemp = queries.saleProductsSearch || ''

      this.eventSaleProducts.filter.sort = queries.saleProductsSort || this.sortFilterOptions.bestSeller.value

      this.sideFilterModel.activeCategoryTreeItems = queries.saleProductsCategories
        ? queries.saleProductsCategories.split(',')
        : []

      this.sideFilterModel.location = queries.saleProductsLocations
        ? queries.saleProductsLocations.split(',')
        : []

      this.sideFilterModel.price = queries.saleProductsPriceRange
        ? queries.saleProductsPriceRange.split(',')
        : [0, 10000000]

      this.sideFilterModel.rating = queries.saleProductsRating
        ? queries.saleProductsRating.split(',').map(rating => Number(rating)).filter(rating => rating)
        : []
    },

    async getPageData () {
      try {
        this.pageDataLoading = true

        await Promise.allSettled([
          this.getDetailEventSale(),
          this.getDetailBrand()
        ])
      } catch (error) {
        console.log(error)
      } finally {
        this.pageDataLoading = false
      }
    },

    async getDetailEventSale () {
      try {
        const response = await this.getOngoingEventSale({
          data: {
            where_has: [
              {
                relation: 'event_sale',
                where: {
                  type: 'event_sale',
                  slug: this.paramId
                }
              }
            ]
          }
        })

        this.eventSaleSession = response.data.data.event_sale_sessions.length > 0
          ? response.data.data.event_sale_sessions[0]
          : null

        if (!this.eventSaleSession) {
          this.setPageToErrorPage()
        }
      } catch (error) {
        console.log(error)

        this.setPageToErrorPage()
      }
    },

    async getDetailBrand () {
      try {
        const response = await this.showBrand(this.paramBrandId)

        this.brand = response.data.data.brand
      } catch (error) {
        console.log(error)

        this.setPageToErrorPage()
      }
    },
    // END - Page Methods

    // START - Event Sale Detail Brand Products Section Header
    handleEventSaleProductsFilterSearchOnInput: _.debounce(function (searchValue) {
      this.pushFilterIntoRouteQuery({
        saleProductsSearch: searchValue || null
      })
    }, defaultDebounceTimes.search),
    // END - Event Sale Detail Brand Products Section Header

    // START  - Event Sale Detail Brand Products Section Content
    // START - Side Filter
    async getProductsSideFilterData () {
      try {
        this.sideFilterData.loading = true

        const response = await this.getProductsSideFilter({
          params: {
            event_sale: this.paramId
          }
        })

        assignResponseToDataModelDataNotPaginated(this.sideFilterData, response.data.data)
      } catch (error) {
        console.log(error)
      } finally {
        this.sideFilterData.loading = false
      }
    },

    handleProductsSideFilterCategoryTreeNodeOnClick (data) {
      this.$refs.eventSaleProducts.completeInfiniteLoading()

      const saleProductsCategories = this.$route.query.saleProductsCategories
      if (!saleProductsCategories || !saleProductsCategories.includes(data.slug)) {
        this.pushFilterIntoRouteQuery({
          saleProductsCategories: data.slug || null
        })
        return
      }

      this.pushFilterIntoRouteQuery({
        saleProductsCategories: null
      })
    },

    handleProductsSideFilterLocationCheckboxGroupOnChange (data) {
      this.$refs.eventSaleProducts.completeInfiniteLoading()
      this.pushFilterIntoRouteQuery({
        saleProductsLocations: data.length > 0 ? data.join(',') : null
      })
    },

    handleProductsSideFilterPriceSliderOnChange (data) {
      this.$refs.eventSaleProducts.completeInfiniteLoading()
      this.pushFilterIntoRouteQuery({
        saleProductsPriceRange: data.length > 0 ? data.join(',') : null
      })
    },

    handleProductsSideFilterRatingCheckboxGroupOnChange (data) {
      this.$refs.eventSaleProducts.completeInfiniteLoading()
      this.pushFilterIntoRouteQuery({
        saleProductsRating: data.length > 0 ? data.join(',') : null
      })
    },

    handleBtnResetProductsSideFilterOnClick () {
      this.$refs.eventSaleProducts.completeInfiniteLoading()

      this.$router.push({
        query: filterNonNullValues({
          ...this.$route.query,
          saleProductsCategories: null,
          saleProductsLocations: null,
          saleProductsPriceRange: null,
          saleProductsRating: null
        })
      })
    },
    // END - Side Filter

    // START - Event Sale Products
    // START - Products Filter Mobile
    handleProductsFilterMobileBtnOpenOnClick () {
      this.populateRouteQueryToEventSaleProducts(this.$route.query)

      if (this.$refs.productsFilterMobile) {
        this.$refs.productsFilterMobile.openProductsFilterMobileDrawer()
      }
    },

    handleProductsFilterMobileBtnResetOnClick () {
      this.$refs.eventSaleProducts.completeInfiniteLoading()

      this.$router.push({
        query: filterNonNullValues({
          ...this.$route.query,
          saleProductsPriceRange: null,
          saleProductsRating: null
        })
      })

      if (this.$refs.productsFilterMobile) {
        this.$refs.productsFilterMobile.closeProductsFilterMobileDrawer()
      }
    },

    handleProductsFilterMobileBtnApplyOnClick () {
      this.$refs.eventSaleProducts.completeInfiniteLoading()

      this.$router.push({
        query: filterNonNullValues({
          ...this.$route.query,
          saleProductsCategories: this.sideFilterModel.activeCategoryTreeItems.length > 0
            ? this.sideFilterModel.activeCategoryTreeItems.join(',')
            : null,
          saleProductsLocations: this.sideFilterModel.location.length > 0
            ? this.sideFilterModel.location.join(',')
            : null,
          saleProductsPriceRange: this.sideFilterModel.price.length > 0
            ? this.sideFilterModel.price.join(',')
            : null,
          saleProductsRating: this.sideFilterModel.rating.length > 0
            ? this.sideFilterModel.rating.join(',')
            : null
        })
      })

      if (this.$refs.productsFilterMobile) {
        this.$refs.productsFilterMobile.closeProductsFilterMobileDrawer()
      }
    },
    // END - Products Filter Mobile

    // START - Event Sale Products Header
    handleSelectFilterSortProductsOnChange (value) {
      this.$refs.eventSaleProducts.completeInfiniteLoading()
      this.pushFilterIntoRouteQuery({
        saleProductsSort: value || null
      })
    },
    // END - Event Sale Products Header

    async getEventSaleProductsData ({ resetData } = { resetData: false }) {
      try {
        if (this.eventSaleProducts.controller) {
          this.eventSaleProducts.controller.abort()
        }
        this.eventSaleProducts.controller = new AbortController()

        this.eventSaleProducts.loading = true

        // Payload
        const smallestRating = this.sideFilterModel.rating.length > 0
          ? Math.min(...this.sideFilterModel.rating)
          : undefined

        const getPayloadData = {
          paginated: this.eventSaleProducts.paginated,
          page: this.eventSaleProducts.page + 1,
          per_page: this.eventSaleProducts.perPage,

          ...filterNonNullValues({
            search: this.eventSaleProducts.filter.search || null,
            category_slug: this.sideFilterModel.activeCategoryTreeItems.length > 0
              ? this.sideFilterModel.activeCategoryTreeItems
              : null,
            city: this.sideFilterModel.location.length > 0
              ? this.sideFilterModel.location
              : null,
            where_rating: smallestRating
              ? Array.from({ length: 6 - smallestRating }, (_, i) => (smallestRating + i).toString())
              : null
          }),

          event_sale: this.paramId,
          or_where_has: [
            {
              relation: 'brand',
              where_in: [
                [
                  'slug',
                  [
                    this.paramBrandId
                  ]
                ]
              ]
            }
          ]
        }

        // Payload Sort
        switch (this.eventSaleProducts.filter.sort) {
          case this.sortFilterOptions.bestSeller.value:
            Object.assign(getPayloadData, {
              order_by: {
                total_sold_last_month: 'desc'
              }
            })
            break
          case this.sortFilterOptions.mostExpensive.value:
            Object.assign(getPayloadData, {
              order_by: {
                price: 'desc'
              }
            })
            break
          case this.sortFilterOptions.cheapest.value:
            Object.assign(getPayloadData, {
              order_by: {
                price: 'asc'
              }
            })
            break
        }

        // Payload Price Range
        if (this.sideFilterModel.price.length === 2 && this.sideFilterData.data.price) {
          if (
            this.sideFilterModel.price[0] !== this.sideFilterData.data.price.from ||
            this.sideFilterModel.price[1] !== this.sideFilterData.data.price.until
          ) {
            Object.assign(getPayloadData, {
              where_greater_than: {
                price: this.sideFilterModel.price[0]
              },
              where_lower_than: {
                price: this.sideFilterModel.price[1]
              }
            })
          }
        }

        const response = await this.getProducts({
          data: getPayloadData,
          signal: this.eventSaleProducts.controller.signal
        })

        assignResponseToDataModelData(this.eventSaleProducts, response.data.data.products, resetData)
      } catch (error) {
        console.log(error)
        this.$refs.eventSaleProducts.completeInfiniteLoading()
      } finally {
        this.eventSaleProducts.loading = false
      }
    },

    async handleEventSaleProductsInfiniteLoading ($state) {
      if (this.eventSaleProducts.page >= this.eventSaleProducts.totalPage) {
        $state.complete()
        return
      }

      await this.getEventSaleProductsData()
      $state.loaded()
    },
    // END - Event Sale Products
    // END  - Event Sale Detail Brand Products Section Content

    // START - Others
    pushFilterIntoRouteQuery (query) {
      this.$router.push({
        query: filterNonNullValues({
          ...this.$route.query,
          ...query
        })
      })
    },

    scrollToTopContentBody () {
      const targetTop = this.$refs.eventSaleDetailBrandProductsSection.getBoundingClientRect().top
      const containerTop = document.body.getBoundingClientRect().top
      const scrollPosition = targetTop - containerTop - 100

      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth'
      })
    }
    // END - Others
  }
}
