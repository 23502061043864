//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
import { storeTypes } from '@/utils/helpers/store-helper'
import { mapState, mapActions } from 'vuex'
import VClamp from 'vue-clamp'
import ModalImageReview from './ModalImageReview.vue'
import ModalRebuyProduct from './ModalRebuyProduct.vue'

export default {
  components: {
    VClamp,
    ModalImageReview,
    ModalRebuyProduct
  },
  mixins: [panstoreMixin],
  data () {
    return {
      visible: false,
      indexImg: 0,
      images: [],
      dataProductsChecked: []
    }
  },
  computed: {
    ...mapState('Order', ['orderReviews']),
    storeTypes () {
      return storeTypes
    }
  },
  methods: {
    ...mapActions('Order', ['checkReorder']),
    showImage (index, images) {
      this.indexImg = index
      this.images = images
      this.$refs.buttonModalImageReview.click()
    },
    async handleCheck (products) {
      try {
        const resp = await this.checkReorder({
          data: {
            order_product_ids: products
          }
        })
        if (resp.status === 200) {
          this.dataProductsChecked = resp.data.data.order_products
          this.$refs.buttonModalRebuyProduct.click()
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
