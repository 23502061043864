//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AbortController from 'abort-controller'
import { mapActions, mapState } from 'vuex'

import {
  assignResponseToDataModelData,
  assignResponseToDataModelDataNotPaginated,
  defaultNotPaginationAttributes,
  defaultPaginationAttributes,
  resetPaginationData
} from '@/utils/helpers/pagination-helper'
import { getShowcaseIconByType, showcaseSlugs, showcaseTypes } from '@/utils/helpers/showcase-helper'
import { generateRandomString } from '@/utils/helpers/string-helper'
import { filterNonNullValues, isObjectNotEmptyOrNull } from '@/utils/helpers/object-helper'
import { eventSaleTypes } from '@/utils/helpers/event-sale-helper'

export default {
  data () {
    const partnerProductsSortFilterOptions = {
      bestSeller: {
        label: 'Terlaris',
        value: 'best_seller'
      },
      newest: {
        label: 'Terbaru',
        value: 'newest'
      },
      mostExpensive: {
        label: 'Termahal',
        value: 'most_expensive'
      },
      cheapest: {
        label: 'Termurah',
        value: 'cheapest'
      }
    }

    return {
      // START - Side Filter
      sideFilterData: {
        ...defaultNotPaginationAttributes()
      },
      sideFilterModel: {
        activeCategoryTreeItems: [],
        activeCollapseNames: [
          'filter-price',
          'filter-rating'
        ],
        location: [],
        price: [
          0, 10000000
        ],
        rating: []
      },
      sideFilterLabel: {
        price: 'Harga Produk'
      },
      sideFilterShowed: {
        category: false,
        location: false,
        price: true,
        rating: true
      },
      // END - Side Filter

      // START - Partner Products
      partnerProductsSortFilterOptions,

      activeSidebarFilterShowcaseItem: '',
      activeDrawerFilterShowcaseItem: '',
      ongoingSaleSessions: {
        ...defaultNotPaginationAttributes()
      },
      partnerProducts: {
        ...defaultPaginationAttributes(),
        perPage: 12,
        filter: {
          search: '',
          sort: partnerProductsSortFilterOptions.newest.value
        },
        controller: null
      }
      // END - Partner Products
    }
  },

  computed: {
    ...mapState('Partner/PartnerProfile', [
      'partner', 'sideFilterShowcases'
    ]),

    showcaseTypes () {
      return showcaseTypes
    },

    selectedSideFilterShowcaseItem () {
      return this.sideFilterShowcases.find(showcase => showcase.slug === this.activeSidebarFilterShowcaseItem) || null
    },

    isSideFilterShowcaseEventSaleProducts () {
      if (!this.selectedSideFilterShowcaseItem) {
        return false
      }

      return [showcaseTypes.eventSale, showcaseTypes.flashSale, showcaseTypes.clearanceSale, showcaseTypes.presale]
        .includes(this.selectedSideFilterShowcaseItem.type)
    }
  },

  watch: {
    '$route.query': {
      async handler (newValue, oldValue) {
        const isFilterShowcaseChange = newValue.partnerProductsShowcase !== oldValue.partnerProductsShowcase
        const isFilterSortChange = newValue.partnerProductsSort !== oldValue.partnerProductsSort
        const isFilterPriceRangeChange = newValue.partnerProductsPriceRange !== oldValue.partnerProductsPriceRange
        const isFilterRatingChange = newValue.partnerProductsRating !== oldValue.partnerProductsRating
        const isFilterSearchChange = newValue.partnerProductsSearch !== oldValue.partnerProductsSearch

        if (isFilterSearchChange) {
          this.partnerProducts.filter.search = newValue.partnerProductsSearch || ''
        }

        if (isFilterShowcaseChange) {
          this.activeSidebarFilterShowcaseItem = newValue.partnerProductsShowcase || showcaseSlugs.allProduct
        }

        if (
          isFilterShowcaseChange || isFilterSortChange || isFilterPriceRangeChange || isFilterRatingChange ||
          isFilterSearchChange
        ) {
          this.populateRouteQueryToPartnerProducts(newValue)

          this.resetPartnerProducts()
          await this.getShowcasePartnerProducts(true)
          this.$refs.partnerProfileProducts.resetInfiniteLoading()
        }
      },
      deep: true
    }
  },

  mounted () {
    this.getAndSetPageData()
  },

  methods: {
    getShowcaseIconByType,
    generateRandomString,
    defaultPaginationAttributes,

    ...mapActions('Partner/PartnerProfile', [
      'getProductsSideFilter',
      'getProducts',
      'getOngoingSaleSessions',
      'getOnSaleProducts',
      'getEventSaleProducts'
    ]),

    // START - Page Methods
    async getAndSetPageData () {
      await this.getProductsSideFilterData()

      this.populateRouteQueryToPartnerProducts(this.$route.query)

      if (this.$refs.partnerProfileProducts) {
        this.$refs.partnerProfileProducts.completeInfiniteLoading()
      }

      setTimeout(async () => {
        if (this.isSideFilterShowcaseEventSaleProducts) {
          await this.getShowcaseEventSaleOngoingSessions(true)
        }

        await this.getShowcasePartnerProducts()
        this.$refs.partnerProfileProducts.resetInfiniteLoading()
      }, 300)
    },

    async getProductsSideFilterData () {
      try {
        this.sideFilterData.loading = true

        const response = await this.getProductsSideFilter({
          params: {}
        })

        assignResponseToDataModelDataNotPaginated(this.sideFilterData, response.data.data)
      } catch (error) {
        console.log(error)
      } finally {
        this.sideFilterData.loading = false
      }
    },
    // END - Page Methods

    // START - Side Filter
    async handleShowCaseFilterItemOnClick (showcase) {
      if (showcase.slug !== this.activeSidebarFilterShowcaseItem) {
        this.$refs.partnerProfileProducts.completeInfiniteLoading()
        this.resetPartnerProducts()

        this.activeSidebarFilterShowcaseItem = showcase.slug

        if (this.isSideFilterShowcaseEventSaleProducts) {
          await this.getShowcaseEventSaleOngoingSessions(true)
        }

        this.pushFilterIntoRouteQuery({
          partnerProductsShowcase: showcase.slug
        })
      }
    },

    handleProductsSideFilterPriceSliderOnChange (data) {
      this.$refs.partnerProfileProducts.completeInfiniteLoading()
      this.pushFilterIntoRouteQuery({
        partnerProductsPriceRange: data.length > 0 ? data.join(',') : null
      })
    },

    handleProductsSideFilterRatingCheckboxGroupOnChange (data) {
      this.$refs.partnerProfileProducts.completeInfiniteLoading()
      this.pushFilterIntoRouteQuery({
        partnerProductsRating: data.length > 0 ? data.join(',') : null
      })
    },

    handleBtnResetProductsSideFilterOnClick () {
      this.$router.push({
        query: filterNonNullValues({
          ...this.$route.query,
          partnerProductsShowcase: showcaseSlugs.allProduct,
          partnerProductsPriceRange: null,
          partnerProductsRating: null
        })
      })
    },
    // END - Side Filter

    // START - Partner Products
    handleDrawerShowCaseFilterItemOnClick (showcase) {
      this.activeDrawerFilterShowcaseItem = showcase.slug
    },

    handleProductsFilterMobileBtnOpenOnClick () {
      this.populateRouteQueryToPartnerProducts(this.$route.query)
      this.activeDrawerFilterShowcaseItem = this.activeSidebarFilterShowcaseItem

      if (this.$refs.productsFilterMobile) {
        this.$refs.productsFilterMobile.openProductsFilterMobileDrawer()
      }
    },

    handleProductsFilterMobileBtnResetOnClick () {
      this.$router.push({
        query: filterNonNullValues({
          ...this.$route.query,
          partnerProductsShowcase: showcaseSlugs.allProduct,
          partnerProductsPriceRange: null,
          partnerProductsRating: null
        })
      })

      if (this.$refs.productsFilterMobile) {
        this.$refs.productsFilterMobile.closeProductsFilterMobileDrawer()
      }
    },

    handleProductsFilterMobileBtnApplyOnClick () {
      this.$router.push({
        query: filterNonNullValues({
          ...this.$route.query,
          partnerProductsShowcase: this.activeDrawerFilterShowcaseItem,
          partnerProductsCategories: this.sideFilterModel.activeCategoryTreeItems.length > 0
            ? this.sideFilterModel.activeCategoryTreeItems.join(',')
            : null,
          partnerProductsLocations: this.sideFilterModel.location.length > 0
            ? this.sideFilterModel.location.join(',')
            : null,
          partnerProductsPriceRange: this.sideFilterModel.price.length > 0
            ? this.sideFilterModel.price.join(',')
            : null,
          partnerProductsRating: this.sideFilterModel.rating.length > 0
            ? this.sideFilterModel.rating.join(',')
            : null
        })
      })

      if (this.$refs.productsFilterMobile) {
        this.$refs.productsFilterMobile.closeProductsFilterMobileDrawer()
      }
    },

    async getShowcasePartnerProducts (resetData = false) {
      try {
        if (this.partnerProducts.controller) {
          this.partnerProducts.controller.abort()
        }
        this.partnerProducts.controller = new AbortController()

        switch (this.selectedSideFilterShowcaseItem.type) {
          case showcaseTypes.product:
          case showcaseTypes.sale:
            await this.getShowcasePartnerGeneralProducts(resetData)
            break
          case showcaseTypes.eventSale:
          case showcaseTypes.flashSale:
          case showcaseTypes.clearanceSale:
          case showcaseTypes.presale:
            await this.getShowcasePartnerEventSaleProducts(resetData)
            break
          default:
            this.partnerProducts.loading = false
            break
        }
      } catch (error) {
        this.partnerProducts.loading = false
      }
    },

    async getShowcasePartnerGeneralProducts (resetData = false) {
      try {
        this.partnerProducts.loading = true

        const reqPayloadData = filterNonNullValues({
          paginated: this.partnerProducts.paginated,
          per_page: this.partnerProducts.perPage,
          page: this.partnerProducts.page + 1,
          search: this.partnerProducts.filter.search || '',
          where: {
            'products.store_id': this.partner ? this.partner.id : ''
          }
        })

        // Showcase Filter
        if (
          this.selectedSideFilterShowcaseItem &&
          this.selectedSideFilterShowcaseItem.type === showcaseTypes.product &&
          this.selectedSideFilterShowcaseItem.id
        ) {
          reqPayloadData.where_has = [
            {
              relation: 'showcases',
              where: {
                id: this.selectedSideFilterShowcaseItem.id
              }
            }
          ]
        }

        // Sort Filter
        switch (this.partnerProducts.filter.sort) {
          case this.partnerProductsSortFilterOptions.bestSeller.value:
            reqPayloadData.order_by = {
              'products.total_sold_last_month': 'desc'
            }
            break
          case this.partnerProductsSortFilterOptions.newest.value:
            reqPayloadData.order_by = {
              'products.created_at': 'desc'
            }
            break
          case this.partnerProductsSortFilterOptions.mostExpensive.value:
            reqPayloadData.order_by = {
              price: 'desc'
            }
            break
          case this.partnerProductsSortFilterOptions.cheapest.value:
            reqPayloadData.order_by = {
              price: 'asc'
            }
            break
        }

        // Price Filter
        if (this.sideFilterModel.price.length === 2 && isObjectNotEmptyOrNull(this.sideFilterData.data.price)) {
          if (
            this.sideFilterModel.price[0] !== this.sideFilterData.data.price.from ||
            this.sideFilterModel.price[1] !== this.sideFilterData.data.price.until
          ) {
            reqPayloadData.where_greater_than = {
              price: this.sideFilterModel.price[0]
            }
            reqPayloadData.where_lower_than = {
              price: this.sideFilterModel.price[1]
            }
          }
        }

        // Rating Filter
        if (this.sideFilterModel.rating.length > 0) {
          const smallestRating = Math.min(...this.sideFilterModel.rating) || null

          reqPayloadData.where_rating = smallestRating
            ? Array.from({ length: 6 - smallestRating }, (_, i) => (smallestRating + i).toString())
            : null
        }

        let response = null
        if (this.selectedSideFilterShowcaseItem.type === showcaseTypes.sale) {
          response = await this.getOnSaleProducts({
            data: reqPayloadData,
            signal: this.partnerProducts.controller.signal
          })
        } else {
          response = await this.getProducts({
            data: reqPayloadData,
            signal: this.partnerProducts.controller.signal
          })
        }

        if (response) {
          assignResponseToDataModelData(
            this.partnerProducts,
            response.data.data.products,
            resetData
          )
        }
      } catch (error) {
        console.log(error)
        this.$refs.partnerProfileProducts.completeInfiniteLoading()
      } finally {
        this.partnerProducts.loading = false
      }
    },

    async getShowcaseEventSaleOngoingSessions (resetData = false) {
      try {
        let selectedSaleType = ''
        switch (this.selectedSideFilterShowcaseItem.type) {
          case showcaseTypes.eventSale:
            selectedSaleType = eventSaleTypes.eventSale
            break
          case showcaseTypes.flashSale:
            selectedSaleType = eventSaleTypes.flashSale
            break
          case showcaseTypes.clearanceSale:
            selectedSaleType = eventSaleTypes.clearanceSale
            break
          case showcaseTypes.presale:
            selectedSaleType = eventSaleTypes.preSale
            break
        }

        const response = await this.getOngoingSaleSessions({
          data: {
            partner_id: this.partner ? [this.partner.id] : '',
            where_has: [
              {
                relation: 'event_sale',
                where: {
                  type: selectedSaleType
                }
              }
            ]
          }
        })

        assignResponseToDataModelDataNotPaginated(
          this.ongoingSaleSessions,
          response.data.data.event_sale_sessions,
          resetData
        )
      } catch (error) {
        console.log(error)
      }
    },

    async getShowcasePartnerEventSaleProducts (resetData = false) {
      try {
        this.partnerProducts.loading = true

        if (this.ongoingSaleSessions.data.length <= 0) {
          this.partnerProducts.page = 1
          return
        }

        const response = await this.getEventSaleProducts({
          data: {
            paginated: this.partnerProducts.paginated,
            per_page: this.partnerProducts.perPage,
            page: this.partnerProducts.page + 1,
            search: this.partnerProducts.filter.search || '',
            where_in: [
              ['event_sale_session_id', this.ongoingSaleSessions.data.map(session => session.id)]
            ],
            where_has: [
              {
                relation: 'product_detail.product',
                where: {
                  'products.store_id': this.partner ? this.partner.id : ''
                }
              }
            ]
          },
          signal: this.partnerProducts.controller.signal
        })

        assignResponseToDataModelData(
          this.partnerProducts,
          response.data.data.event_sale_products,
          resetData
        )
      } catch (error) {
        console.log(error)
        this.$refs.partnerProfileProducts.completeInfiniteLoading()
      } finally {
        this.partnerProducts.loading = false
      }
    },

    handlePartnerProductsSortFilterOnChange () {
      this.$refs.partnerProfileProducts.completeInfiniteLoading()
      this.pushFilterIntoRouteQuery({
        partnerProductsSort: this.partnerProducts.filter.sort
      })
    },

    async handlePartnerProductInfiniteLoading ($state) {
      if (this.partnerProducts.page >= this.partnerProducts.totalPage) {
        $state.complete()
        return
      }

      await this.getShowcasePartnerProducts()
      $state.loaded()
    },
    // END - Partner Products

    // START - Others
    pushFilterIntoRouteQuery (query) {
      this.$router.push({
        query: filterNonNullValues({
          ...this.$route.query,
          ...query
        })
      })
    },

    populateRouteQueryToPartnerProducts (queries = {}) {
      this.activeSidebarFilterShowcaseItem = queries.partnerProductsShowcase || showcaseSlugs.allProduct

      this.partnerProducts.filter.search = queries.partnerProductsSearch || ''
      this.partnerProducts.filter.sort = queries.partnerProductsSort || this.partnerProductsSortFilterOptions.newest.value

      this.sideFilterModel.price = queries.partnerProductsPriceRange
        ? queries.partnerProductsPriceRange.split(',')
        : [0, 10000000]

      this.sideFilterModel.rating = queries.partnerProductsRating
        ? queries.partnerProductsRating.split(',').map(rating => Number(rating)).filter(rating => rating)
        : []
    },

    resetPartnerProducts () {
      resetPaginationData(this.partnerProducts, {
        perPage: this.partnerProducts.perPage
      })
    }
    // END - Others
  }
}
