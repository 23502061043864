//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import windowResizeMixin from '@/mixins/window-resize-mixin'

import { generateRandomString } from '@/utils/helpers/string-helper'

export default {
  mixins: [
    windowResizeMixin
  ],

  props: {
    sortFilterOptions: {
      type: Object,
      default: () => ({})
    },

    eventSaleProducts: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      //
    }
  },

  methods: {
    generateRandomString,

    handleSelectFilterSortProductsSortOnChange (value) {
      this.$emit('select-filter-sort-products-on-change', value)
    },

    handleInfiniteLoading ($state) {
      this.$emit('infinite-loading', $state)
    },

    completeInfiniteLoading () {
      if (this.$refs.infiniteLoading) {
        this.$refs.infiniteLoading.stateChanger.complete()
      }
    },

    resetInfiniteLoading () {
      if (this.$refs.infiniteLoading) {
        this.$refs.infiniteLoading.stateChanger.reset()
      }
    }
  }
}
