//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations } from 'vuex'
import { checkoutMethods } from '@/utils/helpers/checkout-helper'
import orderModuleMixins from '@/mixins/order-module-mixins'
export default {
  mixins: [orderModuleMixins],
  data () {
    return {
      disabledTab: false
    }
  },
  computed: {
    ...mapState('Checkout', ['checkoutMethod']),
    checkoutMethodType () {
      return checkoutMethods
    },
    methodType: {
      get () {
        return this.checkoutMethod
      },
      set (value) {}
    }
  },
  watch: {
    onCheck () {
      this.checkLoadingCheckout()
    }
  },
  methods: {
    ...mapMutations('Checkout', ['setCheckoutMethod']),
    checkLoadingCheckout () {
      this.disabledTab = !this.checkoutAvailability.shipping_is_available || !this.checkoutAvailability.pickup_is_available

      if (!this.checkoutAvailability.shipping_is_available && !this.checkoutAvailability.shipping_is_available) {
        return
      }

      if (this.checkoutMethod === checkoutMethods.shipping.value && !this.checkoutAvailability.shipping_is_available) {
        this.setCheckoutMethod(checkoutMethods.pickup.value)
        localStorage.setItem('order_method', checkoutMethods.pickup.value)
      } else if (this.checkoutMethod === checkoutMethods.pickup.value && !this.checkoutAvailability.pickup_is_available) {
        this.setCheckoutMethod(checkoutMethods.shipping.value)
        localStorage.setItem('order_method', checkoutMethods.shipping.value)
      }
    },
    async changeMethod (value) {
      this.setCheckoutMethod(value.name)
      localStorage.setItem('order_method', value.name)

      await this.checkAvaibilityCheckout()
      await this.fetchBranchesForCheckout()
      if (value.name === checkoutMethods.shipping.value) {
        await this.fetchShippingCourier()
      }
    }
  }
}
