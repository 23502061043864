//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import imagesMixin from '@/mixins/images-mixin'
import panstoreMixin from '@/mixins'

export default {
  mixins: [imagesMixin, panstoreMixin],
  props: {
    images: {
      type: Array,
      required: true
    },

    index: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      indexImg: 0
    }
  },
  watch: {
    index: {
      handler () {
        this.indexImg = this.index
      },
      deep: true
    }
  }
}
