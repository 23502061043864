//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'

import { defaultNotPaginationAttributes } from '@/utils/helpers/pagination-helper'

export default {
  props: {
    filterModel: {
      type: Object,
      required: true
    },

    filterData: {
      type: Object,
      default: () => ({
        ...defaultNotPaginationAttributes(),
        data: {
          category: [],
          location: [],
          price: {
            from: 0,
            until: 5000000
          },
          rating: [],
          variant: {}
        }
      })
    },

    filterLabel: {
      type: Object,
      default: () => ({})
    },

    filterShowed: {
      type: Object,
      default: () => ({})
    },

    selectedLabel: {
      type: String,
      default: () => 'Filter'
    },

    navbarTopLabel: {
      type: String,
      default: () => 'Filter'
    }
  },

  data () {
    return {
      actionButtonLoading: false
    }
  },

  methods: {
    ...mapMutations('Product/ProductsFilterMobile', [
      'SET_PRODUCTS_FILTER_MOBILE_IS_OPEN'
    ]),

    openProductsFilterMobileDrawer () {
      this.SET_PRODUCTS_FILTER_MOBILE_IS_OPEN(true)
    },

    handleBtnOpenProductsFilterMobileOnClick () {
      this.$emit('btn-open-products-filter-mobile-on-click')
    },

    handleProductsSideFilterCategoryTreeNodeOnClick (data) {
      if (!this.filterModel.activeCategoryTreeItems.includes(data.slug)) {
        this.filterModel.activeCategoryTreeItems = [data.slug]
      } else {
        this.filterModel.activeCategoryTreeItems = []
      }
    },

    handleBtnResetFilterOnClick () {
      this.$emit('btn-filter-reset-on-click')
    },

    handleBtnApplyFilterOnClick () {
      this.$emit('btn-filter-apply-on-click')
    },

    closeProductsFilterMobileDrawer () {
      this.actionButtonLoading = true

      this.$nextTick(() => {
        setTimeout(() => {
          this.SET_PRODUCTS_FILTER_MOBILE_IS_OPEN(false)
          this.actionButtonLoading = false
        }, 500)
      })
    }
  }
}
