import { render, staticRenderFns } from "./_order_id.vue?vue&type=template&id=bf337fe4&scoped=true&"
import script from "./_order_id.vue?vue&type=script&lang=js&"
export * from "./_order_id.vue?vue&type=script&lang=js&"
import style0 from "./_order_id.vue?vue&type=style&index=0&id=bf337fe4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf337fe4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumb: require('/usr/src/app/components/home/base/Breadcrumb.vue').default,CheckoutCounterSuccess: require('/usr/src/app/components/home/payment-detail/CheckoutCounterSuccess.vue').default,PaymentHelpSection: require('/usr/src/app/components/home/payment-detail/PaymentHelpSection.vue').default,PaymentInfo: require('/usr/src/app/components/home/payment-detail/PaymentInfo.vue').default,BannerBottomSection: require('/usr/src/app/components/home/BannerBottomSection.vue').default})
