import { render, staticRenderFns } from "./index.vue?vue&type=template&id=f6dc4544&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=f6dc4544&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6dc4544",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumb: require('/usr/src/app/components/home/base/Breadcrumb.vue').default,BannerEventSaleSection: require('/usr/src/app/components/home/sale/BannerEventSaleSection.vue').default,BrandSaleSection: require('/usr/src/app/components/home/sale/BrandSaleSection.vue').default,EventSaleOnNotSearch: require('/usr/src/app/components/home/sale/EventSaleOnNotSearch.vue').default,EventSaleOnSearch: require('/usr/src/app/components/home/sale/EventSaleOnSearch.vue').default,BannerBottomSection: require('/usr/src/app/components/home/BannerBottomSection.vue').default})
