//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import panstoreMixin from '@/mixins/index'

import { getResponseErrorMessage } from '@/utils/helpers/response-helper'
import { getDefaultImageFromFiles, getDefaultStoreLogoOnError } from '@/utils/helpers/file-helper'

export default {
  mixins: [panstoreMixin],
  data () {
    return {}
  },
  computed: {
    ...mapState('Cart', ['cartEmpty']),
    ...mapGetters('Cart', ['getTotalProductCartEmpty']),
    emptyCarts () {
      return this.handleProcessedEmptyCarts()
    }
  },
  methods: {
    getDefaultImageFromFiles,
    getDefaultStoreLogoOnError,
    ...mapMutations('Cart', ['setCartItemId', 'setDeleteItem', 'setWishlistCartProduct', 'setSourceDelete']),
    ...mapActions('User', ['toggleWishlist']),
    ...mapActions('Product', ['getProductWishlist']),
    ...mapActions('Cart', ['getEmptyCarts']),
    handleProcessedEmptyCarts () {
        const resultProcessed = []

        if (!this.cartEmpty.data.length) {
          return
        }

        const localCarts = JSON.parse(JSON.stringify(this.cartEmpty))

        localCarts.data.forEach((cart) => {
          if (this.checkObject(cart) && this.checkObject(cart.cart_group) && this.checkObjectKey(cart.cart_group, 'is_fulfilled')) {
            const cartObj = {
              isFulfilled: cart.cart_group.is_fulfilled,
              cart_group_id: cart.cart_group.is_fulfilled ? cart.cart_group.connected_id : cart.cart_group.id,
              stores: [],
              checkAll: false
            }

            // cart group is fulfilled
            if (cart.cart_group.is_fulfilled) {
              // compare cart result processed fulfilled
              const resultProcessedFulfilledIndex = resultProcessed.findIndex((exCart) => {
                return exCart.isFulfilled === true
              })

              // have no conneccted
              if (resultProcessedFulfilledIndex === -1) {
                cartObj.stores = [{
                  cart_group_id: cart.cart_group.id,
                  ...cart.cart_group.store,
                  cart_items: [
                    {
                      ...cart,
                      check: false
                    }
                  ]
                }]

                resultProcessed.push(cartObj)
              } else {
                const existResultProcessed = resultProcessed[resultProcessedFulfilledIndex]

                // check existing store
                const storeExistIdx = existResultProcessed.stores.findIndex((exStore) => {
                    return exStore.id === cart.cart_group.store.id
                })

                // handle store cart exist
                if (storeExistIdx === -1) {
                  resultProcessed[resultProcessedFulfilledIndex].stores.push({
                    cart_group_id: cart.cart_group.id,
                    ...cart.cart_group.store,
                    cart_items: [
                      {
                        ...cart,
                        check: false
                      }
                    ]
                  })
                } else {
                  resultProcessed[resultProcessedFulfilledIndex].stores[storeExistIdx].cart_items.push({
                    ...cart,
                    check: false
                  })
                }
              }
            } else {
              // compare cart result processed non fulfilled
              const resultProcessedNonFulfilledIndex = resultProcessed.findIndex((exCart) => {
                return exCart.isFulfilled === false && exCart.stores.some((exStore) => {
                  return exStore.id === cart.cart_group.store.id
                })
              })

              if (resultProcessedNonFulfilledIndex > -1) {
                const existResultProcessed = resultProcessed[resultProcessedNonFulfilledIndex]

                existResultProcessed.stores[0].cart_items.push({
                  ...cart,
                  check: false
                })

                // update existing store cart item
                resultProcessed.splice(resultProcessedNonFulfilledIndex, 1, existResultProcessed)
              } else {
                cartObj.stores = [{
                  cart_group_id: cart.cart_group.id,
                  ...cart.cart_group.store,
                  cart_items: [
                    {
                      ...cart,
                      check: false
                    }
                  ]
                }]

                resultProcessed.push(cartObj)
              }
            }
          }
        })

        return resultProcessed
    },
    async handleToggleWishlist (cartId, id, isWishlisted) {
      if (this.$auth.loggedIn) {
        try {
          const resp = await this.toggleWishlist({
            data: {
              product_id: [id]
            }
          })
          if (resp.status === 200) {
            await this.getProductWishlist({
              data: { session_id: this.$cookies.get('session_id') }
            })
            this.setWishlistCartProduct({
              ids: [id],
              condition: isWishlisted,
              source: 'cartEmpty'
            })
            this.$message({
              message: `Produk berhasil ${
                  isWishlisted ? 'dihapus dari ' : 'ditambahkan ke'
                } Wishlist.`,
              type: 'success',
              showClose: true
            })
          }
        } catch (error) {
          const msg = getResponseErrorMessage(error)
          this.$message({
            showClose: true,
            message: msg,
            type: 'warning'
          })
        }
      } else {
        this.$alert('Silahkan login terlebih dahulu!')
      }
    },
    deleteModal (item, id) {
      this.setCartItemId(id)
      this.setDeleteItem(item)
      this.setSourceDelete('unavailable')
    },
    async handleInfiniteLoading ($state) {
      await this.fetchUnavailableCarts($state)
    },
    async fetchUnavailableCarts ($stateInfinite = null, paginated = true) {
      if (!this.cartEmpty.pagination.nextPage && paginated) {
        $stateInfinite.complete()
        return
      }
      try {
        const unavailablePayload = {
          params: {
            paginated,
            page: this.cartEmpty.pagination.nextPage || 1,
            per_page: 10,
            session_id: this.$cookies.get('session_id')
          }
        }

        if ($stateInfinite) {
          unavailablePayload.params.onLoadMore = !!$stateInfinite
        }

        const resp = await this.getEmptyCarts(unavailablePayload)
        if (paginated ? resp.data.data.carts.data.length : resp.data.data.carts) {
          if ($stateInfinite) {
            $stateInfinite.loaded()
          }
        } else if ($stateInfinite) {
          $stateInfinite.complete()
        }
      } catch (error) {
        const msg = getResponseErrorMessage(error)
        this.$message({
            showClose: true,
            message: msg,
            type: 'warning'
          })
      }
    }
  }
}
