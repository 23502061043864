import { render, staticRenderFns } from "./CardPartnerSmall.vue?vue&type=template&id=55761086&scoped=true&"
import script from "./CardPartnerSmall.vue?vue&type=script&lang=js&"
export * from "./CardPartnerSmall.vue?vue&type=script&lang=js&"
import style0 from "./CardPartnerSmall.vue?vue&type=style&index=0&id=55761086&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55761086",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Badge: require('/usr/src/app/components/base/Badge.vue').default,Clamp: require('/usr/src/app/components/base/Clamp.vue').default,IconStarFilled: require('/usr/src/app/components/icons/IconStarFilled.vue').default,DividerVertical: require('/usr/src/app/components/base/DividerVertical.vue').default,IconBxsStore: require('/usr/src/app/components/icons/IconBxsStore.vue').default})
