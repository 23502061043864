//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'

import windowResizeMixin from '@/mixins/window-resize-mixin'

import { catalogPartnerSections, storeSimpleType, storeTypes } from '@/utils/helpers/store-helper'
import {
  assignResponseToDataModelData,
  defaultPaginationAttributes,
  resetPaginationData
} from '@/utils/helpers/pagination-helper'
import { filterNonNullValues } from '@/utils/helpers/object-helper'

export default {
  mixins: [
    windowResizeMixin
  ],

  data () {
    const catalogListPartnersSortOptions = {
      latest: {
        label: 'Terbaru',
        value: 'latest'
      },
      mostPopular: {
        label: 'Terpopuler',
        value: 'most_popular'
      },
      nearest: {
        label: 'Terdekat',
        value: 'nearest'
      },
      nameAsc: {
        label: 'A-Z',
        value: 'name_asc'
      },
      nameDesc: {
        label: 'Z-A',
        value: 'name_desc'
      }
    }

    return {
      catalogListPartnersSortOptions,
      catalogListPartners: {
        ...defaultPaginationAttributes(),
        perPage: 10,

        filter: {
          search: '',
          sort: ''
        }
      }
    }
  },

  computed: {
    ...mapState('User/address', [
      'location'
    ]),

    breadCrumb () {
      if (this.querySearch) {
        return [
          {
            path: '/'
          },
          {
            name: `Hasil pencarian “${this.querySearch}”`
          }
        ]
      }

      return [
        {
          path: '/'
        },
        {
          name: this.partnerLabel || 'Partner'
        }
      ]
    },

    querySearch () {
      return this.$route.query.search
    },

    querySortFilter () {
      return this.$route.query.catalogListPartnersSort
    },

    partnerLabel () {
      switch (this.$route.query.type) {
        case storeSimpleType.brand_partner:
          return storeTypes.brandPartner.label
        case storeSimpleType.store_partner:
          return storeTypes.storePartner.label
        default:
          return 'Partner'
      }
    },

    partnerSectionFilter () {
      if (!this.$route.query.section) {
        return catalogPartnerSections.aroundYou.value
      }

      return this.$route.query.section
    },

    partnerTypeFilter () {
      const typeMappings = {
        [storeSimpleType.brand_partner]: storeTypes.brandPartner.value,
        [storeSimpleType.store_partner]: storeTypes.storePartner.value
      }

      const queryType = this.$route.query.type ?? null

      const isAroundYouSection = this.partnerSectionFilter === catalogPartnerSections.aroundYou.value

      if (isAroundYouSection) {
        return queryType ? (typeMappings[queryType] ?? null) : storeTypes.storePartner.value
      }

      return typeMappings[queryType] ?? null
    }
  },

  watch: {
    '$route.query': {
      async handler (newValue) {
        if (newValue) {
          this.populateRouteQueryToCatalogListPartners(
            newValue
          )
        }

        this.scrollToTop()

        this.resetCatalogListPartners()
        await this.getCatalogPartnersData(true)
        this.$refs.catalogListPartners.resetInfiniteLoading()
      },
      deep: true
    },

    location: {
      async handler (newValue, oldValue) {
        if (newValue && oldValue.city_id) {
          this.scrollToTop()

          this.resetCatalogListPartners()
          await this.getCatalogPartnersData(true)
          this.$refs.catalogListPartners.resetInfiniteLoading()
        }
      },
      deep: true
    }
  },

  mounted () {
    this.getAndSetPageData()
  },

  methods: {
    ...mapActions('Partner/CatalogPartners', [
      'getPartners',
      'getAroundYouPartners'
    ]),

    getAndSetPageData () {
      this.populateRouteQueryToCatalogListPartners(
        this.$route.query
      )
      this.getCatalogPartnersData()
    },

    async getCatalogPartnersData (resetData = false) {
      try {
        this.catalogListPartners.loading = true

        let response = null
        if (this.partnerSectionFilter === catalogPartnerSections.aroundYou.value) {
          response = await this.getAroundYouPartners({
            params: filterNonNullValues({
              paginated: this.catalogListPartners.paginated,
              per_page: this.catalogListPartners.perPage,
              page: this.catalogListPartners.page + 1,
              type: this.partnerTypeFilter,
              city_id: this.catalogListPartners.filter.sort === this.catalogListPartnersSortOptions.nearest.value && this.location
                ? this.location.city_id
                : null,
              order_by: this.catalogListPartners.filter.sort || null
            })
          })
        } else {
          response = await this.getPartners({
            params: filterNonNullValues({
              paginated: this.catalogListPartners.paginated,
              per_page: this.catalogListPartners.perPage,
              page: this.catalogListPartners.page + 1,
              search: this.querySearch || null
            })
          })
        }

        assignResponseToDataModelData(
          this.catalogListPartners,
          response.data.data.stores,
          resetData
        )
      } catch (error) {
        console.error(error)
        this.$refs.catalogListPartners.completeInfiniteLoading()
      } finally {
        this.catalogListPartners.loading = false
      }
    },

    handleSelectFilterSortCatalogListPartnersOnChange () {
      this.$refs.catalogListPartners.completeInfiniteLoading()
      this.pushFilterIntoRouteQuery({
        catalogListPartnersSort: this.catalogListPartners.filter.sort
      })
    },

    async handleCatalogListPartnersInfiniteLoading ($state) {
      if (this.catalogListPartners.page >= this.catalogListPartners.totalPage) {
        $state.complete()
        return
      }

      await this.getCatalogPartnersData()
      $state.loaded()
    },

    // START - Others
    populateRouteQueryToCatalogListPartners (queries = {}) {
      this.catalogListPartners.filter.search = queries.search || ''

      if (this.querySortFilter) {
        this.catalogListPartners.filter.sort = this.querySortFilter
      } else {
        this.catalogListPartners.filter.sort = this.partnerSectionFilter === catalogPartnerSections.aroundYou.value
          ? this.catalogListPartnersSortOptions.nearest.value
          : this.catalogListPartnersSortOptions.latest.value
      }
    },

    resetCatalogListPartners () {
      resetPaginationData(this.catalogListPartners, {
        perPage: this.catalogListPartners.perPage
      })
    },

    pushFilterIntoRouteQuery (query) {
      this.$router.push({
        query: filterNonNullValues({
          ...this.$route.query,
          ...query
        })
      })
    }
    // END - Others
  },

  head () {
    return {
      title: `Semua ${this.partnerLabel} Favorit dalam Satu Tempat | Paninti Store`,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `Ingin cari produk dari ${this.partnerLabel} favoritmu? Paninti punya semuanya! Temukan berbagai ${this.partnerLabel} dan mulai belanja dengan mudah. Klik tautan ini dan cek sekarang!`
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: `Semua ${this.partnerLabel} Favorit dalam Satu Tempat | Paninti Store`
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: `Ingin cari produk dari ${this.partnerLabel} favoritmu? Paninti punya semuanya! Temukan berbagai ${this.partnerLabel} dan mulai belanja dengan mudah. Klik tautan ini dan cek sekarang!`
        },
        {
          hid: 'og:keywords',
          name: 'og:keywords',
          content: 'partner merek bayi, daftar merek ibu dan bayi, merek terpercaya ibu dan bayi, produk bayi berkualitas, merek bayi terbaik, partner paninti store'
        }
      ]
    }
  }
}
