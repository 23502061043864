//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins/images-mixin.js'
import {
  storeTypes,
  storeSimpleType,
  storeFileNames,
  defaultStoreLocationLabel,
  defaultStoreEmptyRating
} from '@/utils/helpers/store-helper'
import { fileVariants, getDefaultImageFromFiles, getDefaultStoreLogoOnError } from '@/utils/helpers/file-helper'

export default {
  mixins: [mixins],

  props: {
    partner: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    defaultStoreLocationLabel () {
      return defaultStoreLocationLabel
    },

    defaultStoreEmptyRating () {
      return defaultStoreEmptyRating
    },

    fileVariants () {
      return fileVariants
    },

    storeFileNames () {
      return storeFileNames
    },

    storeTypes () {
      return storeTypes
    },

    storeSimpleType () {
      return storeSimpleType
    },

    isBrandPartner () {
      return this.partner.type === this.storeTypes.brand.value || false
    }
  },

  methods: {
    getDefaultStoreLogoOnError,
    getDefaultImageFromFiles,

    onCardPartnerRelatedActionClicked (slug) {
      this.$router.push(`/partner/${slug}`)
    }
  }
}
