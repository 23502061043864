//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatRupiah } from '@/utils/helpers/string-helper'
import { isObjectNotEmptyOrNull } from '@/utils/helpers/object-helper'
import { getDefaultImageFromFiles, getDefaultImgProductOnError } from '@/utils/helpers/file-helper'
export default {
  props: {
    productItem: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    formatRupiah,
    isObjectNotEmptyOrNull,
    getDefaultImageFromFiles,
    getDefaultImgProductOnError
  }
}
