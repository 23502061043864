//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { slugtify } from '@/utils/helpers/string-helper'
import { defaultPaginationAttributes } from '@/utils/helpers/pagination-helper'

export default {
  props: {
    sectionData: {
      type: Object,
      required: true,
      default: () => ({
        title: '',
        showSeeAllProduct: true,
        isSlideProducts: false
      })
    },

    products: {
      type: Object,
      required: true,
      default: () => ({
        ...defaultPaginationAttributes()
      })
    }
  },

  mounted () {
    this.$emit('mounted')
  },

  methods: {
    slugtify,

    handleSeeAllProductOnClick () {
      this.$emit('see-all-product-on-click')
    }
  }
}
