//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'

import pageMixin from '@/mixins/page-mixin'
import windowResizeMixin from '@/mixins/window-resize-mixin'

import { fileVariants, getDefaultCategoryIconOnError, getDefaultImageFromFiles } from '@/utils/helpers/file-helper'
import {
  assignResponseToDataModelDataNotPaginated,
  defaultNotPaginationAttributes
} from '@/utils/helpers/pagination-helper'
import { categoryFileNames, getCategoryPagePathFromCategory } from '@/utils/helpers/category-helper'

export default {
  mixins: [
    pageMixin,
    windowResizeMixin
  ],

  data () {
    return {
      categories: {
        ...defaultNotPaginationAttributes()
      },

      activeCategoryItem: ''
    }
  },

  computed: {
    ...mapState('Category/AllCategoryDrawer', [
      'allCategoryDrawerIsOpen'
    ]),

    fileVariants () {
      return fileVariants
    },

    categoryFileNames () {
      return categoryFileNames
    },

    activeCategory () {
      return this.categories.data.find(category => category.slug === this.activeCategoryItem)
    }
  },

  watch: {
    '$route.query': {
      handler (value) {
        this.setDefaultActiveCategoryItem()
      },
      deep: true
    }
  },

  async mounted () {
    await this.getCategoriesForNavbarData()
    this.setDefaultActiveCategoryItem()
  },

  methods: {
    getCategoryPagePathFromCategory,
    getDefaultImageFromFiles,
    getDefaultCategoryIconOnError,

    ...mapActions('Category', ['getCategoriesForNavbar']),

    async getCategoriesForNavbarData () {
      try {
        this.categories.loading = true

        const response = await this.getCategoriesForNavbar()
        assignResponseToDataModelDataNotPaginated(this.categories, response.data.data.categories)
      } catch (error) {
        console.error(error)
      } finally {
        this.categories.loading = false
      }
    },

    setDefaultActiveCategoryItem () {
      const categorySlug = this.$route.query.categorySlug

      if (categorySlug) {
        this.activeCategoryItem = categorySlug
      } else {
        this.activeCategoryItem = this.categories.data.length > 0 ? this.categories.data[0].slug : ''
      }
    },

    handleCategoryTabItemOnClick (category) {
      this.$router.push({
        query: {
          ...this.$route.query,
          categorySlug: category.slug
        }
      })
    },

    goToCategoryPageFromCategory (category) {
      if (!category) {
        return
      }

      window.location.href = getCategoryPagePathFromCategory(category)
    }
  }
}
