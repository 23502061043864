//
//
//
//
//
//
//
//
//

export default {
  props: {
    iconClass: {
      type: String,
      default: () => ''
    }
  }
}
