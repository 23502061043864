import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5dd1acac&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BannerEventSaleSection: require('/usr/src/app/components/home/sale/BannerEventSaleSection.vue').default,BrandSaleSection: require('/usr/src/app/components/home/sale/BrandSaleSection.vue').default,SectionTemplate: require('/usr/src/app/components/home/sale/SectionTemplate.vue').default,BannerBottomSection: require('/usr/src/app/components/home/BannerBottomSection.vue').default})
