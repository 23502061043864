//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
  name: 'NuxtError',
  layout: 'default',

  props: {
    error: {
      type: Object || null || undefined,
      default: () => {}
    }
  },

  computed: {
    ...mapState('Contents', ['littleBanner']),

    nuxtErrorPageMinHeight () {
      return this.littleBanner && this.littleBanner.length > 0 ? '46.9vh' : '51.2vh'
    },

    errorImage () {
      let errorImage = require('@/static/img/error/maintenance.svg')
      if (this.error.statusCode === 404) { errorImage = require('@/static/img/error/404.svg') }
      if (this.errorType !== 'general') { errorImage = this.error.image }

      return errorImage
    },

    errorType () {
      return !this.error || !this.error.type ? 'general' : this.error.type
    },

    errorMessage () {
      let errorMessage = 'Terjadi Kesalahan Server'
      if (this.error.statusCode === 404) { errorMessage = 'Halaman tidak ditemukan' }
      if (this.errorType !== 'general') { errorMessage = this.error.message }

      return errorMessage
    },

    errorSubMessage () {
      let errorSubMessage = 'Maaf ada kesalahan pada server kami. Mohon untuk tunggu beberapa saat dan coba lagi.'
      if (this.error.statusCode === 404) {
        errorSubMessage = 'Maaf halaman yang dimaksud tidak ada. Mohon untuk kembali ke beranda'
      }
      if (this.errorType !== 'general') { errorSubMessage = this.error.subMessage }

      return errorSubMessage
    }
  },

  head () {
    return {
      title: this.message,
      meta: [
        {
          name: 'viewport',
          content: 'width=device-width,initial-scale=1.0,minimum-scale=1.0'
        }
      ]
    }
  }
}
