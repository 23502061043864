//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { isObjectNotEmptyOrNull } from '@/utils/helpers/object-helper'
import { isArrayNotNullOrEmpty } from '@/utils/helpers/array-helper'
import { formatRupiah } from '@/utils/helpers/string-helper'
import { getDefaultImageFromFiles } from '@/utils/helpers/file-helper'
export default {
  computed: {
    ...mapState('Order', ['orderDetailPayment']),
    orderDetailPaymentExist () {
      return isObjectNotEmptyOrNull(this.orderDetailPayment)
    },
    listOrder () {
      if (this.orderDetailPaymentExist) {
        return this.orderDetailPayment.orders
      }

      return []
    },
    paymentFiles () {
      if (this.orderDetailPaymentExist && isArrayNotNullOrEmpty(this.listOrder)) {
        return isObjectNotEmptyOrNull(this.listOrder[0].payment) && this.listOrder[0].payment.files
      }

      return []
    }
  },
  methods: {
    formatRupiah,
    getDefaultImageFromFiles,
    showNotif () {
      this.$message({
        message: this.orderDetailPayment.payment_method_type === 'Bank Transfer' ? 'Akun Bank berhasil disalin' : 'No. Virtual Account berhasil disalin',
        type: 'success'
      })
    },
    handleShowDetail () {
      if (this.listOrder.length === 1) {
        this.$router.push(`/account/shop/my-order/detail/${this.$route.params.order_id}`)
      } else {
        this.$router.push('/account/shop/my-order')
      }
    },
    handlePaymentConfirm () {
      this.$router.push(
        `/payment-confirmation/${this.$route.params.order_id}`
      )
    },
    handleShopAgain () {
      window.location.replace('/')
    }
  }
}
