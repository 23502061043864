import { render, staticRenderFns } from "./PartnerProfileHeader.vue?vue&type=template&id=4a9ddc2a&scoped=true&"
import script from "./PartnerProfileHeader.vue?vue&type=script&lang=js&"
export * from "./PartnerProfileHeader.vue?vue&type=script&lang=js&"
import style0 from "./PartnerProfileHeader.vue?vue&type=style&index=0&id=4a9ddc2a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a9ddc2a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Banner: require('/usr/src/app/components/base/banner/Banner.vue').default,CardPartnerProfileHeader: require('/usr/src/app/components/home/partner/CardPartnerProfileHeader.vue').default})
