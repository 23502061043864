//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  async asyncData ({ store, params, error }) {
    try {
      const resp = await store.dispatch(
        'Order/getOrderPaymentDetail',
        params.order_id
      )

      if (resp.data.data.order_payment.is_payment_proof_uploaded || resp.data.data.order_payment.paid) {
        error({ statusCode: 404, message: 'Halaman tidak ditemukan' })
      }
    } catch (exerror) {
      error({ statusCode: 404, message: 'Halaman tidak ditemukan' })
    }
  },
  data () {
    const breadcrumbs = [
      {
        path: '/'
      },
      {
        name: 'Pesanan Saya',
        path: '/account/shop/my-order'
      },
      {
        name: 'Konfirmasi Pembayaran'
      }
    ]
    return {
      breadCrumb: breadcrumbs
    }
  },
  head: () => {
    return {
      title: 'Konfirmasi Pembayaran | Paninti Store'
    }
  }
}
