//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import windowResizeMixin from '@/mixins/window-resize-mixin'

import { generateRandomString } from '@/utils/helpers/string-helper'
import { cardProductPurposes } from '@/utils/helpers/product-helper'
import { showcaseTypes } from '@/utils/helpers/showcase-helper'

export default {
  mixins: [
    windowResizeMixin
  ],

  props: {
    sortFilterOptions: {
      type: Object,
      default: () => ({})
    },

    showcaseType: {
      type: String,
      default: () => ''
    },

    partner: {
      type: Object,
      required: true
    },

    partnerProducts: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      //
    }
  },

  computed: {
    showcaseTypes () {
      return showcaseTypes
    },

    cardProductPurposes () {
      return cardProductPurposes
    },

    isShowcaseEventSaleProducts () {
      if (!this.showcaseType) {
        return false
      }

      return [showcaseTypes.eventSale, showcaseTypes.flashSale, showcaseTypes.clearanceSale, showcaseTypes.presale]
        .includes(this.showcaseType)
    },

    emptyStateData () {
      const emptyStateData = {
        illustrationPath: '/img/illustration/no-product.svg',
        title: 'Produk tidak ditemukan',
        description: 'Cari produk lainnya yuk!'
      }

      const partnerName = this.partner ? this.partner.name : 'Toko'

      switch (this.showcaseType) {
        case showcaseTypes.flashSale:
          emptyStateData.illustrationPath = '/img/illustration/no-flash-sale.svg'
          emptyStateData.title = 'Oops!'
          emptyStateData.description = `Mohon maaf, belum ada Flash Sale di ${partnerName}. Besok cek lagi ya!`
          break
        case showcaseTypes.eventSale:
          emptyStateData.illustrationPath = '/img/illustration/no-sale.svg'
          emptyStateData.title = 'Oops!'
          emptyStateData.description = `Mohon maaf, belum ada produk Event Sale di ${partnerName}. Besok cek lagi ya!`
          break
        case showcaseTypes.clearanceSale:
          emptyStateData.illustrationPath = '/img/illustration/no-clearance-sale.svg'
          emptyStateData.title = 'Oops!'
          emptyStateData.description = `Mohon maaf, belum ada produk Clearance Sale di ${partnerName}. Besok cek lagi ya!`
          break
        case showcaseTypes.presale:
          emptyStateData.illustrationPath = '/img/illustration/no-pre-sale.svg'
          emptyStateData.title = 'Oops!'
          emptyStateData.description = `Tidak ada Pre Sale untuk produk ${partnerName}, besok cek lagi yaa!`
          break
      }

      return emptyStateData
    }
  },

  methods: {
    generateRandomString,

    handleSelectFilterSortProductsSortOnChange (value) {
      this.$emit('select-filter-sort-products-on-change', value)
    },

    handleInfiniteLoading ($state) {
      this.$emit('infinite-loading', $state)
    },

    completeInfiniteLoading () {
      if (this.$refs.infiniteLoading) {
        this.$refs.infiniteLoading.stateChanger.complete()
      }
    },

    resetInfiniteLoading () {
      if (this.$refs.infiniteLoading) {
        this.$refs.infiniteLoading.stateChanger.reset()
      }
    }
  }
}
