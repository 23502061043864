import { render, staticRenderFns } from "./PopupCardAddressItem.vue?vue&type=template&id=5ea63145&scoped=true&"
import script from "./PopupCardAddressItem.vue?vue&type=script&lang=js&"
export * from "./PopupCardAddressItem.vue?vue&type=script&lang=js&"
import style0 from "./PopupCardAddressItem.vue?vue&type=style&index=0&id=5ea63145&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ea63145",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconPinLocation: require('/usr/src/app/components/icons/IconPinLocation.vue').default,Badge: require('/usr/src/app/components/base/Badge.vue').default,Button: require('/usr/src/app/components/base/Button.vue').default,Divider: require('/usr/src/app/components/base/Divider.vue').default})
