//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { fileVariants, getDefaultImageFromFiles } from '@/utils/helpers/file-helper'
import { lorikeetFileNames } from '@/utils/helpers/lorikeet-helper'

export default {
  props: {
    lorikeets: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    lorikeetFileNames () {
      return lorikeetFileNames
    },

    fileVariants () {
      return fileVariants
    }
  },

  methods: {
    getDefaultImageFromFiles,

    handleLorikeetImageOnError (event, lorikeet) {
      lorikeet.exists = false
    }
  }
}
