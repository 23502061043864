import { render, staticRenderFns } from "./ModalDetailVoucher.vue?vue&type=template&id=b3895586&scoped=true&"
import script from "./ModalDetailVoucher.vue?vue&type=script&lang=js&"
export * from "./ModalDetailVoucher.vue?vue&type=script&lang=js&"
import style0 from "./ModalDetailVoucher.vue?vue&type=style&index=0&id=b3895586&lang=scss&scoped=true&"
import style1 from "./ModalDetailVoucher.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3895586",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Divider: require('/usr/src/app/components/base/Divider.vue').default,IconBagDiscount: require('/usr/src/app/components/icons/IconBagDiscount.vue').default,IconTime: require('/usr/src/app/components/icons/IconTime.vue').default,IconPuzzle: require('/usr/src/app/components/icons/IconPuzzle.vue').default,Button: require('/usr/src/app/components/base/Button.vue').default,ModalBase: require('/usr/src/app/components/base/ModalBase.vue').default})
