//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'
import panstoreMixin from '@/mixins'
export default {
  mixins: [panstoreMixin],
  props: {
    voucher: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    storeId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      checked: false
    }
  },
  methods: {
    ...mapMutations('Voucher', ['setVoucherUsed']),
    getImage (type) {
      if (type === 'discount') {
        return require('@/static/img/home/cart/voucher-tag.svg')
      } else {
        return require('@/static/img/home/cart/voucher-truck.svg')
      }
    },
    handleClick (storeId, voucherCode, voucherId, voucherData) {
      if (this.checked) {
        this.setVoucherUsed({ storeId, voucherId, voucherData })
        this.$emit('unchecked')
      } else {
        this.$emit('click', { voucherCode, storeId })
      }
    }
  }
}
