var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-order-review"},[_vm._l((_vm.orderReviews.data),function(list,index){return _c('div',{key:index,staticClass:"card-comp card-order-review border-radius-small border"},[(list.product && list.product.brand)?[_c('div',{staticClass:"card-body-review"},[_c('div',{staticClass:"product-info"},[_c('div',{staticClass:"brand-detail"},[_c('span',{staticClass:"brand-name paninti-content-sm regular color-text-sub"},[_vm._v("\n              "+_vm._s(list.product.store.name || '-')+"\n              "),_c('img',{attrs:{"src":list.product.store.type === _vm.storeTypes.brandPartner.value
                    ? '/img/icon/brand_partner.svg'
                    : '/img/icon/store_partner.svg',"alt":list.product.store.type}})])]),_vm._v(" "),_c('div',{staticClass:"product-top"},[_c('div',{staticClass:"product-image-wrapper"},[_c('img',{staticClass:"img-product",attrs:{"src":list.product.files.length
                    ? list.product.files[0].value + '.webp'
                    : _vm.getDefaultImgProduct,"alt":"Gambar Produk"},on:{"error":_vm.getDefaultImgProduct}})]),_vm._v(" "),_c('div',{staticClass:"product-detail"},[_c('div',{staticClass:"brand-name-right paninti-content-sm bold"},[_vm._v("\n                "+_vm._s(list.product.brand.name)+"\n              ")]),_vm._v(" "),_c('v-clamp',{ref:'desc-' + index,refInFor:true,staticClass:"paninti-content-base regular",attrs:{"autoresize":"","max-lines":2}},[_vm._v("\n                "+_vm._s(list.product.name)+"\n              ")]),_vm._v(" "),(list.total_product > 0)?_c('div',{staticClass:"product-variant paninti-content-base regular"},[(
                    list.product.detail_name &&
                      list.product.detail_name !== 'Default'
                  )?[_vm._v("\n                  "+_vm._s(list.product.detail_name)+" -\n                ")]:_vm._e(),_vm._v("\n                "+_vm._s(list.total_product)+" produk\n              ")],2):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"product-buttons d-flex align-items-center w-100 gap-3"},[_c('button',{staticClass:"paninti-btn paninti-btn-primary flex-fill justify-content-center",attrs:{"text":"Beli Lagi"},on:{"click":function($event){return _vm.handleCheck(list.product.order_product_ids || [])}}},[_vm._v("\n              Beli Lagi\n            ")]),_vm._v(" "),_c('nuxt-link',{staticClass:"flex-fill",attrs:{"to":("/product/" + (list.product.slug))}},[_c('button',{staticClass:"paninti-btn paninti-btn-primary-outline w-100 justify-content-center",attrs:{"text":"Lihat Produk"}},[_vm._v("\n                Lihat Produk\n              ")])])],1)]),_vm._v(" "),_c('Divider',{attrs:{"direction":"vertical"}}),_vm._v(" "),_c('div',{staticClass:"review-content"},[_c('div',{staticClass:"header-right d-flex align-items-center justify-content-between w-100"},[_c('client-only',[_c('el-rate',{staticClass:"custom-rating",attrs:{"value":Number(list.rating),"disabled":"","colors":['#FFCF44', '#FFCF44', '#FFCF44']}})],1),_vm._v(" "),_c('span',{staticClass:"profile-date paninti-content-sm regular"},[_vm._v("\n              "+_vm._s(list.timestamp)+"\n            ")])],1),_vm._v(" "),(list.suggestions.length)?_c('div',{staticClass:"product-excess"},[_c('div',{staticClass:"excess-wrapper"},_vm._l((list.suggestions),function(item,idx){return _c('Badge',{key:idx,attrs:{"border":false,"variant":"pink","rounded":""}},[_vm._v("\n                "+_vm._s(item.title)+"\n              ")])}),1)]):_vm._e(),_vm._v(" "),(list.description)?_c('div',{staticClass:"product-text-review paninti-content-sm regular"},[_c('v-clamp',{ref:'desc-' + index,refInFor:true,attrs:{"autoresize":"","max-lines":3},scopedSlots:_vm._u([{key:"after",fn:function(ref){
                  var toggle = ref.toggle;
                  var expanded = ref.expanded;
return [(list.description.length > 180)?_c('div',{staticClass:"toggle font-weight-bold color-text-link-primary cursor-pointer",attrs:{"slot":"after"},on:{"click":toggle},slot:"after"},[_vm._v("\n                  "+_vm._s(expanded ? 'Sembunyikan' : 'Lihat Selengkapnya')+"\n                ")]):_vm._e()]}}],null,true)},[_vm._v("\n              "+_vm._s(list.description)+"\n              ")])],1):_vm._e(),_vm._v(" "),(list.files && list.files.length)?_c('div',{staticClass:"review-images"},_vm._l((list.files.slice(0, 5)),function(file,i){return _c('img',{key:i,staticClass:"review-thumbnail",attrs:{"src":file.value + '.webp',"alt":"Review Image"},on:{"error":_vm.getDefaultImgProduct,"click":function($event){return _vm.showImage(i, list.files || [])}}})}),0):_vm._e()])],1)]:_vm._e()],2)}),_vm._v(" "),_c('button',{ref:"buttonModalImageReview",staticClass:"d-none",attrs:{"data-bs-toggle":"modal","data-bs-target":"#modalImageReview"}}),_vm._v(" "),_c('button',{ref:"buttonModalRebuyProduct",staticClass:"d-none",attrs:{"data-bs-toggle":"modal","data-bs-target":"#modalRebuyProduct"}}),_vm._v(" "),_c('ModalImageReview',{attrs:{"images":_vm.images,"index":_vm.indexImg}}),_vm._v(" "),_c('ModalRebuyProduct',{attrs:{"datas":_vm.dataProductsChecked}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }