import { render, staticRenderFns } from "./PartnerProfileBody.vue?vue&type=template&id=7ad7aa8c&scoped=true&"
import script from "./PartnerProfileBody.vue?vue&type=script&lang=js&"
export * from "./PartnerProfileBody.vue?vue&type=script&lang=js&"
import style0 from "./PartnerProfileBody.vue?vue&type=style&index=0&id=7ad7aa8c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ad7aa8c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Tab: require('/usr/src/app/components/base/Tab.vue').default,PartnerProfileBodyTabHomepage: require('/usr/src/app/components/home/partner/PartnerProfileBodyTabHomepage.vue').default,PartnerProfileBodyTabAllProduct: require('/usr/src/app/components/home/partner/PartnerProfileBodyTabAllProduct.vue').default})
