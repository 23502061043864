//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'

import { ucwords } from '@/utils/helpers/string-helper'
import { defaultPaginationAttributes } from '@/utils/helpers/pagination-helper'
import { catalogPartnerSections } from '@/utils/helpers/store-helper'

export default {
  mixins: [
    mixins
  ],

  props: {
    partnerLabel: {
      type: String,
      default: () => 'Partner'
    },

    partnerSection: {
      type: String,
      default: () => catalogPartnerSections.allPartner.value
    },

    sortOptions: {
      type: Object,
      default: () => ({})
    },

    partners: {
      type: Object,
      default: () => ({
        ...defaultPaginationAttributes()
      })
    }
  },

  computed: {
    catalogPartnerSections () {
      return catalogPartnerSections
    }
  },

  methods: {
    ucwords,

    handleInfiniteLoading ($state) {
      this.$emit('infinite-loading', $state)
    },

    handleSelectFilterSortPartnersOnChange (value) {
      this.$emit('select-filter-sort-partners-on-change', value)
    },

    completeInfiniteLoading () {
      if (this.$refs.infiniteLoading) {
        this.$refs.infiniteLoading.stateChanger.complete()
      }
    },

    resetInfiniteLoading () {
      if (this.$refs.infiniteLoading) {
        this.$refs.infiniteLoading.stateChanger.reset()
      }
    }
  }
}
