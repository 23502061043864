//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex';

import { fileVariants, getDefaultImageFromFiles, getDefaultImgBannerOnError } from '@/utils/helpers/file-helper'
import { contentFileNames } from '@/utils/helpers/content-helper'

export default {
  props: {
    promotion: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    fileVariants () {
      return fileVariants
    },

    contentFileNames () {
      return contentFileNames
    }
  },

  methods: {
    getDefaultImgBannerOnError,
    getDefaultImageFromFiles,

    ...mapMutations('Voucher', ['setVoucher', 'setToggleDetail']),

    onBannerPromotionClicked () {
      if (this.promotion.type !== 'voucher') {
        const type = {
          clearance_sale: 'clearance-sale',
          flash_sale: 'flashsale',
          event_sale: this.promotion.event_sales && this.promotion.event_sales.slug
            ? `sale/${this.promotion.event_sales.slug}`
            : 'sale',
          external: this.promotion.link
        }

        if (this.promotion.type === 'external') {
          window.location.href = this.promotion.link
        } else {
          this.$router.push(`/${type[this.promotion.type]}`)
        }
      } else {
        this.setVoucher(this.promotion.vouchers)
        this.setToggleDetail(true)
        this.$refs.showModalVoucher.click()
      }
    }
  }
}
