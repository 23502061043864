import { render, staticRenderFns } from "./ListOrderReview.vue?vue&type=template&id=25318c49&scoped=true&"
import script from "./ListOrderReview.vue?vue&type=script&lang=js&"
export * from "./ListOrderReview.vue?vue&type=script&lang=js&"
import style0 from "./ListOrderReview.vue?vue&type=style&index=0&id=25318c49&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25318c49",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/usr/src/app/components/base/Button.vue').default,Divider: require('/usr/src/app/components/base/Divider.vue').default,Badge: require('/usr/src/app/components/base/Badge.vue').default,ModalImageReview: require('/usr/src/app/components/account/shop/order-review/ModalImageReview.vue').default,ModalRebuyProduct: require('/usr/src/app/components/account/shop/order-review/ModalRebuyProduct.vue').default})
