//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isObjectNotEmptyOrNull } from '@/utils/helpers/object-helper'
import { isArrayNotNullOrEmpty } from '@/utils/helpers/array-helper'
import orderModuleMixins from '@/mixins/order-module-mixins'
export default {
  mixins: [orderModuleMixins],
  props: {
    fulfilled: {
      type: Boolean,
      default: false
    },
    availableCarts: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    hasAvailableCarts () {
      return isObjectNotEmptyOrNull(this.availableCarts) && isArrayNotNullOrEmpty(this.availableCarts.store_branches)
    }
  },
  methods: {
    getCartGroupItemStoreBranches (storeBranchId) {
      if (isArrayNotNullOrEmpty(this.cartGroupStoreBranches)) {
        const cartGroupStoreBranchIdx = this.cartGroupStoreBranches.findIndex((branch) => {
          return storeBranchId === branch.storeBranchId
        })

        if (cartGroupStoreBranchIdx === -1) {
          return []
        }

        return this.cartGroupStoreBranches[cartGroupStoreBranchIdx].storeBranches
      }

      return []
    },
    getCartGroupItemShippingCouriers (storeBranchId) {
      if (isArrayNotNullOrEmpty(this.shippingCourier) && !this.pickupAtStore) {
        const cartGroupShippingCourierIdx = this.shippingCourier.findIndex((courier) => {
          return storeBranchId === courier.storeBranchId
        })

        if (cartGroupShippingCourierIdx === -1) {
          return []
        }
        return this.shippingCourier[cartGroupShippingCourierIdx].shippingCouriers
      }

      return []
    }
  }
}
