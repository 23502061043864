//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatRupiah } from '@/utils/helpers/string-helper'
import { isObjectNotEmptyOrNull } from '@/utils/helpers/object-helper'
import { isArrayNotNullOrEmpty } from '@/utils/helpers/array-helper'
import { getDefaultImageFromFiles, getDefaultStoreLogoOnError } from '@/utils/helpers/file-helper'
import { gramToKg } from '@/utils/helpers/number-helper'

import { Arrow } from '@egjs/flicking-plugins'

import { mapMutations } from 'vuex'
import orderModuleMixins from '@/mixins/order-module-mixins'
import windowResizeMixin from '@/mixins/window-resize-mixin'
export default {
  mixins: [orderModuleMixins, windowResizeMixin],
  props: {
    fulfilled: {
      type: Boolean,
      default: false
    },
    pickup: {
      type: Boolean,
      default: false
    },
    cartGroupItem: {
      type: Object,
      default: () => ({})
    },
    storeBranchOptions: {
      type: Array,
      default: () => ([])
    },
    shippingCouriers: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      isOpen: true,
      targetBranch: '',
      sliderArrow: {
        left: '<img src="/img/icon/chevron-left.svg" alt=""/>',
        right: '<img src="/img/icon/chevron-right.svg" alt=""/>'
      },
      plugins: [new Arrow()]
    }
  },
  computed: {
    cartGroupItemExist () {
      return isObjectNotEmptyOrNull(this.cartGroupItem)
    },
    productCarts () {
      let products = []

      if (!isObjectNotEmptyOrNull(this.cartGroupItem)) {
        return products
      }

      // handle non fulfilled checkout
      if (this.fulfilled) {
        this.cartGroupItem.carts.forEach((cart) => {
          const checkStoreIdx = products.findIndex((product) => {
            return product.storeId === cart.product_detail.product.store.id
          })

          if (checkStoreIdx === -1) {
            const productObj = {
              ...cart.product_detail.product.store,
              storeId: cart.product_detail.product.store.id,
              carts: [cart]
            }

            products.push(productObj)
          } else {
            products[checkStoreIdx].carts.push(cart)
          }
        })
      } else {
        products = this.cartGroupItem.carts.map((cart) => {
          return cart
        })
      }

      return products
    },
    totalWeight () {
      let weight = 0

      if (!this.cartGroupItemExist) {
        return weight
      }

      this.cartGroupItem.carts.forEach((cart) => {
        weight += cart.total_weight
      })

      return gramToKg(weight)
    },
    shippingVoucherDiscount () {
      if (this.cartGroupItemExist) {
        const shippingVoucher = this.cartGroupItem.vouchers.find((voucher) => {
          return voucher.type === 'free_shipping_cost'
        })

        if (isObjectNotEmptyOrNull(shippingVoucher)) {
          return shippingVoucher
        }
      }

      return {}
    },
    regularVoucherDiscount () {
      if (this.cartGroupItemExist) {
        const regularVoucher = this.cartGroupItem.vouchers.find((voucher) => {
          return voucher.type === 'discount'
        })

        if (isObjectNotEmptyOrNull(regularVoucher)) {
          return regularVoucher
        }
      }

      return {}
    }
  },
  watch: {
    storeBranchOptions: {
      handler () {
        this.setBranch()
      },
      deep: true
    },
    checkoutMethod () {
      this.$refs.sectionShippingPickup.resetForm()
    }
  },
  methods: {
    formatRupiah,
    isObjectNotEmptyOrNull,
    isArrayNotNullOrEmpty,
    getDefaultImageFromFiles,
    getDefaultStoreLogoOnError,
    ...mapMutations('Checkout', ['editAvailableCartsForm', 'modifyShippingCourier']),
    ...mapMutations('Checkout/branch', ['modifyCartGroupStoreBranches']),
    setIsOpen () {
      this.isOpen = !this.isOpen
    },
    beforeEnter (el) {
      el.style.height = '0px';
      el.style.overflow = 'hidden';
    },
    enter (el) {
      el.style.transition = 'height 0.3s ease-out';
      el.style.height = el.scrollHeight + 'px';
      setTimeout(() => (el.style.height = 'auto'), 300);
    },
    leave (el) {
      el.style.transition = 'height 0.3s ease-out';
      el.style.height = el.scrollHeight + 'px';
      requestAnimationFrame(() => {
        el.style.height = '0px';
      });
    },
    setBranch () {
      if (!this.targetBranch && isArrayNotNullOrEmpty(this.storeBranchOptions)) {
          // match store branch options with cart group item if target branch is empty
          const storeBranchIdx = this.storeBranchOptions.findIndex((option) => {
            return option.id === this.cartGroupItem.id
          })

          if (storeBranchIdx > -1) {
            this.targetBranch = this.storeBranchOptions[storeBranchIdx].id
            this.editAvailableCartsForm({
              cartGroupId: this.cartGroupItem.id,
              fields: [{
                name: 'selectedBranch',
                value: this.storeBranchOptions[storeBranchIdx]
              }]
            })
          }
        }
    },
    async modifyCartGroupData (formItem, value) {
      const editAvailableCartsFormPayload = {
        cartGroupId: this.cartGroupItem.id,
        fields: [{
          name: formItem,
          value
        }]
      }

      if (formItem === 'selectedBranch') {
        this.$refs.sectionShippingPickup.removeSelectedCourier()
        editAvailableCartsFormPayload.fields.push({
          name: 'selectedCourier',
          value: {}
        })
      }
      this.editAvailableCartsForm(editAvailableCartsFormPayload)

      if (formItem === 'selectedBranch') {
        this.modifyCartGroupStoreBranches({
          storeBranchId: this.cartGroupItem.id,
          fields: [{
            name: formItem,
            value
          }]
        })
      }

      if (formItem !== 'shippingPickupNote') {
        await this.checkAvaibilityCheckout()
      }
    }
  }
}
