//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapMutations } from 'vuex'
import { isObjectNotEmptyOrNull } from '@/utils/helpers/object-helper'
import { defaultCourierForCheckoutFulfilled } from '@/utils/helpers/checkout-helper'
import { isArrayNotNullOrEmpty } from '@/utils/helpers/array-helper'
import { defaultToastOptions, toastTypes } from '@/utils/helpers/toast-helper'
import { getResponseErrorMessage } from '@/utils/helpers/response-helper'
import orderModuleMixins from '@/mixins/order-module-mixins'
export default {
  name: 'CheckoutPage',
  mixins: [orderModuleMixins],
  middleware ({ $auth, redirect }) {
    if (!$auth.loggedIn) {
      return redirect('/cart')
    }
  },
  async asyncData ({ store }) {
    store.dispatch('Cart/actionWithCart', false)
    try {
      const resp = await store.dispatch('User/address/getUserAddress')
      if (resp.status === 200 && resp.data.data.addresses.data.length) {
        let mainAddress = resp.data.data.addresses.data.filter((address) => {
          return address.main
        })[0]
        if (!mainAddress) {
          mainAddress = resp.data.data.addresses.data[0]
          await store.dispatch('User/address/editUserAddress', {
            id: mainAddress.id,
            payload: {
              data: {
                main: true
              }
            }
          })
        }
        await store.commit('Checkout/setSelectedAddress', mainAddress)
        await store.commit('Checkout/setOrderPayload', {
          user_address_id: mainAddress.id
        })
      }
    } catch (error) {
      console.log(error)
    }
  },
  data () {
    const breadcrumbs = [
      {
        path: '/'
      },
      {
        path: '/cart',
        name: 'Keranjang'
      },
      {
        name: 'Checkout'
      }
    ]
    return {
      breadCrumb: breadcrumbs,
      loading: true,
      popup3DSPurpose: 'create-order'
    }
  },
  computed: {
    ...mapState('Checkout', [
      'selectedCourier',
      'listItem',
      'selectedAddress',
      'storeBranch',
      'storeBranchPAS',
      'branchSelected',
      'pageTakeInStore',
      'onCheck',
      'checkoutAvailability'
    ])
  },
  mounted () {
    // START XENDIT
    window.Xendit.setPublishableKey(process.env.XENDIT_PUBLIC_KEY)

    if (!localStorage.cartItem || !localStorage.cartItem.length) {
      this.$alert('Anda belum memilih produk untuk checkout')
      return this.$router.push('/cart')
    } else {
      this.initComponent()
    }
  },
  methods: {
    isObjectNotEmptyOrNull,
    ...mapMutations('Checkout', ['setOrderPayload']),
    ...mapMutations('Voucher', ['setVoucherUsed']),
    ...mapActions('Order', ['addOrder']),
    async initComponent () {
      if (localStorage.voucher) {
        this.setVoucherUsed(JSON.parse(localStorage.voucher))
      }
      const payload = {
        user_address_id: this.selectedAddress.id
      }
      await this.setOrderPayload(payload)
      this.loading = false

      if (!isObjectNotEmptyOrNull(this.checkoutAvailability)) {
        await this.checkAvaibilityCheckout()
      }

      if (isObjectNotEmptyOrNull(this.selectedAddress)) {
        await this.fetchBranchesForCheckout()
        await this.fetchShippingCourier()
      }
    },
    handleCreateOrderError () {
      setTimeout(() => {
        this.$refs.popupCheckoutLoading.closeModal()
      }, 500)
    },
    async handleVoucherUsed () {
      this.setLocalVoucher()
      await this.checkAvaibilityCheckout()
    },
    async handleCheckAvailability () {
      await this.checkAvaibilityCheckout()
    },
    handleShow3DS (purpose) {
      this.popup3DSPurpose = purpose
      this.$refs.open3DS.click()
    },
    async handleCreateOrder () {
      const note = localStorage.getItem('note')
      const payload = {
        data: {
          order_method: localStorage.getItem('order_method') || 'shipping',
          send_as_gift: !!note,
          gift_note: note || '',
          use_paninti_point: this.usePoint,
          user_address_id: this.selectedAddress.id,
          cart_ids: this.groupedSelectedItem.map((item) => {
            return item.items.map(cart => cart.cartId)
          }).flat(),
          voucher_codes: []
        }
      }

      if (isObjectNotEmptyOrNull(this.checkoutAvailability) && isArrayNotNullOrEmpty(this.availableCartsForm)) {
        payload.data = Object.assign(payload.data, {
          checkout_groups: this.availableCartsForm.map((cartForm) => {
            let checkoutGroup = {
              store_branch_id: this.checkoutAvailability.is_fulfilled ? cartForm.storeBranchId : cartForm.selectedBranch.id,
              cart_ids: cartForm.carts.map((cart) => {
                return cart.id
              }),
              note: cartForm.shippingPickupNote
            }

            if (isObjectNotEmptyOrNull(cartForm.selectedCourier)) {
              checkoutGroup = Object.assign(checkoutGroup, {
                shipping_courier: {
                  code: cartForm.selectedCourier.code,
                  service: cartForm.selectedCourier.service
                }
              })
            } else if (this.checkoutAvailability.is_fulfilled) {
              checkoutGroup = Object.assign(checkoutGroup, {
                shipping_courier: defaultCourierForCheckoutFulfilled
              })
            }

            // Append Voucher
            if (isArrayNotNullOrEmpty(this.voucherUsed)) {
              // TES
              let voucherCodes = []
              if (this.checkoutAvailability.is_fulfilled) {
                const products = []

                cartForm.carts.forEach((cart) => {
                  const checkStoreIdx = products.findIndex((product) => {
                    return product.storeId === cart.product_detail.product.store.id
                  })

                  if (checkStoreIdx === -1) {
                    const productObj = {
                      ...cart.product_detail.product.store,
                      storeId: cart.product_detail.product.store.id,
                      carts: [cart]
                    }

                    products.push(productObj)
                  } else {
                    products[checkStoreIdx].carts.push(cart)
                  }
                })

                // prepare voucher payload
                const storeIds = products.map((product) => {
                  return product.storeId
                })

                this.voucherUsed.forEach((used) => {
                  storeIds.forEach((storeId) => {
                    if (used.storeId === storeId) {
                      used.data.forEach((dt) => {
                        voucherCodes.push(dt.code)
                      })
                    }
                  })
                })
              } else {
                const storeVoucherIdx = this.voucherUsed.findIndex((used) => {
                  return cartForm.storeId === used.storeId
                })

                if (storeVoucherIdx > -1) {
                  voucherCodes = this.voucherUsed[storeVoucherIdx].data.map((dt) => {
                    return dt.code
                  })
                }
              }

              checkoutGroup.voucher_codes = voucherCodes
            }

            return checkoutGroup
          })
        })
      }

      if (isObjectNotEmptyOrNull(this.selectedPaymentMethod)) {
        payload.data = Object.assign(payload.data, {
          payment_method_id: this.selectedPaymentMethod.id
        })
      }

      if (isObjectNotEmptyOrNull(this.selectedPaymentAccount)) {
        payload.data = Object.assign(payload.data, {
          account_id: this.selectedPaymentAccount.id
        })

        if (isObjectNotEmptyOrNull(this.selectedCCInstallment) && isObjectNotEmptyOrNull(this.selectedPaymentAccount)) {
          payload.data = Object.assign(payload.data, {
            credit_card: {
              card_cvv: this.selectedPaymentAccount.value.card_cvv,
              card_token_id: this.selectedPaymentAccount.value.card_token_id,
              card_authentication_id: this.selectedPaymentAccount.value.card_authentication_id,
              installment_count: this.selectedCCInstallment.count,
              installment_interval: this.selectedCCInstallment.interval,
              save_card: false
            }
          })
        }
      }

      try {
        await this.addOrder(payload)
      } catch (error) {
        const errorMessage = getResponseErrorMessage(error)
        this.$message({
          ...defaultToastOptions(),
          message: errorMessage,
          type: toastTypes.error.value
        })
        this.handleCreateOrderError()
      }
    },
    handleCreateCCSuccess () {
      this.popup3DSPurpose = 'create-order'
    },
    handleOpenFormCC () {
      this.$refs.openCreditCard.click()
    }
  },
  beforeRouteLeave (to, from, next) {
    this.resetCheckoutPageData()
    next()
  }
}
