import { render, staticRenderFns } from "./ModalSharePartner.vue?vue&type=template&id=30f2cdab&"
import script from "./ModalSharePartner.vue?vue&type=script&lang=js&"
export * from "./ModalSharePartner.vue?vue&type=script&lang=js&"
import style0 from "./ModalSharePartner.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Clamp: require('/usr/src/app/components/base/Clamp.vue').default,DividerVertical: require('/usr/src/app/components/base/DividerVertical.vue').default,ButtonShareSocialMedia: require('/usr/src/app/components/base/ButtonShareSocialMedia.vue').default,ModalBase: require('/usr/src/app/components/base/ModalBase.vue').default})
