//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defaultToastOptions, toastTypes } from '@/utils/helpers/toast-helper'
import { getResponseErrorMessage } from '@/utils/helpers/response-helper'
import { formatRupiah } from '@/utils/helpers/string-helper'
import { mapState, mapActions } from 'vuex'
import panstoreMixin from '@/mixins'
export default {
  mixins: [panstoreMixin],
  data () {
    const validateNumberOnly = (rule, value, callback) => {
      const letters = /^[0-9]+$/
      const field = rule.field
      const input = value.charAt(value.length - 1)
      if (value === ' ') {
        this.formPayment[field] = ''
      }
      if (!input.match(letters)) {
        this.formPayment[field] = value.substring(0, value.length - 1)
      }

      callback()
    }

    return {
      formPayment: {
        organization_from: '',
        on_behalf_of: '',
        customer_account_number: '',
        medias: [],
        proof: {}
      },
      dataCropper: {},
      rules: {
        organization_from: [
          {
            required: true,
            message: 'Wajib Diisi',
            trigger: 'blur'
          }
        ],
        on_behalf_of: [
          {
            required: !this.isEDC,
            message: 'Wajib Diisi',
            trigger: 'blur'
          }
        ],
        customer_account_number: [
          {
            required: !this.isEDC,
            message: 'Wajib Diisi',
            trigger: 'blur'
          },
          {
            validator: validateNumberOnly
          }
        ],
        medias: [
          {
            required: true,
            message: 'Tambahkan gambar bukti pengiriman',
            trigger: 'blur'
          }
        ]
      },
      isImage: false,
      fileList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      onSubmit: false,
      showUpload: true,
      medias: [],
      customHeaders: {
        'Application-Key': 'Q5nGL97wRBFlgQ2WOG3XDnHhV33jFIYWdHKArumKS78YaSQA3U',
        Authorization: this.$auth.loggedIn
          ? this.$auth.getToken('local')
          : null
      },
      windowWidth: process.client ? window.innerWidth : 0,
      windowHeight: process.client ? window.innerHeight : 0
    }
  },
  computed: {
    ...mapState('Order', ['order', 'orderDetailPayment']),
    baseApi () {
      return process.env.BASE_API
    },
    normalPhone () {
      return this.windowWidth > 0 && this.windowWidth < 992
    },
    normalDesktop () {
      return this.windowWidth >= 992
    },
    isEDC () {
      return this.order.payment ? this.order.payment.payment_method_type === 'EDC' : false
    }
  },
  methods: {
    formatRupiah,
    ...mapActions('Files', ['uploadFiles']),
    ...mapActions('Order', ['postPaymentConfirmation']),
    onResize () {
      this.windowWidth = process.client ? window.innerWidth : 0
    },
    async handleChange (file) {
      this.showUpload = false
      const isImage = file.raw.type.substr(0, 5) === 'image'
      if (isImage && file.raw.type !== 'image/gif') {
        this.openCropper()
        const urlImage = await this.toBase64(file.raw)
        this.dataCropper = {
          original: urlImage,
          name: file.name,
          url: urlImage,
          cropped: ''
        }
      } else {
        this.$message.error('File tidak sesuai, harap masukan .png atau .jpg!')
        this.showUpload = true
        this.$refs.uploadFiles.uploadFiles.pop()
        this.formPayment.medias.pop()
      }
    },
    handleRemove () {
      this.showUpload = true
      this.$refs.uploadFiles.uploadFiles.pop()
      this.formPayment.medias.pop()
      if (!Object.keys(this.dataCropper).length) {
        this.$message({
          showClose: true,
          message: 'Gambar berhasil dihapus dari list',
          type: 'success'
        })
      }
    },
    handlePictureCardPreview () {
      this.dialogImageUrl = this.formPayment.medias[0].cropped
      this.dialogVisible = true
    },
    handleError (err) {
      this.showUpload = true
      this.$refs.uploadFiles.uploadFiles.pop()
      const errorMessage = err ? err.message : err
      this.$Swal.fire({
        icon: 'error',
        title: 'Gagal upload gambar',
        text: errorMessage
      })
    },
    beforeUpload (file) {
      const isImage = file.type.substr(0, 5) === 'image'
      const isLimit5M = file.size / 1024 / 1024 < 4
      if (!isImage) {
        this.$message.error('File tidak sesuai, harap masukan .png atau .jpg!')
        this.$refs.uploadFiles.uploadFiles =
          this.$refs.uploadFiles.uploadFiles.pop()
      }
      if (!isLimit5M) {
        this.$message.error('Ukuran file tidak boleh lebih dari 3Mb!')
        this.$refs.uploadFiles.uploadFiles =
          this.$refs.uploadFiles.uploadFiles.pop()
      }
      return isImage && isLimit5M
    },
    handleLimit () {
      this.$message.error('Upload maksimal 1 gambar!')
    },
    async handleSuccess (response) {
      if (response.status_code === 201) {
        this.formPayment.medias = [
          {
            name: 'proof',
            type: 'image',
            value: response.data.files[0].filename
          }
        ]
        try {
          if (this.isEDC) {
            this.formPayment = Object.assign({
              medias: this.formPayment.medias,
              total_paid: this.order.total_payment
            })
          }
          const resp = await this.postPaymentConfirmation({
            id: this.$route.params.order_id,
            payload: { data: this.formPayment }
          })
          if (resp.status === 200) {
            this.$Swal.fire({
              icon: 'success',
              title: 'Berhasil upload bukti pembayaran'
            })

            window.location.href = '/account/shop/my-order/detail/' + this.$route.params.order_id
          }
        } catch (error) {
          console.log(error)
        }
      }
    },
    openCropper () {
      this.$refs.showModalCrop.click()
    },
    handleCropped (value) {
      this.formPayment.medias = []
      this.formPayment.medias.push(value)
      this.formPayment.proof = value
      this.dataCropper = {}
    },
    submitForm (formName) {
      if (!this.onSubmit) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            this.onSubmit = true
            const _file = await this.compressImage(
              this.dataURLtoFile(
                this.formPayment.medias[0].cropped,
                this.formPayment.medias[0].name
              )
            )
            if (_file.status === 200) {
              const formData = new FormData()
              formData.append(
                'file[]',
                new File([_file.file], _file.file.name, {
                  lastModified: _file.file.lastModified
                })
              )
              const response = await this.uploadFiles(formData)
              if (response.status === 201) {
                this.formPayment.medias = [
                  {
                    name: 'proof',
                    type: 'image',
                    value: response.data.data.files[0].filename
                  }
                ]
                try {
                  if (this.isEDC) {
                    this.formPayment = Object.assign({
                      medias: this.formPayment.medias,
                      total_paid: this.order.total_payment
                    })
                  }
                  await this.postPaymentConfirmation({
                    id: this.$route.params.order_id,
                    payload: { data: this.formPayment }
                  })

                  this.$message({
                    ...defaultToastOptions(),
                    message: 'Berhasil melakukan pembayaran',
                    type: toastTypes.success.value
                  })

                  setTimeout(() => {
                    window.location.href = '/account/shop/my-order/detail/' +
                        this.$route.params.order_id
                  }, 2000)
                } catch (error) {
                  console.log(error)
                  const errorMessage = getResponseErrorMessage(error)
                  this.$message({
                    ...defaultToastOptions(),
                    message: errorMessage,
                    type: toastTypes.error.value
                  })
                }
              }
            } else {
              this.$message.error('Gambar tidak sesuai dengan ketentuan!')
            }
          } else {
            this.$message.error('Lengkapi data dengan benar!')
            return false
          }
        })
      }
    }
  }
}
