import { render, staticRenderFns } from "./ProductsSideFilter.vue?vue&type=template&id=18b59720&scoped=true&"
import script from "./ProductsSideFilter.vue?vue&type=script&lang=js&"
export * from "./ProductsSideFilter.vue?vue&type=script&lang=js&"
import style0 from "./ProductsSideFilter.vue?vue&type=style&index=0&id=18b59720&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18b59720",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Clamp: require('/usr/src/app/components/base/Clamp.vue').default})
