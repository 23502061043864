//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
export default {
  data () {
    return {
      isLoading: true,
      data: []
    }
  },
  created () {
    this.initComponent()
  },
  methods: {
    ...mapActions('Contents', ['getPages']),
    async initComponent () {
      try {
        const resp = await this.getPages('how-to-pay')
        if (resp.status === 200) {
          this.data = resp.data.data.page.contents
        }
        this.isLoading = false
      } catch (error) {
        console.log(error)
        this.isLoading = false
      }
    }
  }
}
