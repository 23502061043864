import { render, staticRenderFns } from "./PanintiExclusiveSection.vue?vue&type=template&id=5cf799e2&scoped=true&"
import script from "./PanintiExclusiveSection.vue?vue&type=script&lang=js&"
export * from "./PanintiExclusiveSection.vue?vue&type=script&lang=js&"
import style0 from "./PanintiExclusiveSection.vue?vue&type=style&index=0&id=5cf799e2&lang=scss&scoped=true&"
import style1 from "./PanintiExclusiveSection.vue?vue&type=style&index=1&id=5cf799e2&lang=scss&scoped=true&"
import style2 from "./PanintiExclusiveSection.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cf799e2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BannerPromotionExclusive: require('/usr/src/app/components/home/BannerPromotionExclusive.vue').default})
