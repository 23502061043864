var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.partner.banners.length > 0)?_c('div',{staticClass:"partner-profile-body-tab-homepage"},[_c('LazyComponent',[_c('PartnerProfileHomepageSliderBanner',{staticClass:"container-section",attrs:{"banners":_vm.partner.banners,"file-name":_vm.partner.type === _vm.storeTypes.brandPartner.value
          ? _vm.bannerFileNames.brandProfile
          : _vm.bannerFileNames.storeProfile}})],1),_vm._v(" "),(_vm.isContentVisible(_vm.partnerVouchers))?_c('LazyComponent',{staticClass:"container-section"},[_c('PartnerProfileHomepageVouchers',{attrs:{"vouchers":_vm.partnerVouchers},on:{"mounted":function($event){return _vm.handleHomepageSectionMounted(_vm.partnerProfileHomepageSections.vouchers.name)}}})],1):_vm._e(),_vm._v(" "),(_vm.partnerFlashSales.isFlashsaleNotEmpty)?_c('LazyComponent',[_c('PartnerProfileHomepageFlashsale',{on:{"flashsale-loaded":_vm.handleFlashsaleSectionSectionLoaded,"see-all-flashsale-on-click":_vm.handleSeeAllFlashsaleOnClick}})],1):_vm._e(),_vm._v(" "),(_vm.isContentVisible(_vm.partnerLastSeenProducts))?_c('LazyComponent',{staticClass:"container-section"},[_c('ListProductsOverview',{attrs:{"section-data":{
        title: 'Terakhir Dilihat',
        showSeeAllProduct: false,
        isSlideProducts: true
      },"products":_vm.partnerLastSeenProducts},on:{"mounted":function($event){return _vm.handleHomepageSectionMounted(_vm.partnerProfileHomepageSections.lastSeenProducts.name)}}})],1):_vm._e(),_vm._v(" "),(_vm.isContentVisible(_vm.partnerProfileHomepageShowcases))?_c('LazyComponent',{staticClass:"container-section"},[_c('PartnerProfileHomepageShowcases',{attrs:{"partner-show-cases":_vm.partnerProfileHomepageShowcases}})],1):_vm._e(),_vm._v(" "),(_vm.isContentVisible(_vm.partnerBestSellerProducts))?_c('LazyComponent',{staticClass:"container-section"},[_c('ListProductsOverview',{attrs:{"section-data":{
        title: ("Terlaris Di " + (_vm.partner.type === _vm.storeTypes.brandPartner.value ? 'Brand' : 'Toko')),
        showSeeAllProduct: false,
        isSlideProducts: true
      },"products":_vm.partnerBestSellerProducts},on:{"mounted":function($event){return _vm.handleHomepageSectionMounted(_vm.partnerProfileHomepageSections.bestSellerProducts.name)}}})],1):_vm._e(),_vm._v(" "),(_vm.partner.lorikeets.length > 0)?_c('LazyComponent',{staticClass:"container-section"},[_c('PartnerProfileHomepageLorikeet',{attrs:{"lorikeets":_vm.partner.lorikeets || []}})],1):_vm._e(),_vm._v(" "),(_vm.isContentVisible(_vm.partnerRecommendationProducts))?_c('LazyComponent',{staticClass:"container-section"},[_c('ListProductsOverview',{attrs:{"section-data":{
        title: "Rekomendasi Produk",
        showSeeAllProduct: true
      },"products":_vm.partnerRecommendationProducts},on:{"mounted":function($event){return _vm.handleHomepageSectionMounted(_vm.partnerProfileHomepageSections.recommendationProducts.name)},"see-all-product-on-click":_vm.handleSeeAllRecommendationProductsOnClick}})],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }