//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import _ from 'lodash';
import mixins from '@/mixins';

export default {
  mixins: [mixins],

  props: {
    eventSale: {
      type: Object,
      default: null
    },

    eventSaleSession: {
      type: Object,
      default: null
    },

    searchQuery: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      eventSaleProducts: this.setEventSaleProductsData(),
      windowWidth: process.client ? window.innerWidth : 0
    }
  },
  computed: {
    normalDesktop () {
      return this.windowWidth >= 992
    }
  },
  watch: {
    searchQuery: {
      handler () {
        this.debounceSearch()
      },
      immediate: true
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  methods: {
    ...mapActions('Contents', ['getEventSaleSections']),

    debounceSearch: _.debounce(async function () {
      if (this.searchQuery.trim() !== '') {
        Object.assign(this.eventSaleProducts, this.setEventSaleProductsData(), { isLoading: true })
        await this.getEventSaleSearchedProducts()
        if (
          Array.isArray(this.$refs.InfiniteLoading) &&
          this.$refs.InfiniteLoading.length > 0
        ) {
          this.$refs.InfiniteLoading[0].stateChanger.reset()
        } else if (this.$refs.InfiniteLoading) {
          this.$refs.InfiniteLoading.stateChanger.reset()
        } else {
          await this.getEventSaleSearchedProducts()
        }
      }
    }, 750),

    setEventSaleProductsData () {
      return {
        isLoading: true,
        page: 1,
        perPage: 10,
        totalPage: 0,
        total: 0,
        data: []
      }
    },

    handleInfiniteLoading () {
      this.getEventSaleSearchedProducts()
    },

    async getEventSaleSearchedProducts (search = '') {
      this.eventSaleProducts.isLoading = true

      try {
        const resp = await this.getEventSaleSections({
          data: {
            where: {
              event_sale_session_id: this.eventSaleSession ? this.eventSaleSession.id : null
            },
            search: search || this.searchQuery,
            page: this.eventSaleProducts.page,
            per_page: this.eventSaleProducts.perPage
          }
        })

        if (Array.isArray(resp.data.data.event_sale_sections) && resp.data.data.event_sale_sections.length > 0) {
          this.eventSaleProducts.totalPage = resp.data.data.event_sale_sections[0].event_sale_products.last_page
          this.eventSaleProducts.total = resp.data.data.event_sale_sections[0].event_sale_products.total
          this.eventSaleProducts.data = this.eventSaleProducts.data.concat(
            resp.data.data.event_sale_sections[0].event_sale_products.data
          )
        }

        this.eventSaleProducts.page++

        if (this.eventSaleProducts.page > this.eventSaleProducts.totalPage) {
          if (
            Array.isArray(this.$refs.InfiniteLoading) &&
            this.$refs.InfiniteLoading.length > 0
          ) {
            this.$refs.InfiniteLoading[0].stateChanger.complete()
          } else if (this.$refs.InfiniteLoading) {
            this.$refs.InfiniteLoading.stateChanger.complete()
          }
        } else if (
          Array.isArray(this.$refs.InfiniteLoading) &&
          this.$refs.InfiniteLoading.length > 0
        ) {
          this.$refs.InfiniteLoading[0].stateChanger.loaded()
        } else if (this.$refs.InfiniteLoading) {
          this.$refs.InfiniteLoading.stateChanger.loaded()
        }
      } catch (error) {
        this.$message.error('Gagal mendapatkan data event sale produk')
      }

      this.eventSaleProducts.isLoading = false
    }
  }
}
