//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import windowResizeMixin from '@/mixins/window-resize-mixin'

import { generateRandomString } from '@/utils/helpers/string-helper'

export default {
  mixins: [
    windowResizeMixin
  ],

  props: {
    searchResultPartners: {
      type: Object,
      required: true
    },

    searchResultProducts: {
      type: Object,
      required: true
    },

    sortFilterOptions: {
      type: Object,
      default: () => ({})
    },

    sideFilterModel: {
      type: Object,
      default: () => ({})
    },

    sideFilterData: {
      type: Object,
      default: () => ({})
    },

    sideFilterShowed: {
      type: Object,
      default: () => ({})
    }
  },

  methods: {
    generateRandomString,

    handleSelectFilterSortProductsSortOnChange (value) {
      this.$emit('select-filter-sort-products-on-change', value)
    },

    handleSeeAllPartnersOnClick () {
      this.$router.push({
        path: '/partner',
        query: {
          search: this.$route.query.search,
          section: 'all-partner'
        }
      })
    },

    handleInfiniteLoading ($state) {
      this.$emit('infinite-loading', $state)
    },

    completeInfiniteLoading () {
      if (this.$refs.infiniteLoading) {
        this.$refs.infiniteLoading.stateChanger.complete()
      }
    },

    resetInfiniteLoading () {
      if (this.$refs.infiniteLoading) {
        this.$refs.infiniteLoading.stateChanger.reset()
      }
    },

    // START - Products Filter Mobile
    openProductsFilterMobileDrawer () {
      if (this.$refs.productsFilterMobile) {
        this.$refs.productsFilterMobile.openProductsFilterMobileDrawer()
      }
    },

    closeProductsFilterMobileDrawer () {
      if (this.$refs.productsFilterMobile) {
        this.$refs.productsFilterMobile.closeProductsFilterMobileDrawer()
      }
    },

    handleProductsFilterMobileBtnOpenOnClick () {
      this.$emit('btn-products-filter-mobile-open-on-click')
    },

    handleProductsFilterMobileBtnResetOnClick () {
      this.$emit('btn-products-filter-drawer-reset-on-click')
    },

    handleProductsFilterMobileBtnApplyOnClick () {
      this.$emit('btn-products-filter-drawer-apply-on-click')
    }
    // END - Products Filter Mobile
  }
}
