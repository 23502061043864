//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    type: {
      type: String,
      default: 'empty'
    }
  },
  computed: {
    titleText () {
      return this.type === 'notFound' ? 'Tidak ada ulasan!' : 'Belum ada ulasan!';
    },
    subtitleText () {
      return this.type === 'notFound'
        ? 'Hasil pencarian ulasan kamu tidak ada'
        : 'Tulis ulasanmu dari setiap pesanan yang sudah selesai dan dapatkan Paninti Poin!';
    }
  },
  methods: {
    image () {
      return require('@/static/img/error/empty-review.svg');
    }
  }
}
