//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex'
import { isObjectNotEmptyOrNull } from '@/utils/helpers/object-helper'
import { isArrayNotNullOrEmpty } from '@/utils/helpers/array-helper'
import { toastTypes, defaultToastOptions } from '@/utils/helpers/toast-helper'
import { getResponseErrorMessage } from '@/utils/helpers/response-helper'
import { checkoutMethods } from '@/utils/helpers/checkout-helper'
import orderModuleMixins from '@/mixins/order-module-mixins'
export default {
  mixins: [orderModuleMixins],
  data () {
    return {
      unavailableDefaultText: {
        unavailable_carts: 'tidak dapat dikirim sama sekali',
        unavailable_carts_with_branch: 'tidak tersedia di cabang yang dipilih',
        unavailable_carts_with_pickup: 'tidak dapat diambil di toko',
        unavailable_carts_with_shipping: 'tidak dapat dikirim',
        unavailable_carts_with_stock: 'tidak memenuhi stok'
      }
    }
  },
  computed: {
    ...mapState('Checkout', ['checkoutMethod']),
    ...mapGetters('Checkout', ['unavailableCarts']),
    onlyUnavailableCartsWithValue () {
      return Object.keys(this.unavailableCarts)
      .filter(key => this.unavailableCarts[key].length > 0)
      .reduce((obj, key) => {
        obj[key] = this.unavailableCarts[key];
        return obj;
      }, {})
    },
    unavailableCartsWithoutStock () {
      return Object.keys(this.unavailableCarts)
        .filter(key => key !== 'unavailable_carts_with_stock') // Exclude this key
        .reduce((obj, key) => {
          obj[key] = this.unavailableCarts[key];
          return obj;
        }, {})
    },
    mergedUnavailableCartsWithoutStock () {
      return Object.values(this.unavailableCartsWithoutStock).flat()
    },
    checkoutMethods () {
      return checkoutMethods
    }
  },
  methods: {
    isObjectNotEmptyOrNull,
    isArrayNotNullOrEmpty,
    ...mapActions('Cart', ['getCarts', 'getCartsNavbar', 'updateCart', 'selectedItem', 'deleteCart', 'getCartSummary']),
    async handleUpdateCart (idProduct, currentQuantity, newQuantity) {
      const payload = {
        id: idProduct,
        data: {
          quantity: newQuantity,
          action: 'decrement',
          session_id: this.$cookies.get('session_id')
        }
      }

      try {
        await this.updateCart(payload)
        this.$message({
          ...defaultToastOptions(),
          message: 'Berhasil mengubah stok produk',
          type: toastTypes.success.value
        })
        await this.checkAvaibilityCheckout()
      } catch (error) {
        console.log(error)
        const msg = getResponseErrorMessage(error)
        this.$message({
          ...defaultToastOptions(),
          message: msg,
          type: toastTypes.error.value
        })
      }
    },
    async removeFromCheckout (cartId) {
      this.setSelectedItem({
        cartId
      })

      if (this.selectItem.length === 0) {
        localStorage.removeItem('cartItem')
        window.location.href = '/cart'

        return
      } else {
        localStorage.setItem('cartItem', JSON.stringify(this.selectItem))
      }

      await this.checkAvaibilityCheckout(false)
    }
  }
}
