//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { voucherReductionTypes, voucherTypes } from '@/utils/helpers/voucher-helper'
import { formatRupiah } from '@/utils/helpers/string-helper'
import { isObjectNotEmptyOrNull } from '@/utils/helpers/object-helper'
import { defaultImages, getDefaultImageFromFiles, getDefaultImgOnError } from '@/utils/helpers/file-helper'
import { areDatesSame, formatDateWithTimezone } from '@/utils/helpers/date-time-helper'
import { defaultToastOptions, toastTypes } from '@/utils/helpers/toast-helper'

export default {
  props: {
    voucherableNotFromPaninti: {
      type: Object,
      default: () => ({})
    },

    voucher: {
      type: Object,
      required: true,
      default: () => ({
        type: '',
        reduction_type: '',
        date_start: '',
        date_end: ''
      })
    }
  },

  data () {
    return {
      appliedForClamp: false
    }
  },

  computed: {
    defaultImages () {
      return defaultImages
    },
    selectedVoucherType () {
      return Object.values(voucherTypes).find(
        voucherType => voucherType.name === this.voucher.type
      )
    },

    voucherBodyInfoAdditionalClass () {
      switch (this.voucher.type) {
        case voucherTypes.discount.name:
          return 'voucher-discount'
        case voucherTypes.freeShippingCost.name:
          return 'voucher-shipping'
        default:
          return 'voucher-discount'
      }
    },

    voucherIcon () {
      switch (this.voucher.type) {
        case voucherTypes.discount.name:
          return '/img/icon/account/voucher/icon-voucher-disc.svg'
        case voucherTypes.freeShippingCost.name:
          return '/img/icon/account/voucher/icon-voucher-shipping.svg'
        default:
          return '/img/icon/account/voucher/icon-voucher-disc.svg'
      }
    },

    voucherLabel () {
      if (!this.selectedVoucherType) { return voucherTypes.discount.cardLabel }

      return this.selectedVoucherType.cardLabel
    },

    voucherReductionValue () {
      switch (this.voucher.reduction_type) {
        case voucherReductionTypes.amount.name:
          return formatRupiah(this.voucher.reduction_value || '', 'Rp')
        case voucherReductionTypes.percent.name:
          return (this.voucher.reduction_value || '0') + '%'
        default:
          return this.voucher.reduction_value || ''
      }
    },

    voucherPeriod () {
      if (!this.voucher || !this.voucher.date_start || !this.voucher.date_end) {
        return '-'
      }

      let formattedDateStart = ''
      const formattedDateEnd = formatDateWithTimezone(this.voucher.date_end, {
        formatResult: 'DD MMM YYYY'
      })

      const isDateInSameMonth = areDatesSame([this.voucher.date_start, this.voucher.date_end], 'month')
      const isDateInSameYear = areDatesSame([this.voucher.date_start, this.voucher.date_end], 'year')

      if (isDateInSameMonth) {
        formattedDateStart = formatDateWithTimezone(this.voucher.date_start, {
          formatResult: 'DD'
        })
      } else if (isDateInSameYear) {
        formattedDateStart = formatDateWithTimezone(this.voucher.date_start, {
          formatResult: 'DD MMM'
        })
      } else {
        formattedDateStart = formatDateWithTimezone(this.voucher.date_start, {
          formatResult: 'DD MMM YYYY'
        })
      }

      return [formattedDateStart, formattedDateEnd].join(' - ')
    },

    termAndConditions () {
      return this.voucher.term_and_conditions || this.voucher.term_and_condition
    }
  },

  methods: {
    formatRupiah,
    getDefaultImgOnError,
    getDefaultImageFromFiles,
    isObjectNotEmptyOrNull,

    showToastCopyVoucherCodeSuccess () {
      this.$message({
        ...defaultToastOptions(),
        message: 'Kode voucher berhasil disalin ke clipboard',
        type: toastTypes.success.value
      })
    }
  }
}
