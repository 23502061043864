//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import orderModuleMixins from '@/mixins/order-module-mixins'
import { mapActions, mapMutations, mapState } from 'vuex'
import { defaultSwal2Options } from '@/utils/helpers/swal2-helper'
import { toastTypes, defaultToastOptions } from '@/utils/helpers/toast-helper'
import { isObjectNotEmptyOrNull } from '@/utils/helpers/object-helper'
export default {
  mixins: [mixins, orderModuleMixins],
  props: {
    purpose: {
      type: String,
      default: 'create-order'
    }
  },
  data () {
    return {
      isSubmit: false,
      cardMetaData: {}
    }
  },
  computed: {
    ...mapState('User', ['link3DS', 'dataAccount']),
    ...mapState('Checkout', ['orderPayload']),
    user () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user
      } else {
        return []
      }
    }
  },
  mounted () {
    this.isSubmit = false
  },
  methods: {
    ...mapMutations('User', ['setDataAccount']),
    ...mapMutations('Checkout', ['setOrderPayload']),
    ...mapActions('User', ['createPayment', 'getPayment']),
    ...mapActions('Order', ['addOrder']),
    handleLoad () {
      const _this = this
      if (this.link3DS) {
        const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent'
        const eventer = window[eventMethod]
        const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message'
        const handleCreateData = this.handleCreateData
        // Listen to message from child window
        eventer(messageEvent, function (e) {
          const key = e.message ? 'message' : 'data'
          const data = e[key]
          if (data && typeof data === 'string') {
            const resp = JSON.parse(data)
            if (resp.status === 'VERIFIED') {
              _this.setDataAccount({
                ..._this.dataAccount,
                card_authentication_id: resp.authentication_id
              })

              if (_this.checkObject(_this.selectedPaymentAccount)) {
                const paymentAccountPayload = {
                  ..._this.selectedPaymentAccount
                }

                paymentAccountPayload.value = Object.assign(paymentAccountPayload.value, {
                  card_authentication_id: resp.authentication_id,
                  card_meta_data: resp.card_info
                })
              }

              _this.cardMetaData = resp.card_info
              handleCreateData()
            }
          }
        }, false)
      }
    },
    async handleCreateData () {
      if (!this.isSubmit) {
        this.isSubmit = true
        if (isObjectNotEmptyOrNull(this.selectedPaymentAccount) && this.purpose === 'create-order') {
          this.$refs.buttonShowLoading.click()
          // this.handleSubmit()
          this.$emit('handle-create-order')
        } else if (this.purpose === 'create-cc') {
          try {
            const resp = await this.createPayment({
              data: {
                name: '',
                owner_name: this.dataAccount.card_holder,
                value: this.dataAccount,
                card_meta_data: this.cardMetaData,
                type: 'Credit Card',
                active: true,
                main: true
              }
            })
            if (resp.status === 201) {
              this.$message({
                ...defaultToastOptions(),
                message: 'Kartu kredit/debit berhasil ditambahkan',
                type: toastTypes.success.value
              })
              this.isSubmit = false
              this.$refs.popup3DS.closeModal()

              this.getDataAccounts()

              this.$emit('create-cc-success')
            }
          } catch (error) {
            this.isSubmit = false
            this.$message({
              ...defaultToastOptions(),
              message: error.response.data.message,
              type: toastTypes.error.value,
              showClose: true
            })
            this.$refs.modal3DS.$refs.buttonClose.click()
            console.log(error)
          }
        }
      }
    },
    async handleSubmit () {
      try {
        const resp = await this.addOrder(this.orderPayload)
        if (resp.status === 201) {
          this.$refs.buttonShowLoading.click()
        }
      } catch (error) {
        this.$refs.buttonShowLoading.click()
        this.$Swal
          .fire({
            ...defaultSwal2Options(),
            icon: 'error',
            title: 'Tidak dapat memproses pesanan',
            text: error.response.data.message,
            heightAuto: false,
            confirmButtonText: 'Kembali'
          })
          .then((result) => {
            if (result.isConfirmed) {
              window.location = '/cart'
            }
          })
      }
    },
    async getDataAccounts () {
      try {
        await this.getPayment({
          data: {
            paginated: false,
            per_page: 10,
            page: 1,
            where: {
              type: 'Credit Card'
            }
          }
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
