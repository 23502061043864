import { render, staticRenderFns } from "./PartnerProfileBodyTabHomepage.vue?vue&type=template&id=7f527abb&scoped=true&"
import script from "./PartnerProfileBodyTabHomepage.vue?vue&type=script&lang=js&"
export * from "./PartnerProfileBodyTabHomepage.vue?vue&type=script&lang=js&"
import style0 from "./PartnerProfileBodyTabHomepage.vue?vue&type=style&index=0&id=7f527abb&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f527abb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PartnerProfileHomepageSliderBanner: require('/usr/src/app/components/home/partner/PartnerProfileHomepageSliderBanner.vue').default,PartnerProfileHomepageVouchers: require('/usr/src/app/components/home/partner/PartnerProfileHomepageVouchers.vue').default,PartnerProfileHomepageFlashsale: require('/usr/src/app/components/home/partner/PartnerProfileHomepageFlashsale.vue').default,ListProductsOverview: require('/usr/src/app/components/base/product/ListProductsOverview.vue').default,PartnerProfileHomepageShowcases: require('/usr/src/app/components/home/partner/PartnerProfileHomepageShowcases.vue').default,PartnerProfileHomepageLorikeet: require('/usr/src/app/components/home/partner/PartnerProfileHomepageLorikeet.vue').default})
