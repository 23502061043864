import { render, staticRenderFns } from "./CardPartnerProfileHeader.vue?vue&type=template&id=75ed8296&scoped=true&"
import script from "./CardPartnerProfileHeader.vue?vue&type=script&lang=js&"
export * from "./CardPartnerProfileHeader.vue?vue&type=script&lang=js&"
import style0 from "./CardPartnerProfileHeader.vue?vue&type=style&index=0&id=75ed8296&scoped=true&lang=scss&"
import style1 from "./CardPartnerProfileHeader.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75ed8296",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PartnerTypeBadge: require('/usr/src/app/components/base/partner/PartnerTypeBadge.vue').default,IconPinLocation: require('/usr/src/app/components/icons/IconPinLocation.vue').default,DividerVertical: require('/usr/src/app/components/base/DividerVertical.vue').default,RatingPartner: require('/usr/src/app/components/base/rating/RatingPartner.vue').default,IconChevronRight: require('/usr/src/app/components/icons/IconChevronRight.vue').default,Button: require('/usr/src/app/components/base/Button.vue').default,IconShareAndroid: require('/usr/src/app/components/icons/IconShareAndroid.vue').default,IconSocialWhatsapp: require('/usr/src/app/components/icons/IconSocialWhatsapp.vue').default,IconSocialInstagram: require('/usr/src/app/components/icons/IconSocialInstagram.vue').default,IconSocialTiktok: require('/usr/src/app/components/icons/IconSocialTiktok.vue').default,IconSocialTwitterX: require('/usr/src/app/components/icons/IconSocialTwitterX.vue').default,IconSocialFacebook: require('/usr/src/app/components/icons/IconSocialFacebook.vue').default,IconSocialYoutube: require('/usr/src/app/components/icons/IconSocialYoutube.vue').default,ModalPartnerRelatedData: require('/usr/src/app/components/base/partner/ModalPartnerRelatedData.vue').default,ModalSharePartner: require('/usr/src/app/components/base/partner/ModalSharePartner.vue').default,CardBase: require('/usr/src/app/components/base/CardBase.vue').default})
