//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AbortController from 'abort-controller'
import { mapActions, mapState } from 'vuex'

import windowResizeMixin from '@/mixins/window-resize-mixin'

import {
  assignResponseToDataModelData,
  assignResponseToDataModelDataNotPaginated,
  defaultNotPaginationAttributes, defaultPaginationAttributes, resetPaginationData
} from '@/utils/helpers/pagination-helper'
import { filterNonNullValues } from '@/utils/helpers/object-helper'

export default {
  mixins: [
    windowResizeMixin
  ],

  data () {
    const searchResultProductsSortFilterOptions = {
      bestSeller: {
        label: 'Terlaris',
        value: 'best_seller'
      },
      newest: {
        label: 'Terbaru',
        value: 'newest'
      },
      mostExpensive: {
        label: 'Termahal',
        value: 'most_expensive'
      },
      cheapest: {
        label: 'Termurah',
        value: 'cheapest'
      }
    }

    return {
      breadCrumb: [
        {
          path: '/'
        }
      ],

      // START - Side Filter
      sideFilterData: {
        ...defaultNotPaginationAttributes()
      },
      sideFilterModel: {
        activeCategoryTreeItems: [],
        activeCollapseNames: [
          'filter-category',
          'filter-brand',
          'filter-location',
          'filter-price',
          'filter-rating'
        ],
        brand: [],
        location: [],
        price: [
          0, 10000000
        ],
        rating: []
      },
      sideFilterShowed: {
        category: true,
        brand: true,
        location: true,
        price: true,
        rating: true
      },
      // END - Side Filter

      // START - Search Result Products
      searchResultProductsSortFilterOptions,
      searchResultPartners: {
        ...defaultPaginationAttributes(),
        perPage: 1,
        filter: {
          search: ''
        },
        controller: null
      },
      searchResultProducts: {
        ...defaultPaginationAttributes(),
        perPage: 12,
        filter: {
          search: '',
          sort: searchResultProductsSortFilterOptions.newest.value
        },
        controller: null
      }
      // END - Search Result Products
    }
  },

  computed: {
    ...mapState('User/address', ['location']),

    paramSection () {
      return this.$route.params.section
    },

    querySearch () {
      return this.$route.query.search
    }
  },

  watch: {
    '$route.query': {
      async handler (newValue, oldValue) {
        if (newValue) {
          this.populateRouteQueryToSearchResultProducts(newValue)
        }

        const asyncMethods = []

        if (newValue.search !== oldValue.search) {
          this.resetSearchResultPartners()
          asyncMethods.concat([
            this.getPartnersData(true),
            this.getProductsSideFilterData()
          ])
        }

        this.scrollToTop()

        this.resetSearchResultProducts()

        await Promise.all(
          asyncMethods
            .concat(this.getProductsData(true))
        )

        this.$refs.searchResultProducts.resetInfiniteLoading()
      },
      deep: true
    },

    location: {
      async handler (newValue, oldValue) {
        if (newValue && oldValue.city_id) {
          this.scrollToTop()

          this.resetSearchResultProducts()
          await this.getProductsData(true)
          this.$refs.searchResultProducts.resetInfiniteLoading()
        }
      },
      deep: true
    }
  },

  mounted () {
    this.populateRouteQueryToSearchResultProducts(this.$route.query)

    this.getAndSetPageData()
  },

  methods: {
    ...mapActions('Product/ProductsSearchResult', [
      'getProductsSideFilter', 'getPartners', 'getProducts'
    ]),

    // START - Page Data
    async getAndSetPageData () {
      await this.getProductsSideFilterData()

      await Promise.all([
        this.getPartnersData(),
        this.getProductsData()
      ])
    },
    // END - Page Data

    // START - Side Filter
    async getProductsSideFilterData () {
      try {
        this.sideFilterData.loading = true

        const response = await this.getProductsSideFilter({
          params: filterNonNullValues({
            search: this.searchResultProducts.filter.search || null
          })
        })

        assignResponseToDataModelDataNotPaginated(this.sideFilterData, response.data.data)
      } catch (error) {
        console.log(error)
      } finally {
        this.sideFilterData.loading = false
      }
    },

    handleProductsSideFilterCategoryTreeNodeOnClick (data) {
      const searchResultProductsCategories = this.$route.query.searchResultProductsCategories
      if (!searchResultProductsCategories || !searchResultProductsCategories.includes(data.slug)) {
        this.sideFilterModel.activeCategoryTreeItems = [data.slug]

        this.pushFilterIntoRouteQuery({
          searchResultProductsCategories: data.slug || null
        })
        return
      }

      this.sideFilterModel.activeCategoryTreeItems = []
      this.pushFilterIntoRouteQuery({
        searchResultProductsCategories: null
      })
    },

    handleProductsSideFilterBrandCheckboxGroupOnChange (data) {
      this.pushFilterIntoRouteQuery({
        searchResultProductsBrands: data.length > 0 ? data.join(',') : null
      })
    },

    handleProductsSideFilterLocationCheckboxGroupOnChange (data) {
      this.pushFilterIntoRouteQuery({
        searchResultProductsLocations: data.length > 0 ? data.join(',') : null
      })
    },

    handleProductsSideFilterPriceSliderOnChange (data) {
      this.$refs.searchResultProducts.completeInfiniteLoading()
      this.pushFilterIntoRouteQuery({
        searchResultProductsPriceRange: data.length > 0 ? data.join(',') : null
      })
    },

    handleProductsSideFilterRatingCheckboxGroupOnChange (data) {
      this.$refs.searchResultProducts.completeInfiniteLoading()
      this.pushFilterIntoRouteQuery({
        searchResultProductsRating: data.length > 0 ? data.join(',') : null
      })
    },

    handleBtnResetProductsSideFilterOnClick () {
      this.$router.push({
        query: filterNonNullValues({
          ...this.$route.query,
          searchResultProductsCategories: null,
          searchResultProductsBrands: null,
          searchResultProductsLocations: null,
          searchResultProductsPriceRange: null,
          searchResultProductsRating: null
        })
      })
    },
    // END - Side Filter

    // START - Search Result Products
    async getPartnersData (resetData = false) {
      if (this.searchResultPartners.controller) {
        this.searchResultPartners.controller.abort()
      }
      this.searchResultPartners.controller = new AbortController()

      try {
        this.searchResultPartners.loading = true

        const response = await this.getPartners({
          data: {
            paginated: this.searchResultPartners.paginated,
            per_page: this.searchResultPartners.perPage,
            page: this.searchResultPartners.page + 1,
            search: this.searchResultProducts.filter.search || null
          },
          signal: this.searchResultPartners.controller.signal
        })

        assignResponseToDataModelData(
          this.searchResultPartners,
          response.data.data.stores,
          resetData
        )
      } catch (error) {
        console.log(error)
      } finally {
        this.searchResultPartners.loading = false
      }
    },

    async getProductsData (resetData = false) {
      if (this.searchResultProducts.controller) {
        this.searchResultProducts.controller.abort()
      }
      this.searchResultProducts.controller = new AbortController()

      try {
        this.searchResultProducts.loading = true

        // Payload
        const smallestRating = this.sideFilterModel.rating.length > 0
          ? Math.min(...this.sideFilterModel.rating)
          : null

        const getPayloadData = {
          paginated: this.searchResultProducts.paginated,
          per_page: this.searchResultProducts.perPage,
          page: this.searchResultProducts.page + 1,
          city_id: this.location ? this.location.city_id : null,
          search: this.searchResultProducts.filter.search || null,
          category_slug: this.sideFilterModel.activeCategoryTreeItems.length > 0
            ? this.sideFilterModel.activeCategoryTreeItems
            : null,
          city: this.sideFilterModel.location.length > 0
            ? this.sideFilterModel.location
            : null,
          where_rating: smallestRating
            ? Array.from({ length: 6 - smallestRating }, (_, i) => (smallestRating + i).toString())
            : null
        }

        // Filter Brand
        if (this.sideFilterModel.brand.length > 0) {
          Object.assign(getPayloadData, {
            where_has: [
              {
                relation: 'brand',
                where_in: [
                  ['brands.slug', this.sideFilterModel.brand]
                ]
              }
            ]
          })
        }

        // Filter Sort
        switch (this.searchResultProducts.filter.sort) {
          case this.searchResultProductsSortFilterOptions.bestSeller.value:
            Object.assign(getPayloadData, {
              order_by: {
                total_sold_last_month: 'desc'
              }
            })
            break
          case this.searchResultProductsSortFilterOptions.newest.value:
            Object.assign(getPayloadData, {
              order_by: {
                'products.created_at': 'desc'
              }
            })
            break
          case this.searchResultProductsSortFilterOptions.mostExpensive.value:
            Object.assign(getPayloadData, {
              order_by: {
                price: 'desc'
              }
            })
            break
          case this.searchResultProductsSortFilterOptions.cheapest.value:
            Object.assign(getPayloadData, {
              order_by: {
                price: 'asc'
              }
            })
            break
        }

        // Filter Price Range
        if (this.sideFilterModel.price.length === 2) {
          if (
            this.sideFilterModel.price[0] !== this.sideFilterData.data.price.from ||
            this.sideFilterModel.price[1] !== this.sideFilterData.data.price.until
          ) {
            Object.assign(getPayloadData, {
              where_greater_than: {
                price: this.sideFilterModel.price[0]
              },
              where_lower_than: {
                price: this.sideFilterModel.price[1]
              }
            })
          }
        }

        const response = await this.getProducts({
          section: this.paramSection || null,
          data: filterNonNullValues(getPayloadData),
          signal: this.searchResultProducts.controller.signal
        })

        assignResponseToDataModelData(
          this.searchResultProducts,
          response.data.data.products,
          resetData
        )
      } catch (error) {
        console.log(error)
        this.$refs.searchResultProducts.completeInfiniteLoading()
      } finally {
        this.searchResultProducts.loading = false
      }
    },

    handleProductsFilterMobileBtnOpenOnClick () {
      this.populateRouteQueryToSearchResultProducts(
        this.$route.query
      )

      this.$refs.searchResultProducts.openProductsFilterMobileDrawer()
    },

    handleProductsFilterMobileBtnResetOnClick () {
      this.$router.push({
        query: filterNonNullValues({
          ...this.$route.query,
          searchResultProductsCategories: null,
          searchResultProductsBrands: null,
          searchResultProductsLocations: null,
          searchResultProductsPriceRange: null,
          searchResultProductsRating: null
        })
      })

      this.$refs.searchResultProducts.closeProductsFilterMobileDrawer()
    },

    handleProductsFilterMobileBtnApplyOnClick () {
      this.$router.push({
        query: filterNonNullValues({
          ...this.$route.query,
          searchResultProductsCategories: this.sideFilterModel.activeCategoryTreeItems.length > 0
            ? this.sideFilterModel.activeCategoryTreeItems.join(',')
            : null,
          searchResultProductsBrands: this.sideFilterModel.brand.length > 0
            ? this.sideFilterModel.brand.join(',')
            : null,
          searchResultProductsLocations: this.sideFilterModel.location.length > 0
            ? this.sideFilterModel.location.join(',')
            : null,
          searchResultProductsPriceRange: this.sideFilterModel.price.length > 0
            ? this.sideFilterModel.price.join(',')
            : null,
          searchResultProductsRating: this.sideFilterModel.rating.length > 0
            ? this.sideFilterModel.rating.join(',')
            : null
        })
      })

      this.$refs.searchResultProducts.closeProductsFilterMobileDrawer()
    },

    handleSelectFilterSortProductsSortOnChange () {
      this.$refs.searchResultProducts.completeInfiniteLoading()
      this.pushFilterIntoRouteQuery({
        searchResultProductsSort: this.searchResultProducts.filter.sort
      })
    },

    async handleSearchResultProductInfiniteLoading ($state) {
      if (this.searchResultProducts.page >= this.searchResultProducts.totalPage) {
        $state.complete()
        return
      }

      await this.getProductsData()
      $state.loaded()
    },
    // END - Search Result Products

    // START - Others
    resetSearchResultPartners () {
      resetPaginationData(this.searchResultPartners, {
        perPage: this.searchResultPartners.perPage
      })
    },

    resetSearchResultProducts () {
      resetPaginationData(this.searchResultProducts, {
        perPage: this.searchResultProducts.perPage
      })
    },

    pushFilterIntoRouteQuery (query) {
      this.$router.push({
        query: filterNonNullValues({
          ...this.$route.query,
          ...query
        })
      })
    },

    populateRouteQueryToSearchResultProducts (queries = {}) {
      this.searchResultProducts.filter.search = queries.search || ''
      this.searchResultProducts.filter.sort = queries.searchResultProductsSort || this.searchResultProductsSortFilterOptions.newest.value

      this.sideFilterModel.activeCategoryTreeItems = queries.searchResultProductsCategories
        ? queries.searchResultProductsCategories.split(',')
        : []

      this.sideFilterModel.brand = queries.searchResultProductsBrands
        ? queries.searchResultProductsBrands.split(',')
        : []

      this.sideFilterModel.location = queries.searchResultProductsLocations
        ? queries.searchResultProductsLocations.split(',')
        : []

      this.sideFilterModel.price = queries.searchResultProductsPriceRange
        ? queries.searchResultProductsPriceRange.split(',')
        : [0, 10000000]

      this.sideFilterModel.rating = queries.searchResultProductsRating
        ? queries.searchResultProductsRating.split(',').map(rating => Number(rating)).filter(rating => rating)
        : []
    }
    // END - Others
  }
}
