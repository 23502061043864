import { render, staticRenderFns } from "./BannerPromotionExclusive.vue?vue&type=template&id=6e8e5e42&scoped=true&"
import script from "./BannerPromotionExclusive.vue?vue&type=script&lang=js&"
export * from "./BannerPromotionExclusive.vue?vue&type=script&lang=js&"
import style0 from "./BannerPromotionExclusive.vue?vue&type=style&index=0&id=6e8e5e42&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e8e5e42",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/usr/src/app/components/base/Button.vue').default,PopupDetailVoucher: require('/usr/src/app/components/home/cart/PopupDetailVoucher.vue').default})
