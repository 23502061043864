//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import orderMixins from '@/mixins/order-module-mixins'
import { formatRupiah } from '@/utils/helpers/string-helper'
import { isArrayNotNullOrEmpty } from '@/utils/helpers/array-helper'

export default {
  mixins: [orderMixins],
  data () {
    return {
      inputSearch: '',
      onSubmit: false,
      voucherSelected: [],
      isAlert: false,
      alert: {
        message: '',
        type: ''
      }
    }
  },
  computed: {
    ...mapState('Voucher', ['vouchers', 'voucherUsed', 'popupVoucherModal']),
    ...mapGetters('Voucher', ['totalVoucherUsed', 'groupedVoucherUsedData', 'ungroupedVoucherUsedData']),
    ...mapGetters('Cart', ['getGroupedSelectedItem']),
    ...mapGetters('Checkout', ['pickupAtStore']),
    totalVoucherSelected () {
      return this.totalVoucherUsed
    },
    listVouchers () {
      // show data voucher if vouchers[index].store_id === getGroupedSelectedItem[index].store_id
      const availableVoucher = this.vouchers.filter((voucher) => {
        return voucher.data && !!voucher.data.length
      })

      return availableVoucher || []
    },
    voucherExist () {
      return this.vouchers.some((voucher) => {
        return isArrayNotNullOrEmpty(voucher.data)
      })
    }
  },
  watch: {
    popupVoucherModal: {
      handler () {
        if (this.popupVoucherModal) {
          this.reapplyVoucher()
        }
      },
      immediate: true
    }
  },
  methods: {
    formatRupiah,
    ...mapActions('Voucher', ['checkAvailabilityVoucher', 'searchVoucher', 'getVouchersAvailable']),
    ...mapMutations('Voucher', ['setVoucherUsed', 'setVouchersAvailable', 'editVouchersAvailable', 'resetVoucherUsed', 'setPopupVoucherModal', 'pushOrUpdateVouchersAvailable']),
    ...mapMutations('Cart', ['setSelectedItem', 'setCartAvailability']),
    async fetchListVouchers (voucherData) {
      if (!voucherData.paginated) {
        this.editVouchersAvailable({
          storeId: voucherData.store_id,
          fields: [{
            name: 'seeMore',
            value: !voucherData.seeMore
          }]

        })

        if (voucherData.seeMore) {
          this.reapplyVoucher()
        }
        return
      }

      const selectedItemIdx = this.groupedSelectedItem.findIndex((grouped) => {
        return grouped.storeId === voucherData.store_id
      })

      if (selectedItemIdx === -1) {
        return
      }

      try {
        await this.getVouchersAvailable({
          store_id: voucherData.store_id,
          store_name: voucherData.store_name,
          payload: {
            data: {
              cart_ids: this.groupedSelectedItem[selectedItemIdx].items.map(item => item.cartId),
              pickup_at_store: false,
              only_available: true
            },
            params: {
              paginated: false,
              page: 1
            }
          }
        })
        this.editVouchersAvailable({
          storeId: voucherData.store_id,
          fields: [{
            name: 'seeMore',
            value: true
          }]
        })

        if (voucherData.seeMore) {
          this.reapplyVoucher()
        }
      } catch (error) {
        console.log(error)
      }
    },
    async handleClickSearch () {
      const payload = {
        pickup_at_store: this.pickupAtStore,
        voucher_code: this.inputSearch,
        voucher_used: this.groupedSelectedItem.map((item) => {
          return {
            cart_ids: item.items.map(cart => cart.cartId),
            voucher_id_used: []
          }
        })
      }
      try {
        const resp = await this.searchVoucher({ data: payload })
        if (resp.status === 200) {
          this.isAlert = true
          const vouchers = resp.data.data.voucher || []
          const isApplied = vouchers.find(voucher => voucher.is_applied)
          if (isApplied) {
            this.alert = {
              message: 'Kode Voucher Dapat Digunakan!',
              type: 'success'
            }
          } else {
            this.alert = {
              message: 'Kode Voucher Tidak Dapat Digunakan! Voucher tidak berlaku atau tidak ada. Periksa kembali kode voucher yang dimasukkan',
              type: 'error'
            }
          }
          if (vouchers.length) {
            // resetVoucherUsed when has is_applied true
            await vouchers.map((voucher) => {
              this.pushOrUpdateVouchersAvailable({
                storeId: voucher.store_id,
                voucherId: voucher.voucher.id,
                voucherData: voucher.voucher,
                voucherTotalDiscount: voucher.total_discount
              })

              if (voucher.is_applied) {
                this.setVoucherUsed({
                  storeId: voucher.store_id,
                  voucherId: voucher.voucher.id,
                  voucherData: voucher.voucher
                })
              }
            })

            await vouchers.map((voucher) => {
              if (voucher.is_applied) {
                this.$refs[`store-${voucher.store_id}-voucher-${voucher.voucher.code}`][0].checked = true
              }
            })
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleShowVoucher (value) {
      this.$emit('show-voucher', value.voucherId)
      this.$refs.modalVouchers.closeModal()
    },
    async handleCheckVoucher (value) {
      const { voucherCode, storeId } = value
      try {
        const respCheck = await this.checkVoucher(voucherCode, storeId)
        if (respCheck.status === 200) {
          if (respCheck.data.data.available) {
            this.setVoucherUsed({
              storeId,
              voucherId: respCheck.data.data.voucher.id,
              voucherData: {
                ...respCheck.data.data.voucher,
                total_discount: respCheck.data.data.total_discount
              }
            })
            this.editVouchersAvailable({
              storeId,
              voucherId: respCheck.data.data.voucher.id,
              fields: [{
                name: 'total_discount',
                value: respCheck.data.data.total_discount
              }]
            })
          } else {
            this.$refs[`store-${storeId}-voucher-${voucherCode}`][0].checked = false
            this.$message({
              message: respCheck.data.message,
              type: 'error'
            })
          }
        } else {
          this.$message({
            message: respCheck.data.message,
            type: 'error'
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleUnchecked () {
      if (!this.ungroupedVoucherUsedData.length) {
        this.setCartAvailability({})
      }
    },
    reapplyVoucher () {
      this.$nextTick(() => {
        this.groupedVoucherUsedData.forEach((used) => {
          used.data.forEach((dt) => {
            const voucherItemRef = `store-${used.storeId}-voucher-${dt.code}`
            if (this.$refs[voucherItemRef] && this.$refs[voucherItemRef][0]) {
              this.$refs[voucherItemRef][0].checked = true
            }
          })
        })
      })
    },
    handleSetVoucherUsed () {
      this.resetModal()
      if (this.$route.name === 'cart') {
        this.checkAvaibilityItems('', 'PopupVoucher')
      }
      this.$emit('voucher-used')
    },
    resetModal () {
      this.inputSearch = ''
      this.isAlert = false
    },
    closePopupVoucher () {
      this.setPopupVoucherModal(false)
      this.listVouchers.forEach((voucher) => {
        voucher.data.forEach((dt) => {
          const voucherItemRef = `store-${voucher.storeId}-voucher-${dt.code}`
          if (this.$refs[voucherItemRef] && this.$refs[voucherItemRef][0]) {
            this.$refs[voucherItemRef][0].checked = false
          }
        })
      })
      this.$emit('popup-voucher-closed')
    },
    closeModal () {
      this.$refs.modalVouchers.closeModal()
    }
  }
}
