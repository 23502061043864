import { render, staticRenderFns } from "./ButtonShareSocialMedia.vue?vue&type=template&id=beb8f89a&scoped=true&"
import script from "./ButtonShareSocialMedia.vue?vue&type=script&lang=js&"
export * from "./ButtonShareSocialMedia.vue?vue&type=script&lang=js&"
import style0 from "./ButtonShareSocialMedia.vue?vue&type=style&index=0&id=beb8f89a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "beb8f89a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconEditorCopy: require('/usr/src/app/components/icons/IconEditorCopy.vue').default,IconSocialWhatsapp: require('/usr/src/app/components/icons/IconSocialWhatsapp.vue').default,IconSocialFacebook: require('/usr/src/app/components/icons/IconSocialFacebook.vue').default,IconSocialTwitterX: require('/usr/src/app/components/icons/IconSocialTwitterX.vue').default})
