//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { fileVariants, getDefaultImageFromFiles, getDefaultStoreLogoOnError } from '@/utils/helpers/file-helper'
import { storeFileNames, storeTypes, defaultStoreLocationLabel } from '@/utils/helpers/store-helper'

export default {
  props: {
    partnerRelatedDataItem: {
      type: Object,
      required: true
    }
  },

  computed: {
    fileVariants () {
      return fileVariants
    },

    storeFileNames () {
      return storeFileNames
    },

    storeTypes () {
      return storeTypes
    },

    defaultStoreLocationLabel () {
      return defaultStoreLocationLabel
    },

    relatedDataLocation () {
      const { store_type: storeType, main_address: mainAddress, main_branch: mainBranch } = this.partnerRelatedDataItem

      if (storeType === storeTypes.storePartner.value) {
        return mainAddress ? mainAddress.full_address : ''
      }

      if (storeType === storeTypes.brandPartner.value && mainBranch) {
        const { city_name: cityName, province_name: provinceName } = mainBranch
        return [cityName, provinceName].filter(Boolean).join(', ').trim()
      }

      return ''
    }
  },

  methods: {
    getDefaultImageFromFiles,
    getDefaultStoreLogoOnError
  }
}
