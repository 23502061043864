//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'

import { storeTypes } from '@/utils/helpers/store-helper'
import { bannerFileNames } from '@/utils/helpers/banner-helper'
import {
  assignResponseToDataModelDataNotPaginated,
  defaultNotPaginationAttributes, isContentVisible
} from '@/utils/helpers/pagination-helper'
import { showcaseSlugs, showcaseTypes } from '@/utils/helpers/showcase-helper'
import { filterNonNullValues } from '@/utils/helpers/object-helper'

export default {
  data () {
    return {
      partnerVouchers: {
        ...defaultNotPaginationAttributes()
      },

      partnerFlashSales: {
        isFlashsaleNotEmpty: true
      },

      partnerLastSeenProducts: {
        ...defaultNotPaginationAttributes(),
        limit: 5
      },

      partnerBestSellerProducts: {
        ...defaultNotPaginationAttributes(),
        limit: 5
      },

      partnerRecommendationProducts: {
        ...defaultNotPaginationAttributes()
      }
    }
  },

  computed: {
    ...mapState('Partner/PartnerProfile', [
      'partner', 'partnerShowCases', 'partnerProfileTabItems', 'partnerProfileHomepageSections'
    ]),

    storeTypes () {
      return storeTypes
    },

    bannerFileNames () {
      return bannerFileNames
    },

    partnerProfileHomepageShowcases () {
      const showCasesData = this.partnerShowCases.data.filter((showcase) => {
        return showcase.type === showcaseTypes.product
      })

      return {
        ...this.partnerShowCases,
        data: showCasesData
      }
    }
  },

  methods: {
    isContentVisible,

    ...mapActions('Partner/PartnerProfile', [
      'getVouchers', 'getLastSeenProducts', 'getBestSellerProducts', 'getRecommendationProducts'
    ]),

    handleHomepageSectionMounted (sectionName) {
      switch (sectionName) {
        case this.partnerProfileHomepageSections.vouchers.name:
          this.getPartnerVouchersData()
          break
        case this.partnerProfileHomepageSections.lastSeenProducts.name:
          this.getPartnerLastSeenProductsData()
          break
        case this.partnerProfileHomepageSections.bestSellerProducts.name:
          this.getPartnerBestSellerProductsData()
          break
        case this.partnerProfileHomepageSections.recommendationProducts.name:
          this.getPartnerRecommendationProductsData()
          break
      }
    },

    // START - Flashsale
    handleFlashsaleSectionSectionLoaded (data) {
      this.partnerFlashSales.isFlashsaleNotEmpty = data.isFlashsaleNotEmpty
    },

    handleSeeAllFlashsaleOnClick () {
      this.$router.push({
        query: filterNonNullValues({
          partnerProfileTab: this.partnerProfileTabItems.allProduct.name,
          partnerProductsShowcase: showcaseSlugs.flashSale
        })
      })
    },
    // END - Flashsale

    // START - Get Partner Last Seen Products
    async getPartnerVouchersData () {
      try {
        this.partnerVouchers.loading = true

        const response = await this.getVouchers({
          data: {
            paginated: this.partnerVouchers.paginated,
            partner_id: this.partner ? [this.partner.id] : '',
            only_available: true
          }
        })

        assignResponseToDataModelDataNotPaginated(
          this.partnerVouchers,
          response.data.data.vouchers
        )
      } catch (error) {
        console.error(error)
      } finally {
        this.partnerVouchers.loading = false
      }
    },
    // END - Get Partner Last Seen Products

    // START - Get Partner Last Seen Products
    async getPartnerLastSeenProductsData () {
      try {
        this.partnerLastSeenProducts.loading = true

        const response = await this.getLastSeenProducts({
          data: {
            paginated: this.partnerLastSeenProducts.paginated,
            limit: this.partnerLastSeenProducts.limit,
            where: {
              'products.store_id': this.partner ? this.partner.id : ''
            }
          }
        })

        assignResponseToDataModelDataNotPaginated(
          this.partnerLastSeenProducts,
          response.data.data.products
        )
      } catch (error) {
        console.error(error)
      } finally {
        this.partnerLastSeenProducts.loading = false
      }
    },
    // END - Get Partner Last Seen Products

    // START - Get Partner Best Seller Products
    async getPartnerBestSellerProductsData () {
      try {
        this.partnerBestSellerProducts.loading = true

        const response = await this.getBestSellerProducts({
          params: {
            paginated: this.partnerBestSellerProducts.paginated,
            limit: this.partnerBestSellerProducts.limit,
            store_id: this.partner ? this.partner.id : ''
          }
        })

        assignResponseToDataModelDataNotPaginated(
          this.partnerBestSellerProducts,
          response.data.data.products
        )
      } catch (error) {
        console.error(error)
      } finally {
        this.partnerBestSellerProducts.loading = false
      }
    },
    // END - Get Partner Best Seller Products

    // START - Get Partner Recommendation Products
    async getPartnerRecommendationProductsData () {
      try {
        this.partnerRecommendationProducts.loading = true

        const response = await this.getRecommendationProducts({
          id: this.partner ? this.partner.id : '',
          payload: {
            data: {
              paginated: this.partnerRecommendationProducts.paginated,
              limit: this.partnerRecommendationProducts.limit
            }
          }
        })

        assignResponseToDataModelDataNotPaginated(
          this.partnerRecommendationProducts,
          response.data.data.products
        )
      } catch (error) {
        console.error(error)
      } finally {
        this.partnerRecommendationProducts.loading = false
      }
    },

    handleSeeAllRecommendationProductsOnClick () {
      this.$router.push({
        query: filterNonNullValues({
          partnerProfileTab: this.partnerProfileTabItems.allProduct.name,
          partnerProductsShowcase: showcaseSlugs.allProduct
        })
      })
    }
    // END - Get Partner Recommendation Products
  }
}
